// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import axios from "axios";
// import Chart from "chart.js/auto";
// import { ApiUrl } from "../../Constant/ApiUrl";
// import { PolarArea } from "react-chartjs-2";
// import "../../App.css";
// import Questions from "./Questions";
// import DashboardBanner from "../../Components/DashboardBanner";
// import buttonevalution from '../../assets/images/buttonevalution.png'
// const Evaluation1 = () => {
//   const [Eval_data, setEval_data] = useState({});
//   const [Question, setQuestion] = useState([]);

//   const Data = useSelector((state) => state.responseData);
//   console.log(Data , 'itsssssss')

//   const dummy = [
//     { data: Eval_data?.data?.evaluation?.criterion?.adabtibility },
//     { data: Eval_data?.data?.evaluation?.criterion?.comunication },
//     { data: Eval_data?.data?.evaluation?.criterion?.selfintroduction },
//     { data: Eval_data?.data?.evaluation?.criterion?.promblem_solving },
//     { data: Eval_data?.data?.evaluation?.criterion?.team_colab },
//   ];
//   const updatedData = dummy.map((item, index) => ({
//     name: `Page ${String.fromCharCode(65 + index)}`, // Generates Page A, Page B, etc.
//     uv: item.data * 800, // Example calculation, adjust based on your requirements
//     pv: item.data, // Directly using the value from dummy
//     amt: item.data * 500, // Example calculation, adjust based on your requirements
//   }));

//   console.log(Eval_data, "<== Eval Data");

//   useEffect(() => {
//     axios
//       .post(ApiUrl + "/get_evaluation", {
//         interview_id: Data?.interview?.id,
//       })
//       .then((x) => {
//         setEval_data(x);
//       });
//   }, []);
//   const chartRef = useRef(null);
//   const [hireText, setHireText] = useState("No Hire");
//   const [textColor, setTextColor] = useState("red");
//   useEffect(() => {
//     // Value to display
//     const value = Eval_data?.data?.evaluation?.overal_decision || 10; // Example value, adjust based on your data

//     // Determine color based on the value
//     const valueColor = value > 50 ? "green" : "red";
//     setHireText(value > 50 ? "Hire" : "No Hire");
//     const decisionTextColor = value > 50 ? "green" : "red";
//     setTextColor(decisionTextColor);
//     // Dataset configuration
//     const dataset =
//       value >= 100
//         ? [
//           // If the value is 100, show in one color
//           {
//             label: "Value",
//             data: [100],
//             backgroundColor: [valueColor],
//             borderColor: ["rgba(255, 26, 104, 0.2)"],
//             borderWidth: 1,
//             cutout: "90%",
//             circumference: 180,
//             rotation: 270,
//           },
//         ]
//         : [
//           // If the value is less than 100, show value in one color, rest in another
//           {
//             label: "Value",
//             data: [value, 100 - value], // Sum to 100
//             backgroundColor: [valueColor, "#fff"],
//             borderColor: ["rgba(255, 26, 104, 0.2)", "rgba(0, 0, 0, 0.2)"],
//             borderWidth: 1,
//             cutout: "90%",
//             circumference: 180,
//             rotation: 270,
//           },
//         ];

//     // Data setup
//     const data = {
//       datasets: dataset,
//     };

//     // Config
//     const config = {
//       type: "doughnut",
//       data,
//       options: {
//         plugins: {
//           legend: {
//             display: false,
//           },
//           tooltip: {
//             enabled: false,
//           },
//         },
//         responsive: true,
//         maintainAspectRatio: false, // Allows setting custom dimensions
//       },
//     };

//     // Destroy previous instance
//     if (chartRef.current) {
//       chartRef.current.destroy();
//     }

//     // Create new instance
//     chartRef.current = new Chart(document.getElementById("myChart"), config);
//   }, [Eval_data]); // Include dependencies if needed

//   useEffect(() => {
//     const timeOut = setTimeout(() => {
//       axios
//         .post(ApiUrl + "/get_evaluation_Questions", {
//           evaluation_id: Eval_data?.data?.evaluation?.id,
//           // evaluation_id: Eval_data?.data?.evaluation?.id
//         })
//         .then((x) => setQuestion(x.data.evaluation_Questions));
//     }, 4000);

//     return () => clearInterval(timeOut);
//   }, [Eval_data]);

//   const responseDatas = [
//     {
//       heading: "Heading 1",
//       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus quod blanditiis ipsa nulla voluptas quia consequuntur qui totam minima aliquam corrupti et, sint aperiam nesciunt aspernatur ex magnam exercitationem? Animi?",
//     },
//     {
//       heading: "Heading 2",
//       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus quod blanditiis ipsa nulla voluptas quia consequuntur qui totam minima aliquam corrupti et, sint aperiam nesciunt aspernatur ex magnam exercitationem? Animi?",
//     },
//     {
//       heading: "Heading 3",
//       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus quod blanditiis ipsa nulla voluptas quia consequuntur qui totam minima aliquam corrupti et, sint aperiam nesciunt aspernatur ex magnam exercitationem? Animi?",
//     },
//   ];

//   const feedbackEntry = [
//     {
//       heading: "Heading 1",
//       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus quod blanditiis ipsa nulla voluptas quia consequuntur qui totam minima aliquam corrupti et, sint aperiam nesciunt aspernatur ex magnam exercitationem? Animi?",
//     },
//     {
//       heading: "Heading 2",
//       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus quod blanditiis ipsa nulla voluptas quia consequuntur qui totam minima aliquam corrupti et, sint aperiam nesciunt aspernatur ex magnam exercitationem? Animi?",
//     },
//     {
//       heading: "Heading 3",
//       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus quod blanditiis ipsa nulla voluptas quia consequuntur qui totam minima aliquam corrupti et, sint aperiam nesciunt aspernatur ex magnam exercitationem? Animi?",
//     },
//   ];

//   // Add a new state to manage the visibility of answers
//   const [visibleAnswers, setVisibleAnswers] = useState({});

//   // Function to toggle the visibility of an answer
//   const toggleAnswerVisibility = (index) => {
//     setVisibleAnswers((prevVisibleAnswers) => ({
//       ...prevVisibleAnswers,
//       [index]: !prevVisibleAnswers[index],
//     }));
//   };
//   return (
//     <div className="h-[95vh] mt-2 mr-3 mb-4 p-5 rounded-lg w-98p bg-[#F0F0F0] font-poppins">
//       <div className="overflow-y-auto no-scrollbar" style={{ maxHeight: 'calc(110vh - 160px)' }}>
//       <DashboardBanner />
//       <img src={buttonevalution} alt="" className="mt-10 mb-4" />
//       <p className="text-start text-xl font-bold">Interview Evaluation & Feedback - Overall Assessment</p>
//       <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-98p">
//         <div className="mt-5 p-5 rounded-lg bg-white">
//           <div className="text-start">
//             <h1 className="text-2xl text-head mb-16">Interview Information</h1>
//             <div className="">
//               <div className="text-xl">
//                 Position: <span>{ }</span>
//               </div>

//               <div className="text-xl leading-10">
//                 Time: <span>{"2024-02-29T19:34:44.197000"}</span>
//               </div>

//               <div className="text-xl leading-6">
//                 Duration: <span>{"0 hour(s) 5 minute(s) 7 second(s)"}</span>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="mt-5 p-5 rounded-lg bg-white">
//           <div className="chartCard">
//             <div className="chartBox relative">
//               <canvas id="myChart"></canvas>
//               <div
//                 className="absolute centeredText font-medium"
//                 style={{ color: textColor }}
//               >
//                 {hireText}
//               </div>
//             </div>
//           </div>
//           <p className="text-head text-xl">Overall Decision</p>
//         </div>

//         <div className="mt-5 p-5 rounded-lg bg-white h-[400px]">
//           {/* Professional Skillset Matrix */}
//           {/* <div> */}
//           <ResponsiveContainer width="100%" height="85%">
//             <LineChart width={300} height={100} data={updatedData}>
//               <Line
//                 type="monotone"
//                 dataKey="pv" // Ensure this matches the field in your data array
//                 stroke="#8884d8"
//                 strokeWidth={2}

//               />
//             </LineChart>
//           </ResponsiveContainer>

//           <p className="text-head  text-xl">Professional Skillset Matrix</p>
//           {/* </div> */}
//         </div>

//         <div className="mt-5 p-5 rounded-lg bg-white md:col-span-1">
//           <div className="text-start">
//             <h1 className="text-xl" style={{ color: "#21C010" }}>
//               Areas of Strength
//             </h1>

//             <ul className="list-disc pl-5">
//               {Eval_data?.data?.evaluation?.area_of_strength
//                 ?.split("-")
//                 .map((x) => x.trim()) // Trim each string to remove whitespace
//                 .filter((x) => x) // Filter out any empty strings
//                 .map((x) => (
//                   <li>{x}</li>
//                 ))}
//             </ul>

//             <h1 className="text-xl" style={{ color: "#D76A03" }}>
//               Areas to Improve
//             </h1>
//             <ul className="list-disc pl-5">
//               <ul className="list-disc pl-5">
//                 {Eval_data?.data?.evaluation?.area_to_improve
//                   ?.split("-")
//                   .map((x) => x.trim()) // Trim each string to remove whitespace
//                   .filter((x) => x) // Filter out any empty strings
//                   .map((x) => (
//                     <li>{x}</li>
//                   ))}
//               </ul>
//             </ul>
//           </div>

//         </div>

//         <div className="mt-5 p-5 h-[600px] rounded-lg bg-white md:col-span-2 ">
//           <p className="text-head text-xl">Competency Spectrum Analysis</p>
//           <PolarArea
//             data={{
//               labels: [
//                 "Adaptibility",
//                 "Communication",
//                 "Self Introduction",
//                 "Problem Solving Skills",
//                 "Teamwork And Collaboration",
//               ],
//               datasets: [
//                 {
//                   label: "My First Dataset",
//                   data: [
//                     Eval_data?.data?.evaluation?.criterion?.adabtibility,
//                     Eval_data?.data?.evaluation?.criterion?.comunication,
//                     Eval_data?.data?.evaluation?.criterion?.selfintroduction,
//                     Eval_data?.data?.evaluation?.criterion?.promblem_solving,
//                     Eval_data?.data?.evaluation?.criterion?.team_colab,
//                   ],
//                   backgroundColor: [
//                     "rgba(255, 99, 132, 0.2)",
//                     "rgba(75, 192, 192, 0.2)",
//                     "rgba(255, 205, 86, 0.2)",
//                     "rgba(201, 203, 207, 0.2)",
//                     "rgba(54, 162, 235, 0.2)",
//                   ],
//                   borderColor: [
//                     "rgb(255, 99, 132)",
//                     "rgb(75, 192, 192)",
//                     "rgb(255, 205, 86)",
//                     "rgb(201, 203, 207)",
//                     "rgb(54, 162, 235)",
//                   ],
//                   borderWidth: 1,
//                 },
//               ],
//             }}
//             options={{
//               // Simplify or adjust options as needed

//               maintainAspectRatio: false,
//             }}
//           />

//         </div>
//       </div>
//       <h1 className="text-[#025CFA] text-start font-bold text-xl">
//         Per Question Evaluation & Feedback
//       </h1>
//       {
//         Question && Question.length > 0 &&
//         Question.map((x, i) => {
//           return (
//             <div onClick={() => console.log(x)} className="bg-white gap-4 w-98p flex flex-col items-center py-5 my-4">

//               <div className="mt-5 p-5 rounded-lg bg-white">
//                 <div className="text-start">
//                   <h1>
//                     Question {i + 1}:{x.quest}
//                   </h1>
//                   <button className="my-2" onClick={() => toggleAnswerVisibility(i)}>
//                     {visibleAnswers[i] ? "Collapse Your answer" : "Expand Your answer"}
//                   </button>
//                   {/* Answer, shown/hidden based on state */}
//                   {visibleAnswers[i] && <p>{x.answer}</p>}
//                   <h2 className="text-[#3AB4E7] text-xl font-medium mt-2">Feedback</h2>
//                   <div className="gap-2 flex flex-wrap rounded-lg md:justify-start justify-center">
//                     {
//                       x.feedback.split('\n\n').map((section, sectionIndex) => (

//                         <div key={sectionIndex} className="grid grid-flow-col  gap-4">
//                           <div
//                             className="border-2 mt-5 p-5 rounded-3xl shadow-lg w-64 mb-4 bg-white"
//                           >
//                             {
//                               section.split('\n').map((line, lineIndex) => {
//                                 const parts = line.split(':');

//                                 if (parts.length > 1) {
//                                   const heading = parts.shift();
//                                   const paragraph = parts.join(':');
//                                   return (

//                                     <div className="flex flex-col text-start">
//                                       <p className="font-medium leading-7 pb-2">
//                                         <span className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 rounded-full bg-[#F2F4FF] font-medium">
//                                           {heading}
//                                         </span>
//                                       </p>
//                                       <p>
//                                         {paragraph}
//                                       </p>
//                                     </div>

//                                   );
//                                 } else {

//                                   return <p key={lineIndex}>{line}</p>;
//                                 }
//                               })
//                             }

//                           </div>
//                         </div>
//                       ))
//                     }
//                   </div>
//                   <h2 className="text-[#3AB4E7] text-xl font-medium">Revised Answer</h2>
//                   <p>
//                     {x.revised_answer}
//                   </p>

//                   <h2 className="text-[#3AB4E7] text-xl font-medium pt-2">Purposes</h2>
//                   <div className="gap-2 flex flex-wrap md:justify-start justify-center">

//                     {
//                       x.purpose.split('\n\n').map((section, sectionIndex) => (
//                         <div key={sectionIndex} className="flex flex-wrap w-64 mb-5 gap-4">
//                           <div className="rounded-xl shadow-xl border border-gray-300 p-4 mx-1">
//                             {section.split('\n').map((line, lineIndex) => {
//                               const parts = line.split(':');
//                               if (parts.length > 1) {
//                                 let heading = parts.shift();
//                                 const paragraph = parts.join(':');
//                                 const headingParts = heading.split(/(?<=^\d+)\. /); // Lookbehind to split after the number followed by a dot and space
//                                 return (
//                                   <div key={lineIndex} >
//                                     <h2 className="flex flex-col">
//                                       {headingParts.length > 1 ? (
//                                         <>
//                                           <span className=" text-black font-bold text-4xl rounded-full p-1">{headingParts[0] + '.'}</span>
//                                           <span className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 text-[12px] rounded-xl bg-[#F2F4FF] font-medium">{headingParts[1]}</span>
//                                         </>
//                                       ) : (
//                                         heading // Fallback if there's no number
//                                       )}
//                                     </h2>
//                                     <p className="pt-3">{paragraph}</p>
//                                   </div>
//                                 );
//                               } else {
//                                 return <p key={lineIndex} className="">{line}</p>;
//                               }
//                             })}
//                           </div>
//                         </div>
//                       ))
//                     }
//                   </div>

//                   <div className="flex w-full flex-col lg:flex-row md:flex-row justify-between">
//                     <div className="flex justify-center items-center w-[50%]">
//                       {x && (
//                         <PolarArea
//                           data={{
//                             labels: [
//                               x?.quest_criteras[0]?.heading,
//                               x?.quest_criteras[1]?.heading,
//                               x?.quest_criteras[2]?.heading,
//                               x?.quest_criteras[3]?.heading,
//                               x?.quest_criteras[4]?.heading,
//                             ],
//                             datasets: [{
//                               label: 'My First Dataset',
//                               data: [
//                                 x?.quest_criteras[0]?.score,
//                                 x?.quest_criteras[1]?.score,
//                                 x?.quest_criteras[2]?.score,
//                                 x?.quest_criteras[3]?.score, // Make sure this exists or handle it appropriately
//                                 x?.quest_criteras[4]?.score, // Make sure this exists or handle it appropriately
//                               ],
//                               backgroundColor: [
//                                 'rgba(255, 99, 132, 0.2)',
//                                 'rgba(75, 192, 192, 0.2)',
//                                 'rgba(255, 205, 86, 0.2)',
//                                 'rgba(201, 203, 207, 0.2)',
//                                 'rgba(54, 162, 235, 0.2)'
//                               ],
//                               borderColor: [
//                                 'rgb(255, 99, 132)',
//                                 'rgb(75, 192, 192)',
//                                 'rgb(255, 205, 86)',
//                                 'rgb(201, 203, 207)',
//                                 'rgb(54, 162, 235)'
//                               ],
//                               borderWidth: 1
//                             }]
//                           }}
//                           options={{
//                             // Simplify or adjust options as needed

//                             maintainAspectRatio: false
//                           }}
//                         />
//                       )}

//                     </div>
//                     <div className=" flex flex-col justify-center items-center w-[50%]">

//                       {
//                         x && x?.quest_criteras?.length > 0 && x?.quest_criteras?.map(x => (
//                           <div className="flex p-2 gap-4 items-center">
//                             <div className="relative py-5">
//                               <svg className="text-center " width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

//                                 <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-red-600  " stroke-width="2"></circle>

//                                 <g className="origin-center -rotate-90 transform">
//                                   <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-gray-700" stroke-width="2" stroke-dasharray="100" stroke-dashoffset={x.score}></circle>
//                                 </g>

//                               </svg>
//                               <div className="absolute w-full text-center top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
//                                 <span className="text-center text-xs font-semibold text-gray-800 dark:text-white">{x.score}%</span>
//                               </div>
//                             </div>
//                             <div>
//                               <div className="text-xs font-extrabold">{x.heading}</div>
//                               <div className="text-xs font-semibold">{x.description}</div>
//                             </div>
//                           </div>
//                         ))
//                       }
//                     </div>
//                   </div>
//                   <div>
//                     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
//                       {x &&
//                         x.star.split('\n\n').map((section, sectionIndex) => {
//                           const validSections = [
//                             'Situation',
//                             'Task',
//                             'Action',
//                             'Result',
//                           ];

//                           // Check if the current section is one of the specified valid sections
//                           if (validSections.some(validSection => section.includes(validSection))) {
//                             return (
//                               <div key={sectionIndex} className="rounded-lg border border-gray-300 shadow-sm p-4">
//                                 {section.split('\n').map((line, lineIndex) => {
//                                   const parts = line.split(':');
//                                   if (parts.length > 1) {
//                                     let heading = parts.shift();
//                                     const paragraph = parts.join(':');
//                                     // Splitting the heading further to separate the number and the text
//                                     const headingParts = heading.split(/(?<=^\d+)\. /); // Lookbehind to split after the number followed by a dot and space
//                                     return (
//                                       <div key={lineIndex}>
//                                         {/* Applying different styles to the number and the rest of the heading */}
//                                         <h2 className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 text-[16px] rounded-full bg-[#F2F4FF] font-medium">
//                                           {headingParts.length > 1 ? (
//                                             <>
//                                               <span className="text-blue-400 bg-white rounded-full p-1">{headingParts[0]}</span>
//                                               <span className="rounded-full p-1 ">{headingParts[1]}</span>
//                                             </>
//                                           ) : (
//                                             heading // Fallback if there's no number
//                                           )}
//                                         </h2>
//                                         <p className="mt-2 text-gray-600 text-[13px]">{paragraph}</p>
//                                       </div>
//                                     );
//                                   } else {
//                                     return <p key={lineIndex}>{line}</p>;
//                                   }
//                                 })}
//                               </div>
//                             );
//                           } else {
//                             return null; // Skip rendering if the section is not in the validSections array
//                           }
//                         })}
//                     </div>
//                     <div className="my-5">
//                       {x &&
//                         x.star.split('\n\n').map((section, sectionIndex) => {
//                           const validSections = [
//                             'Overall feedback on STAR method'
//                           ];

//                           // Check if the current section is one of the specified valid sections
//                           if (validSections.some(validSection => section.includes(validSection))) {
//                             return (
//                               <div key={sectionIndex} className="rounded-lg border border-gray-300 shadow-sm p-4">
//                                 {section.split('\n').map((line, lineIndex) => {
//                                   const parts = line.split(':');
//                                   if (parts.length > 1) {
//                                     let heading = parts.shift();
//                                     const paragraph = parts.join(':');
//                                     // Splitting the heading further to separate the number and the text
//                                     const headingParts = heading.split(/(?<=^\d+)\. /); // Lookbehind to split after the number followed by a dot and space
//                                     return (
//                                       <div key={lineIndex}>
//                                         {/* Applying different styles to the number and the rest of the heading */}
//                                         <h2 className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 text-[16px] rounded-full bg-[#F2F4FF] font-medium">
//                                           {headingParts.length > 1 ? (
//                                             <>
//                                               <span className="text-blue-400 bg-white rounded-full p-1">{headingParts[0]}</span>
//                                               <span className="text-base rounded-full p-1">{headingParts[1]}</span>
//                                             </>
//                                           ) : (
//                                             heading // Fallback if there's no number
//                                           )}
//                                         </h2>
//                                         <p className="mt-2 text-gray-600 text-[13px]">{paragraph}</p>
//                                       </div>
//                                     );
//                                   } else {
//                                     return <p key={lineIndex}>{line}</p>;
//                                   }
//                                 })}
//                               </div>
//                             );
//                           } else {
//                             return null; // Skip rendering if the section is not in the validSections array
//                           }
//                         })}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )
//         })
//       }
//       </div>
//     </div>
//   );
// };

// export default Evaluation1;




import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { LineChart, Line, ResponsiveContainer } from "recharts";
import axios from "axios";
import Chart from "chart.js/auto";
import { ApiUrl } from "../../Constant/ApiUrl";
import { PolarArea } from "react-chartjs-2";
import "../../App.css";
import DashboardBanner from "../../Components/DashboardBanner";
import buttonevalution from "../../assets/images/buttonevalution.png";
import { useNavigate, useLocation } from "react-router-dom";

const Evaluation1 = () => {
  const [Eval_data, setEval_data] = useState({});
  const [Question, setQuestion] = useState([]);
  const Data = useSelector((state) => state.responseData); // Fetch Redux data
  const [visibleAnswers, setVisibleAnswers] = useState({}); // Manage visibility of answers
  const chartRef = useRef(null);
  const [hireText, setHireText] = useState("No Hire");
  const [textColor, setTextColor] = useState("red");
  const [jobTitle, setJobTitle] = useState("N/A");
  const [areasOfStrength, setAreasOfStrength] = useState([]);
  const navigate = useNavigate();
  const location = useLocation(); // To monitor route changes

  // Check for local storage data on page load
  useEffect(() => {
    const storedJobTitle = localStorage.getItem("jobTitle");
    const analysisData = localStorage.getItem("analysisData");
    const criteriaData = localStorage.getItem("criteriaData");
    const feedbackResponses = localStorage.getItem("feedbackResponses");

    if (storedJobTitle) {
      setJobTitle(storedJobTitle);
    }

    if (analysisData || criteriaData || feedbackResponses) {
      const localEvalData = {
        analysis: JSON.parse(analysisData) || {},
        criteria: JSON.parse(criteriaData) || {},
        feedback: JSON.parse(feedbackResponses) || [],
      };

      // Update evaluation data with local storage data
      setEval_data({
        data: {
          evaluation: {
            overal_decision: localEvalData.analysis.OverallDecision,
            overall_sentiment: localEvalData.analysis.OverallSentiment,
            area_of_strength: localEvalData.analysis.AreasOfStrength,
            area_to_improve: localEvalData.analysis.AreasToImprove,
            criterion: {
              adabtibility: localEvalData.criteria.Adaptability,
              comunication: localEvalData.criteria.Communication,
              selfintroduction: localEvalData.criteria.SelfIntroduction,
              promblem_solving: localEvalData.criteria.ProblemSolvingSkills,
              team_colab: localEvalData.criteria.TeamworkAndCollaboration,
            },
          },
        },
      });

      if (localEvalData.analysis.AreasOfStrength) {
        setAreasOfStrength(localEvalData.analysis.AreasOfStrength.split("-").filter(Boolean));
      }

      setQuestion(
        localEvalData.feedback.map((feedback, index) => ({
          quest: `Question ${index + 1}`,
          answer: feedback.answer,
          feedback: feedback.feedback,
          revised_answer: feedback.revised_answer,
          purpose: feedback.purpose,
        }))
      );
    } else if (Data?.interview?.id) {
      // Fetch evaluation data if not available in local storage
      axios
        .post(ApiUrl + "/get_evaluation", {
          interview_id: Data?.interview?.id,
        })
        .then((x) => {
          setEval_data(x);
        })
        .catch((err) => console.error("Error fetching evaluation:", err));
    }
  }, [Data?.interview?.id]);

  // Keep local storage data until the user navigates away from this page
  useEffect(() => {
    return () => {
      // Only clear local storage when navigating away from this page
      if (location.pathname !== "/evaluation") {
        localStorage.removeItem("analysisData");
        localStorage.removeItem("criteriaData");
        localStorage.removeItem("feedbackResponses");
        console.log("Local storage cleared.");
      }
    };
  }, [location]); // Monitor route changes and trigger clearing when navigating away

  // Render doughnut chart
  useEffect(() => {
    const value = Eval_data?.data?.evaluation?.overal_decision || 10;
    const valueColor = value > 50 ? "green" : "red";
    setHireText(value > 50 ? "Hire" : "No Hire");
    setTextColor(valueColor);

    const dataset =
      value >= 100
        ? [{ label: "Value", data: [100], backgroundColor: [valueColor], cutout: "90%" }]
        : [
            {
              label: "Value",
              data: [value, 100 - value],
              backgroundColor: [valueColor, "#fff"],
              cutout: "90%",
            },
          ];

    const data = { datasets: dataset };
    const config = {
      type: "doughnut",
      data,
      options: {
        plugins: { legend: { display: false }, tooltip: { enabled: false } },
        responsive: true,
        maintainAspectRatio: false,
      },
    };

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(document.getElementById("myChart"), config);
  }, [Eval_data]);

  // Toggle answer visibility
  const toggleAnswerVisibility = (index) => {
    setVisibleAnswers((prevVisibleAnswers) => ({
      ...prevVisibleAnswers,
      [index]: !prevVisibleAnswers[index],
    }));
  };

  // Prepare data for line chart
  const dummy = [
    { data: Eval_data?.data?.evaluation?.criterion?.adabtibility },
    { data: Eval_data?.data?.evaluation?.criterion?.comunication },
    { data: Eval_data?.data?.evaluation?.criterion?.selfintroduction },
    { data: Eval_data?.data?.evaluation?.criterion?.promblem_solving },
    { data: Eval_data?.data?.evaluation?.criterion?.team_colab },
  ];
  const updatedData = dummy.map((item, index) => ({
    name: `Skill ${String.fromCharCode(65 + index)}`,
    score: item.data || 0,
  }));

  return (
    <div className="h-[95vh] mt-2 mr-3 mb-4 p-5 rounded-lg w-98p bg-[#F0F0F0] font-poppins">
      <div className="overflow-y-auto no-scrollbar" style={{ maxHeight: "calc(110vh - 160px)" }}>
        <DashboardBanner />
        <img src={buttonevalution} alt="" className="mt-10 mb-4" />
        <p className="text-start text-xl font-bold">Interview Evaluation & Feedback - Overall Assessment</p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-98p">
          {/* Interview Information */}
          <div className="mt-5 p-5 rounded-lg bg-white">
            <div className="text-start">
              <h1 className="text-2xl text-head mb-16">Interview Information</h1>
              <div>
                <div className="text-xl">
                  Position: <span>{jobTitle}</span>
                </div>
                <div className="text-xl leading-10">
                  Time: <span>{Eval_data?.data?.evaluation?.time || "N/A"}</span>
                </div>
                <div className="text-xl leading-6">
                  Duration: <span>{Eval_data?.data?.evaluation?.duration || "N/A"}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Doughnut Chart */}
          <div className="mt-5 p-5 rounded-lg bg-white">
            <div className="chartCard">
              <div className="chartBox relative">
                <canvas id="myChart"></canvas>
                <div className="absolute centeredText font-medium" style={{ color: textColor }}>
                  {hireText}
                </div>
              </div>
            </div>
            <p className="text-head text-xl">Overall Decision</p>
          </div>

          {/* Line Chart */}
          <div className="mt-5 p-5 rounded-lg bg-white h-[400px]">
            <ResponsiveContainer width="100%" height="85%">
              <LineChart data={updatedData}>
                <Line type="monotone" dataKey="score" stroke="#8884d8" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
            <p className="text-head text-xl">Professional Skillset Matrix</p>
          </div>

          {/* Areas of Strength and Improvement */}
          <div className="mt-5 p-5 rounded-lg bg-white md:col-span-1">
            <div className="text-start">
              <h1 className="text-xl" style={{ color: "#21C010" }}>Areas of Strength</h1>
              <ul className="list-disc pl-5">
                {areasOfStrength.map((area, index) => (
                  <li key={index}>{area.trim()}</li>
                ))}
              </ul>
              <h1 className="text-xl" style={{ color: "#D76A03" }}>Areas to Improve</h1>
              <ul className="list-disc pl-5">
                {(Eval_data?.data?.evaluation?.area_to_improve || "")
                  .split("-")
                  .filter(Boolean)
                  .map((x, i) => (
                    <li key={i}>{x.trim()}</li>
                  ))}
              </ul>
            </div>
          </div>

          {/* Polar Area Chart */}
          <div className="mt-5 p-5 h-[600px] rounded-lg bg-white md:col-span-2">
            <p className="text-head text-xl">Competency Spectrum Analysis</p>
            <PolarArea
              data={{
                labels: [
                  "Adaptability",
                  "Communication",
                  "Self Introduction",
                  "Problem Solving Skills",
                  "Teamwork & Collaboration",
                ],
                datasets: [
                  {
                    label: "Skills",
                    data: [
                      Eval_data?.data?.evaluation?.criterion?.adabtibility || 0,
                      Eval_data?.data?.evaluation?.criterion?.comunication || 0,
                      Eval_data?.data?.evaluation?.criterion?.selfintroduction || 0,
                      Eval_data?.data?.evaluation?.criterion?.promblem_solving || 0,
                      Eval_data?.data?.evaluation?.criterion?.team_colab || 0,
                    ],
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.2)",
                      "rgba(75, 192, 192, 0.2)",
                      "rgba(255, 205, 86, 0.2)",
                      "rgba(201, 203, 207, 0.2)",
                      "rgba(54, 162, 235, 0.2)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={{ maintainAspectRatio: false }}
            />
          </div>
        </div>

        {/* Per Question Evaluation */}
        <h1 className="text-[#025CFA] text-start font-bold text-xl">Per Question Evaluation & Feedback</h1>
        {Question.map((q, i) => (
          <div key={i} className="bg-white gap-4 w-98p flex flex-col items-center py-5 my-4">
            <div className="mt-5 p-5 rounded-lg bg-white">
              <div className="text-start">
                <h1>Question {i + 1}: {q.quest}</h1>
                <button className="my-2" onClick={() => toggleAnswerVisibility(i)}>
                  {visibleAnswers[i] ? "Collapse Your answer" : "Expand Your answer"}
                </button>
                {visibleAnswers[i] && <p>{q.answer}</p>}
                <h2 className="text-[#3AB4E7] text-xl font-medium mt-2">Feedback</h2>
                <div className="gap-2 grid grid-cols-3 mb-6 flex flex-wrap rounded-lg md:justify-start justify-center">
                  {q.feedback?.split('\n\n').map((section, sectionIndex) => (
                    <div key={sectionIndex} className="border-2 mt-5 p-12 rounded-3xl shadow-lg bg-white">
                      {section.split('\n').map((line, lineIndex) => (
                        <p key={lineIndex}>{line}</p>
                      ))}
                    </div>
                  ))}
                </div>
                <h2 className="text-[#3AB4E7] text-xl mb-4 font-medium">Revised Answer</h2>
                <p>{q.revised_answer}</p>
                <h2 className="text-[#3AB4E7] my-4 text-xl font-medium pt-2">Purposes</h2>
                <div className="grid grid-cols-4 gap-2 flex flex-wrap md:justify-start justify-center">
                  {q.purpose?.split('\n\n').map((section, sectionIndex) => (
                    <div key={sectionIndex} className="rounded-xl shadow-xl border p-4">
                      {section.split('\n').map((line, lineIndex) => (
                        <p key={lineIndex}>{line}</p>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Evaluation1;




