import React, { useEffect, useState } from "react";
import robot from "../../assets/images/bot.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { responseDataActions } from "../../store/responseDataSlice";
import { FiUploadCloud } from "react-icons/fi";
import DashboardBanner from "../../Components/DashboardBanner";
import { ApiUrl } from "../../Constant/ApiUrl";

const TextSubmit = () => {
  const [file, setFile] = useState(null);
  const [jobTitle, setJobTitle] = useState("");
  const [experience, setExperience] = useState("");
  const [desc, setDesc] = useState("");
  const [Name, setName] = useState(null); // Initialize as null

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleExperience = (e) => {
    setExperience(e.target.value);
  };

  const handleTextChangeJob = (e) => {
    const title = e.target.value;
    setJobTitle(title);
    localStorage.setItem("jobTitle", title);
  };

  const handleTextChangeDesc = (e) => {
    const description = e.target.value;
    setDesc(description);
    localStorage.setItem("jobDesc", description);
  };

  useEffect(() => {
    const Token = localStorage.getItem("user");
    if (Token) {
      axios
        .post(
          ApiUrl + "/get_user",
          { hehe: "jje" },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: Token,
            },
          }
        )
        .then((response) => {
          setName(response?.data?.user_data?.username || ""); // Ensure a fallback value
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setName(""); // Fallback to empty string on error
        });
    } else {
      setName(""); // Ensure `Name` is set even if no token is available
    }
  }, []);

  const send_params = () => {
    dispatch(
      responseDataActions.setResponseData({ jobTitle, experience, desc })
    );
    navigate("/mockinterview/interviews/mockinterviewtext");
  };

  return (
    <div className="p-5 h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden">
      <div
        className="overflow-y-auto no-scrollbar px-10"
        style={{ maxHeight: "calc(110vh - 160px)" }}
      >
        <DashboardBanner />
        <div className="flex items-center justify-center py-8 ">
          <div className="h-4 w-4 rounded-full bg-Step flex items-center justify-center">
            <span className="text-black pt-12">info</span>
          </div>
          <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
          <div className="h-4 w-4 rounded-full bg-[#8E339C] flex items-center justify-center">
            <span className="text-black pt-12">Interview</span>
          </div>
          <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
          <div className="h-4 w-4 rounded-full bg-white border-gray-400 border-2 flex items-center justify-center">
            <span className="text-black pt-12">report</span>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pb-6 pt-7">
          <div>
            <img width={200} src={robot} alt="robot" />
          </div>
          <div className="bg-gradient-to-r from-[#3C38FF] to-[#E6251E] rounded-3xl">
            <p className="text-white pt-7 ps-4 pe-4 pb-1 text-start">
              Hi {Name || "there"}! We need a bit more information about you
              and the position to generate questions for you. <br />
              <p className="pt-5">
                All fields are optional! Go ahead and hit that "Start!" button
                even if you don't have any details yet.
              </p>
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          <h1 className="text-start pt-4 pb-4 text-head font-bold text-lg">
            About You (Optional)
          </h1>
          <div className="flex flex-col md:flex-row text-sm">
            <p>My desired job title/position is</p>
            <input
              className="bg-[#F0F0F0] border-0 border-b border-dotted w-28 text-center ps-2 pe-2 outline-none border-gray-300"
              type="text"
              value={jobTitle}
              onChange={handleTextChangeJob}
            />
            <p>I have</p>
            <input
              value={experience}
              onChange={handleExperience}
              className="bg-[#F0F0F0] border-0 border-b border-dotted w-28 text-center ps-2 pe-2 outline-none border-gray-300"
              type="number"
            />
            <p> years of experience in this industry.</p>
          </div>
          <div className="text-start flex flex-col md:flex-row gap-10">
            <div className="flex-1">
              <h1 className="pt-4 pb-4 text-head font-bold text-lg">
                About The Position (Optional)
              </h1>
              <input
                type="text"
                placeholder="Job Description Link"
                value={desc}
                onChange={handleTextChangeDesc}
                className="rounded-lg border-2 border-gray-200 outline-none p-4 w-full"
              />
            </div>
            <div className="md:w-1/3">
              <h1 className="pt-4 pb-4 text-head font-bold text-lg">
                Upload CV (Optional)
              </h1>
              <label
                htmlFor="upload"
                className="flex justify-between items-center relative cursor-pointer w-full bg-white rounded-lg border-2 border-gray-200 outline-none p-4"
              >
                <span className="text-gray-400">Upload</span>
                <FiUploadCloud className="text-gray-400" />
                <input
                  type="file"
                  id="upload"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </label>
            </div>
          </div>
          <div className="flex items-center justify-center pb-7 pt-7">
            <div className="flex-grow border-t border-[#A3B5D7]"></div>
            <span className="mx-4 -mb-px text-sm text-gray-600">Or</span>
            <div className="flex-grow border-t border-[#A3B5D7]"></div>
          </div>
          <textarea
            type="text"
            className="h-32 p-4 border-2 border-gray-200 mb-6 outline-none rounded-lg w-full"
            placeholder="Job Description Link"
          />
          <div className="">
            <button
              onClick={send_params}
              className="text-center bg-gradient-to-r from-red-500 to-blue-500 text-white rounded-lg"
              style={{ padding: "10px 80px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextSubmit;
