// // import React, { useEffect, useState } from "react";
// // import robot from "../../assets/images/robot.png";
// // import axios from "axios";
// // import { useDispatch, useSelector } from "react-redux";
// // import { useNavigate } from "react-router-dom";
// // import { responseDataActions } from "../../store/responseDataSlice";
// // import { IoMdCloseCircleOutline } from "react-icons/io";
// // import profile from "../../assets/images/profile.png";
// // import 'regenerator-runtime'
// // import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// // import { mockApi, ApiUrl, questionApi } from "../../Constant/ApiUrl";
// // import Loader from "../../Components/Loader";
// // import { useSpeechSynthesis } from "react-speech-kit";

// // const InterviewVoice = () => {
// //   const {
// //     transcript,
// //     listening,
// //     resetTranscript,
// //     browserSupportsSpeechRecognition
// //   } = useSpeechRecognition();
// //   // const isServer = typeof window === "undefined";
// //   // if (!browserSupportsSpeechRecognition && !isServer) {
// //   //   return <div>Your browser does not support speech recognition.</div>;
// //   // }
// //   const [text, setText] = useState(transcript);
// //   const [InterviewId, setInterViewId] = useState(null);
// //   const [Loading, setLoading] = useState(false);
// //   const [History, setHistory] = useState("");
// //   const [Transcript, setTranscript] = useState(true);

// //   useEffect(() => {
// //     setText(transcript);
// //   }, [transcript])


// //   const handleClick = (e) => {
// //     e.preventDefault()
// //     // const value = new SpeechSynthesisUtterance(textToSpeech)
// //     // window.speechSynthesis.speak(value)
// //     window.speechSynthesis.onvoiceschanged = () => {
// //       // Now it's safe to retrieve the voices and set the voice property
// //       const voices = window.speechSynthesis.getVoices();
// //       const femaleVoice = voices.find(voice => voice.name.toLowerCase().includes('female'));

// //       // const textToSpeech = "Hello, welcome to my software";
// //       const value = new SpeechSynthesisUtterance(text);

// //       if (femaleVoice) {
// //         value.voice = femaleVoice;
// //       }

// //       window.speechSynthesis.speak(value);
// //     };
// //   }

// //   const [responseDatas, setResponseDatas] = useState([]);
// //   const [Name, setName] = useState("");
// //   const [Email, setEmail] = useState("");
// //   useEffect(() => {
// //     const Token = localStorage.getItem('user')
// //     Token && axios.post(ApiUrl+'/get_user', {
// //       hehe: 'jje'
// //     },
// //       {
// //         headers: {
// //           "Content-Type": "multipart/form-data",
// //           authorization: Token
// //         },

// //       })
// //       .then(x =>
// //        (
// //         x?.data?.user_data?.username&& setName(x?.data?.user_data?.username),
// //         x?.data?.user_data?.email && setEmail(x?.data?.user_data?.email)
// //        )
// //       )
// //   }, [])

// //   const navigate = useNavigate();

// //   const dispatch = useDispatch();
// //   const responseData = useSelector((state) => state.responseData);
// //   // const SelectData =useSelector(state=>state.responseData)
// //   // const SelectData =useSelector(state=>state.responseData.value.experience)

// //   if (responseDatas.questions && responseDatas.questions.length > 0) {
// //     console.log("hello go for now");
// //     // navigate("/interview"); // Replace '/interview' with your desired route
// //   }

// //   console.log({ responseData })
// //   const [Conversation, setConversation] = useState(
// //     []
// //     // [
// //     //   "me: hello how are you?",
// //     //   "robot:  hello there, thankyou for taking your time for inteviewing with us, Please introduce yourself ",
// //     //   "me: I am suffer engineer and I have 77 years of experience",
// //     //   "robot:  What specific skills do you have as a software engineer? ",
// //     //   "me: I have reactious skills and back and skills on Express",
// //     //   "robot:  What experience do you have with React and Express? ",
// //     //   "me: I usually work on Express because it is used for back and language and react is used for front and so Frontier and Express will be a good combination",
// //     //   "robot:  What other programming languages and frameworks are you familiar with? ",
// //     //   "me: next JS",
// //     //   "robot:  What experience do you have with Next.js? ",
// //     //   "me: I have 100 years",
// //     //   "robot:  What experience do you have with software engineering? "
// //     // ]
// //   );
// //   // const [Conversation, setConversation] = useState([]);

// //   const [qaPairs, setQaPairs] = useState([]);

// //   useEffect(() => {
// //     const tempQaPairs = [];
// //     let questionNumber = 1; // Initialize question number
// //     for (let i = 1; i < Conversation.length; i += 2) {
// //       const question = Conversation[i]?.replace('robot: ', '');
// //       const answer = Conversation[i + 1]?.replace('me: ', '');
// //       if (question && answer) {
// //         tempQaPairs.push({ questionNumber, question, answer });
// //         questionNumber++; // Increment question number for the next pair
// //       }
// //     }
// //     setQaPairs(tempQaPairs);
// //   }, [Conversation]);



// //   let Solved_Answers = []
// //   console.log({ Solved_Answers })


// //   const StopInterview = async (e) => {

// //     e.preventDefault()
// //     SpeechRecognition.stopListening()
// //     setLoading(true)
// //     let evaluation_id = null
// //     const formData = new FormData();
// //     formData.append("history", `${History}`);

// //     axios.post(`${mockApi}/analysis/`, formData)
// //       .then(analysis => {

// //         axios.post(`${mockApi}/criteria/`, formData)
// //           .then(criteria => {
// //             console.log({ criteria, analysis })
// //             // axios.post(`${mockApi}/end/`)
// //             axios.post(`${ApiUrl}/evaluation`, {
// //               interview_id: InterviewId,
// //               overal_decision: analysis.data.OverallDecision,
// //               overall_sentiment: analysis.data.OverallSentiment,
// //               area_of_strength: analysis.data.AreasOfStrength,
// //               area_to_improve: analysis.data.AreasToImprove,
// //               selfintroduction: criteria.data.SelfIntroduction,
// //               team_colab: criteria.data.TeamworkAndCollaboration,
// //               promblem_solving: criteria.data.ProblemSolvingSkills,
// //               adabtibility: criteria.data.Adaptibility,
// //               comunication: criteria.data.Communication
// //             })
// //               .then(async (x) => {
// //                 console.log(x, "<=======");
// //                 evaluation_id = x?.data.creat_eval?.id;
// //                 // setConversation([])
// //                 for (let i = 0; i < qaPairs.length; i++) {
// //                   const formData = new FormData();
// //                   formData.append("ques", (i + 1) + ". " + qaPairs[i].question); // Append question number to the question
// //                   formData.append("ans", qaPairs[i].answer);
// //                   // formData.append("history", `${History}`)

// //                   try {
// //                     const Response = await axios.post(
// //                       `${questionApi}/feedback/`,
// //                       formData,
// //                       {
// //                         headers: {
// //                           "Content-Type": "multipart/form-data",
// //                         },
// //                       }
// //                     );
// //                     let quest_id = 0
// //                     Response?.data && axios.post(ApiUrl + "/insert_ques_ans", {
// //                       quest: qaPairs[i].question,
// //                       answer: qaPairs[i].answer,
// //                       evaluation_id: x?.data.creat_eval?.id,
// //                       feedback: Response?.data.feedback,
// //                       revised_answer: Response?.data.revised_answer,
// //                       purpose: Response?.data.purpose,
// //                       star: Response?.data.star
// //                     }).then(x => {
// //                       quest_id = x.data.quest_ans.id;
// //                       console.log(qaPairs[i], '<===== qaPairs');
// //                       for (let c = 0; c < Response?.data?.criteria?.length; c++) {
// //                         console.log({ quest_id });

// //                         Response?.data && axios.post(ApiUrl + '/insert_ans_criteria', {
// //                           heading: Response.data.criteria[c].heading,
// //                           score: Response.data.criteria[c].score,
// //                           description: Response.data.criteria[c].description,
// //                           quest_id: x.data.quest_ans.id
// //                         })
// //                       }
// //                     })

// //                     //run this after finishing everything

// //                   } catch (error) {
// //                     console.error("Error posting data:", error);
// //                     // Optionally, handle the error, e.g., retry the request, log the error, etc.
// //                   }
// //                 }
// //                 setLoading(false)
// //                 setConversation([])
// //                 // axios.post(`${mockApi}/end/`)
// //                 // Push the complete response into the Solved_Answers array
// //                 console.log(Response.data);
// //               })

// //           })
// //       })

// //   }
// //   const { speak, voices } = useSpeechSynthesis();

// //   const handleCheck = async () => {
// //     setConversation(prev => [...prev, text])

// //     const Token = localStorage.getItem('user')
// //     axios.post(`${ApiUrl}/start_now`, {
// //       hello: 'dd'
// //     },
// //       {
// //         headers: {
// //           authorization: Token

// //         }
// //       }
// //     ).then(x => setInterViewId(x.data.start_interview.id))


// //     console.log('clicked')
// //     const formData = new FormData();
// //     formData.append("text", text);
// //     formData.append("name", Name);
// //     formData.append("desired_job", responseData.value.jobTitle);
// //     formData.append("experience", responseData.value.experience);
// //     formData.append("job_description", responseData.value.desc);
// //     // formData.append("history", History)
// //     try {
// //       const response = await axios.post(
// //         `${mockApi}/manual/`,
// //         formData,
// //         {
// //           headers: {
// //             "Content-Type": "multipart/form-data"
// //           },
// //         }
// //       );
// //       console.log(response.data, "<==== bot")
// //       setConversation(prev => [...prev, `robot: ${response.data.chat} `])
// //       setHistory(`"${response.data.history}"`)
// //       // setResponseDatas(response.data);
// //       // dispatch(responseDataActions.setResponseData(response.data));


// //       speak({ text: response.data.chat, voice: voices[5] })
// //       // speak({ text: "hello", voice: voices[5] })
// //     } catch (error) {
// //       console.error("Axios error:", error.message);
// //       // Optionally, handle error by setting an error state here and displaying it in the UI
// //     }
// //   };
// //   const handleCheckafter = async () => {
// //     setConversation(prev => [...prev, `me: ${text}`])
// //     const formData = new FormData();
// //     console.log('clicked')
// //     formData.append("text", text);
// //     formData.append("name", Name);
// //     formData.append("desired_job", responseData.value.jobTitle);
// //     formData.append("experience", responseData.value.experience);
// //     formData.append("job_description", responseData.value.desc)
// //     formData.append("history", `${History}`)

// //     try {
// //       const response = await axios.post(
// //         `${mockApi}/manual/`,
// //         formData,
// //         {
// //           headers: {
// //             "Content-Type": "multipart/form-data",
// //           },
// //         }
// //       );
// //       speak({ text: response.data.chat, voice: voices[5] })

// //       setText("")
// //       resetTranscript();
// //       setConversation(prev => [...prev, `robot: ${response?.data.chat} `])

// //       console.log(response.data, '<====== for chat history =====')
// //       // const chatHistory = `${response.data.history}`
// //       setHistory(`"${response.data.history}"`)

// //       // setResponseDatas(response.data);
// //       // dispatch(responseDataActions.setResponseData(response.data));
// //     } catch (error) {
// //       console.error("Axios error:", error.message);
// //       // Optionally, handle error by setting an error state here and displaying it in the UI
// //     }
// //   };
// //   console.log(transcript)

// //   return (
// //     <>
// //       {Loading && <Loader />}
// //       <div className="flex items-center justify-center py-8 ">
// //         {/* First Dot */}
// //         <div className="h-4 w-4 rounded-full bg-Step flex items-center justify-center">
// //           <span className="text-black pt-12">info</span>
// //         </div>
// //         {/* Dashed Line */}
// //         <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
// //         {/* Second Dot */}
// //         <div className="h-4 w-4 rounded-full bg-Step flex items-center justify-center">
// //           <span className="text-black pt-12">Interview</span>
// //         </div>
// //         {/* Dashed Line */}
// //         <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
// //         {/* Third Dot */}
// //         <div className="h-4 w-4 rounded-full bg-Step border-gray-400 border-2 flex items-center justify-center">
// //           <span className="text-black pt-12">report</span>
// //         </div>
// //       </div>
// //       <div className={`grid grid-cols-1 gap-6 w-98p h-[900px]  md:grid-cols ${!Transcript&&Conversation?.length >0 ? 'lg:grid-cols-3' : 'lg:grid-cols-1'} `}>
// //         <div className="mt-5 p-5 rounded-lg bg-white md:col-span-3 lg:col-span-2 xl:col-span-2 w-full">
// //           <h1 className="text-head text-2xl text-start">Welcome {Name}</h1>
// //           <p className="font-medium text-start leading-10 md:text-xl lg:text-xl text-sm">
// //             Click "Start Interview" to get Started! START INTERVIEW
// //           </p>
// //           <div className="flex flex-col justify-center items-center">
// //             <img src={robot} alt="robot" />
// //             {
// //               Conversation?.length < 1 ?
// //                 <button onClick={() => (SpeechRecognition.startListening({ continuous: true }), handleCheck(),setTranscript(!Transcript))} className="bg-PrimaryColor mb-4 text-white md:px-4  md:pt-4 md:pb-4 px-4  pt-4 pb-4 rounded-xl lg:text-xl text-sm">
// //                   Start Interview
// //                 </button> :
// //                 <button onClick={(e) => StopInterview(e)} className="bg-PrimaryColor mb-4 text-white md:px-4  md:pt-4 md:pb-4 px-4  pt-4 pb-4 rounded-xl lg:text-xl text-sm">
// //                   Stop Interview
// //                 </button>
// //             }
// //           </div>
// //           {Conversation?.length < 1 ?
// //             <textarea
// //               className="mt-2 p-8 rounded-xl w-full mb-4 border"
// //               rows="4"
// //               value={text}
// //               placeholder="Greeting"
// //               onChange={e => setText(e.target.value)}
// //             /> :
// //             <textarea
// //               className="mt-2 p-8 rounded-xl w-full mb-4 border"
// //               rows="4"
// //               value={text}
// //               onChange={(e) => setText(e.target.value)}
// //               placeholder="Speak into your microphone"

// //             />
// //           }

// //           <div className="flex justify-end gap-5 ">
// //             <button onClick={() => (setTranscript(!Transcript))} className="bg-head text-white text-sm rounded-xl  md:px-4 md:pt-4 md:pb-4">
// //               Show Transcript
// //             </button>
// //             {Conversation.length > 0 && <button onClick={resetTranscript} className="bg-head text-white text-sm rounded-xl  md:px-4 md:pt-4 md:pb-4">
// //               Reset
// //             </button>}
// //             <p onClick={() => console.log(responseData.value.history)}>Microphone: {listening ? 'on' : 'off'}</p>

// //             {
// //               Conversation.length > 1 ?
// //                 <button onClick={() => handleCheckafter()} className="bg-PrimaryColor rounded-xl text-sm  text-white   md:px-4 md:pe-20 md:pt-4 md:pb-4">
// //                   Send Answer
// //                 </button> :
// //                 <button disabled className="bg-PrimaryColor rounded-xl text-sm  text-white   md:px-4 md:pe-20 md:pt-4 md:pb-4">
// //                   Send Answer
// //                 </button>
// //             }
// //           </div>
// //         </div>
// //         {!Transcript && Conversation.length > 0 &&
// //           <div className="mt-5 p-5 rounded-lg overflow-y-auto  bg-white md:col-span-1 lg:col-span-1 xl:col-span-1">
// //             <div className="flex justify-between">
// //               <h1 className="text-3xl text-head">Transcript</h1>
// //               <span onClick={()=>setTranscript(!Transcript)} className="text-4xl text-PrimaryColor">
// //                 <IoMdCloseCircleOutline />
// //               </span>
// //             </div>
// //             {
// //               Conversation.map((y, index) => {
// //                 const me = y.includes('me:');
// //                 const messageContent = me ? y.replace('me:', '') : y.replace('robot:', '');

// //                 return (
// //                   <React.Fragment key={index}>
// //                     {messageContent && (
// //                       <div className="flex flex-col">
// //                         <div className={`flex items-center ${me ? 'self-end' : ''}`}>
// //                           <img src={profile} width={50} alt="Profile" />
// //                           <p className="text-center text-PrimaryColor font-medium text-xl">
// //                             {me ? 'You' : 'Interviewer'}
// //                           </p>
// //                         </div>
// //                         <div>
// //                           <p className="bg-sectionText w-auto rounded-lg text-start p-5 text-white relative z-10 rounded-tr-lg rounded-br-lg rounded-bl-lg">
// //                             <div className={`flex items-center ${me ? 'justify-end' : ''}`}>
// //                               {messageContent}
// //                             </div>
// //                             {me ? (
// //                               <div
// //                                 className="message-box"
// //                                 style={{
// //                                   position: "absolute",
// //                                   top: "0px",
// //                                   right: "-10px",
// //                                   width: "0",
// //                                   height: "0",
// //                                   borderStyle: "solid",
// //                                   borderWidth: "20px 20px 0px 0px",
// //                                   borderColor: "#3AB4E7 transparent transparent transparent",
// //                                   zIndex: "1",
// //                                 }}
// //                               ></div>
// //                             ) : (
// //                               <div
// //                                 className="message-box"
// //                                 style={{
// //                                   position: "absolute",
// //                                   top: "0px",
// //                                   left: "-10px",
// //                                   width: "0",
// //                                   height: "0",
// //                                   borderStyle: "solid",
// //                                   borderWidth: "20px 0px 10px 20px",
// //                                   borderColor: "#3AB4E7 transparent transparent transparent",
// //                                   zIndex: "1",
// //                                 }}
// //                               ></div>
// //                             )}
// //                           </p>
// //                         </div>

// //                       </div>
// //                     )}
// //                   </React.Fragment>
// //                 );
// //               })
// //             }
// //           </div >
// //         }
// //       </div >
// //     </>
// //   );
// // };

// // export default InterviewVoice;




// import React, { useEffect, useState } from "react";
// import robot from "../../assets/images/mic.png";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { responseDataActions } from "../../store/responseDataSlice";
// import { IoMdCloseCircleOutline } from "react-icons/io";
// import profile from "../../assets/images/profile.png";
// import 'regenerator-runtime'
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import { mockApi, ApiUrl, questionApi } from "../../Constant/ApiUrl";
// import Loader from "../../Components/Loader";
// import { useSpeechSynthesis } from "react-speech-kit";
// import DashboardBanner from "../../Components/DashboardBanner";

// const InterviewVoice = () => {
//   const {
//     transcript,
//     listening,
//     resetTranscript,
//     browserSupportsSpeechRecognition
//   } = useSpeechRecognition();
//   // const isServer = typeof window === "undefined";
//   // if (!browserSupportsSpeechRecognition && !isServer) {
//   //   return <div>Your browser does not support speech recognition.</div>;
//   // }
//   const [text, setText] = useState(transcript);
//   const [InterviewId, setInterViewId] = useState(null);
//   const [Loading, setLoading] = useState(false);
//   const [History, setHistory] = useState("");
//   const [Transcript, setTranscript] = useState(true);
//   const [isFirstClick, setIsFirstClick] = useState(true);


//   useEffect(() => {
//     setText(transcript);
//   }, [transcript])


//   const handleClick = (e) => {
//     e.preventDefault()
//     // const value = new SpeechSynthesisUtterance(textToSpeech)
//     // window.speechSynthesis.speak(value)
//     window.speechSynthesis.onvoiceschanged = () => {
//       // Now it's safe to retrieve the voices and set the voice property
//       const voices = window.speechSynthesis.getVoices();
//       const femaleVoice = voices.find(voice => voice.name.toLowerCase().includes('female'));

//       // const textToSpeech = "Hello, welcome to my software";
//       const value = new SpeechSynthesisUtterance(text);

//       if (femaleVoice) {
//         value.voice = femaleVoice;
//       }

//       window.speechSynthesis.speak(value);
//     };
//   }

//   const [responseDatas, setResponseDatas] = useState([]);
//   const [Name, setName] = useState("");
//   const [Email, setEmail] = useState("");
//   useEffect(() => {
//     const Token = localStorage.getItem('user')
//     Token && axios.post(ApiUrl+'/get_user', {
//       hehe: 'jje'
//     },
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           authorization: Token
//         },

//       })
//       .then(x =>
//        (
//         x?.data?.user_data?.username&& setName(x?.data?.user_data?.username),
//         x?.data?.user_data?.email && setEmail(x?.data?.user_data?.email)
//        )
//       )
//   }, [])

//   const navigate = useNavigate();

//   const dispatch = useDispatch();
//   const responseData = useSelector((state) => state.responseData);
//   // const SelectData =useSelector(state=>state.responseData)
//   // const SelectData =useSelector(state=>state.responseData.value.experience)

//   if (responseDatas.questions && responseDatas.questions.length > 0) {
//     console.log("hello go for now");
//     // navigate("/interview"); // Replace '/interview' with your desired route
//   }

//   console.log({ responseData })
//   const [Conversation, setConversation] = useState(
//     []
//     // [
//     //   "me: hello how are you?",
//     //   "robot:  hello there, thankyou for taking your time for inteviewing with us, Please introduce yourself ",
//     //   "me: I am suffer engineer and I have 77 years of experience",
//     //   "robot:  What specific skills do you have as a software engineer? ",
//     //   "me: I have reactious skills and back and skills on Express",
//     //   "robot:  What experience do you have with React and Express? ",
//     //   "me: I usually work on Express because it is used for back and language and react is used for front and so Frontier and Express will be a good combination",
//     //   "robot:  What other programming languages and frameworks are you familiar with? ",
//     //   "me: next JS",
//     //   "robot:  What experience do you have with Next.js? ",
//     //   "me: I have 100 years",
//     //   "robot:  What experience do you have with software engineering? "
//     // ]
//   );
//   // const [Conversation, setConversation] = useState([]);

//   const [qaPairs, setQaPairs] = useState([]);

//   useEffect(() => {
//     const tempQaPairs = [];
//     let questionNumber = 1; // Initialize question number
//     for (let i = 1; i < Conversation.length; i += 2) {
//       const question = Conversation[i]?.replace('robot: ', '');
//       const answer = Conversation[i + 1]?.replace('me: ', '');
//       if (question && answer) {
//         tempQaPairs.push({ questionNumber, question, answer });
//         questionNumber++; // Increment question number for the next pair
//       }
//     }
//     setQaPairs(tempQaPairs);
//   }, [Conversation]);



//   let Solved_Answers = []
//   console.log({ Solved_Answers })


//   const StopInterview = async (e) => {

//     e.preventDefault()
//     SpeechRecognition.stopListening()
//     setLoading(true)
//     let evaluation_id = null
//     const formData = new FormData();
//     formData.append("history", `${History}`);

//     axios.post(`${mockApi}/analysis/`, formData)
//       .then(analysis => {

//         axios.post(`${mockApi}/criteria/`, formData)
//           .then(criteria => {
//             console.log({ criteria, analysis })
//             // axios.post(`${mockApi}/end/`)
//             axios.post(`${ApiUrl}/evaluation`, {
//               interview_id: InterviewId,
//               overal_decision: analysis.data.OverallDecision,
//               overall_sentiment: analysis.data.OverallSentiment,
//               area_of_strength: analysis.data.AreasOfStrength,
//               area_to_improve: analysis.data.AreasToImprove,
//               selfintroduction: criteria.data.SelfIntroduction,
//               team_colab: criteria.data.TeamworkAndCollaboration,
//               promblem_solving: criteria.data.ProblemSolvingSkills,
//               adabtibility: criteria.data.Adaptibility,
//               comunication: criteria.data.Communication
//             })
//               .then(async (x) => {
//                 console.log(x, "<=======");
//                 evaluation_id = x?.data.creat_eval?.id;
//                 // setConversation([])
//                 for (let i = 0; i < qaPairs.length; i++) {
//                   const formData = new FormData();
//                   formData.append("ques", (i + 1) + ". " + qaPairs[i].question); // Append question number to the question
//                   formData.append("ans", qaPairs[i].answer);
//                   // formData.append("history", `${History}`)

//                   try {
//                     const Response = await axios.post(
//                       `${questionApi}/feedback/`,
//                       formData,
//                       {
//                         headers: {
//                           "Content-Type": "multipart/form-data",
//                         },
//                       }
//                     );
//                     let quest_id = 0
//                     Response?.data && axios.post(ApiUrl + "/insert_ques_ans", {
//                       quest: qaPairs[i].question,
//                       answer: qaPairs[i].answer,
//                       evaluation_id: x?.data.creat_eval?.id,
//                       feedback: Response?.data.feedback,
//                       revised_answer: Response?.data.revised_answer,
//                       purpose: Response?.data.purpose,
//                       star: Response?.data.star
//                     }).then(x => {
//                       quest_id = x.data.quest_ans.id;
//                       console.log(qaPairs[i], '<===== qaPairs');
//                       for (let c = 0; c < Response?.data?.criteria?.length; c++) {
//                         console.log({ quest_id });

//                         Response?.data && axios.post(ApiUrl + '/insert_ans_criteria', {
//                           heading: Response.data.criteria[c].heading,
//                           score: Response.data.criteria[c].score,
//                           description: Response.data.criteria[c].description,
//                           quest_id: x.data.quest_ans.id
//                         })
//                       }
//                     })

//                     //run this after finishing everything

//                   } catch (error) {
//                     console.error("Error posting data:", error);
//                     // Optionally, handle the error, e.g., retry the request, log the error, etc.
//                   }
//                 }
//                 setLoading(false)
//                 setConversation([])
//                 // axios.post(`${mockApi}/end/`)
//                 // Push the complete response into the Solved_Answers array
//                 console.log(Response.data);
//               })

//           })
//       })

//   }
//   const { speak, voices } = useSpeechSynthesis();

//   const handleCheck = async () => {
//     setConversation(prev => [...prev, text])

//     let textToSend;
//     if (isFirstClick) {
//         textToSend = "hello";
//         setIsFirstClick(false); // Update state to mark that the first click has happened
//     } else {
//         textToSend = text; // Use the text input by the user for subsequent clicks
//     }

//     const Token = localStorage.getItem('user')
//     axios.post(`${ApiUrl}/start_now`, {
//       hello: 'dd'
//     },
//       {
//         headers: {
//           authorization: Token

//         }
//       }
//     ).then(x => setInterViewId(x.data.start_interview.id))


//     console.log('clicked')
//     const formData = new FormData();
//     formData.append("text", textToSend);
//     formData.append("name", Name);
//     formData.append("desired_job", responseData.value.jobTitle);
//     formData.append("experience", responseData.value.experience);
//     formData.append("job_description", responseData.value.desc);
//     // formData.append("history", History)
//     try {
//       const response = await axios.post(
//         `${mockApi}/manual/`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data"
//           },
//         }
//       );
//       console.log(response.data, "<==== bot")
//       setConversation(prev => [...prev, `robot: ${response.data.chat} `])
//       setHistory(`"${response.data.history}"`)
//       // setResponseDatas(response.data);
//       // dispatch(responseDataActions.setResponseData(response.data));


//       speak({ text: response.data.chat, voice: voices[5] })
//       // speak({ text: "hello", voice: voices[5] })
//     } catch (error) {
//       console.error("Axios error:", error.message);
//       // Optionally, handle error by setting an error state here and displaying it in the UI
//     }
//   };
//   const handleCheckafter = async () => {
//     setConversation(prev => [...prev, `me: ${text}`])
//     const formData = new FormData();
//     console.log('clicked')
//     formData.append("text", text);
//     formData.append("name", Name);
//     formData.append("desired_job", responseData.value.jobTitle);
//     formData.append("experience", responseData.value.experience);
//     formData.append("job_description", responseData.value.desc)
//     formData.append("history", `${History}`)

//     try {
//       const response = await axios.post(
//         `${mockApi}/manual/`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       console.log(response.data.chat)
//       speak({ text: response.data.chat, voice: voices[5] })

//       setText("")
//       resetTranscript();
//       setConversation(prev => [...prev, `robot: ${response?.data.chat} `])

//       console.log(response.data, '<====== for chat history =====')
//       // const chatHistory = `${response.data.history}`
//       setHistory(`"${response.data.history}"`)

//       // setResponseDatas(response.data);
//       // dispatch(responseDataActions.setResponseData(response.data));
//     } catch (error) {
//       console.error("Axios error:", error.message);
//       // Optionally, handle error by setting an error state here and displaying it in the UI
//     }
//   };
//   console.log(transcript)

//   return (
//     <>
//       {Loading && <Loader />}

//       <div className={`grid grid-cols-1 gap-6 w-98p h-[900px]  md:grid-cols ${!Transcript&&Conversation?.length >0 ? 'lg:grid-cols-3' : 'lg:grid-cols-1'} `}>
//         <div className="mt-5 p-5 rounded-lg bg-white md:col-span-3 lg:col-span-2 xl:col-span-2 w-full">
//           <DashboardBanner />
//           <h1 className="text-head text-2xl text-start">Welcome {Name}</h1>
//           <p className="font-medium text-start leading-10 md:text-xl lg:text-xl text-sm">
//             Click "Start Interview" to get Started! START INTERVIEW
//           </p>
//           <div className="flex flex-col justify-center items-center">
//             <img src={robot} alt="robot" />
//             {
//               Conversation?.length < 1 ?
//                 <button onClick={() => (SpeechRecognition.startListening({ continuous: true }), handleCheck(),setTranscript(!Transcript))} className="bg-PrimaryColor mb-4 text-white md:px-4  md:pt-4 md:pb-4 px-4  pt-4 pb-4 rounded-xl lg:text-xl text-sm">
//                   Start Interview
//                 </button> :
//                 <button onClick={(e) => StopInterview(e)} className="bg-PrimaryColor mb-4 text-white md:px-4  md:pt-4 md:pb-4 px-4  pt-4 pb-4 rounded-xl lg:text-xl text-sm">
//                   Stop Interview
//                 </button>
//             }
//           </div>
//           {Conversation?.length < 1 ?
//             <textarea
//               className="mt-2 p-8 rounded-xl w-full mb-4 border"
//               rows="4"
//               value={text}
//               placeholder="Greeting"
//               onChange={e => setText(e.target.value)}
//             /> :
//             <textarea
//               className="mt-2 p-8 rounded-xl w-full mb-4 border"
//               rows="4"
//               value={text}
//               onChange={(e) => setText(e.target.value)}
//               placeholder="Speak into your microphone"

//             />
//           }

//           <div className="flex justify-end gap-5 ">
//             <button onClick={() => (setTranscript(!Transcript))} className="bg-head text-white text-sm rounded-xl  md:px-4 md:pt-4 md:pb-4">
//               Show Transcript
//             </button>
//             {Conversation.length > 0 && <button onClick={resetTranscript} className="bg-head text-white text-sm rounded-xl  md:px-4 md:pt-4 md:pb-4">
//               Reset
//             </button>}
//             <p onClick={() => console.log(responseData.value.history)}>Microphone: {listening ? 'on' : 'off'}</p>

//             {
//               Conversation.length > 1 ?
//                 <button onClick={() => handleCheckafter()} className="bg-PrimaryColor rounded-xl text-sm  text-white   md:px-4 md:pe-20 md:pt-4 md:pb-4">
//                   Send Answer
//                 </button> :
//                 <button disabled className="bg-PrimaryColor rounded-xl text-sm  text-white   md:px-4 md:pe-20 md:pt-4 md:pb-4">
//                   Send Answer
//                 </button>
//             }
//           </div>
//         </div>
//         {!Transcript && Conversation.length > 0 &&
//           <div className="mt-5 p-5 rounded-lg overflow-y-auto  bg-white md:col-span-1 lg:col-span-1 xl:col-span-1">
//             <div className="flex justify-between">
//               <h1 className="text-3xl text-head">Transcript</h1>
//               <span onClick={()=>setTranscript(!Transcript)} className="text-4xl text-PrimaryColor">
//                 <IoMdCloseCircleOutline />
//               </span>
//             </div>
//             {
//               Conversation.map((y, index) => {
//                 const me = y.includes('me:');
//                 const messageContent = me ? y.replace('me:', '') : y.replace('robot:', '');

//                 return (
//                   <React.Fragment key={index}>
//                     {messageContent && (
//                       <div className="flex flex-col">
//                         <div className={`flex items-center ${me ? 'self-end' : ''}`}>
//                           <img src={profile} width={50} alt="Profile" />
//                           <p className="text-center text-PrimaryColor font-medium text-xl">
//                             {me ? 'You' : 'Interviewer'}
//                           </p>
//                         </div>
//                         <div>
//                           <p className="bg-sectionText w-auto rounded-lg text-start p-5 text-white relative z-10 rounded-tr-lg rounded-br-lg rounded-bl-lg">
//                             <div className={`flex items-center ${me ? 'justify-end' : ''}`}>
//                               {messageContent}
//                             </div>
//                             {me ? (
//                               <div
//                                 className="message-box"
//                                 style={{
//                                   position: "absolute",
//                                   top: "0px",
//                                   right: "-10px",
//                                   width: "0",
//                                   height: "0",
//                                   borderStyle: "solid",
//                                   borderWidth: "20px 20px 0px 0px",
//                                   borderColor: "#3AB4E7 transparent transparent transparent",
//                                   zIndex: "1",
//                                 }}
//                               ></div>
//                             ) : (
//                               <div
//                                 className="message-box"
//                                 style={{
//                                   position: "absolute",
//                                   top: "0px",
//                                   left: "-10px",
//                                   width: "0",
//                                   height: "0",
//                                   borderStyle: "solid",
//                                   borderWidth: "20px 0px 10px 20px",
//                                   borderColor: "#3AB4E7 transparent transparent transparent",
//                                   zIndex: "1",
//                                 }}
//                               ></div>
//                             )}
//                           </p>
//                         </div>

//                       </div>
//                     )}
//                   </React.Fragment>
//                 );
//               })
//             }
//           </div >
//         }
//       </div >
//     </>
//   );
// };

// export default InterviewVoice;






























// import React, { useEffect, useState } from "react";
// import robot from "../../assets/images/mic.png";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { responseDataActions } from "../../store/responseDataSlice";
// import { IoMdClose, IoMdCloseCircleOutline } from "react-icons/io";
// import profile from "../../assets/images/profile.png";
// import 'regenerator-runtime'
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import { mockApi, ApiUrl, questionApi } from "../../Constant/ApiUrl";
// import Loader from "../../Components/Loader";
// import { useSpeechSynthesis } from "react-speech-kit";
// import DashboardBanner from "../../Components/DashboardBanner";
// import { FaRegCirclePlay } from "react-icons/fa6";
// import SiriWave from 'react-siriwave';
// import voice from '../../assets/images/voice.gif'
// const Dashboard = () => {
//   const {
//     transcript,
//     listening,
//     resetTranscript,
//     browserSupportsSpeechRecognition
//   } = useSpeechRecognition();
//   // const isServer = typeof window === "undefined";
//   // if (!browserSupportsSpeechRecognition && !isServer) {
//   //   return <div>Your browser does not support speech recognition.</div>;
//   // }
//   const [text, setText] = useState(transcript);
//   const [InterviewId, setInterViewId] = useState(null);
//   const [Loading, setLoading] = useState(false);
//   const [History, setHistory] = useState("");
//   const [Transcript, setTranscript] = useState(true);
//   const [isFirstClick, setIsFirstClick] = useState(true);

//   useEffect(() => {
//     setText(transcript);
//   }, [transcript])


//   const handleClick = (e) => {
//     e.preventDefault()
//     // const value = new SpeechSynthesisUtterance(textToSpeech)
//     // window.speechSynthesis.speak(value)
//     window.speechSynthesis.onvoiceschanged = () => {
//       // Now it's safe to retrieve the voices and set the voice property
//       const voices = window.speechSynthesis.getVoices();
//       const femaleVoice = voices.find(voice => voice.name.toLowerCase().includes('female'));

//       // const textToSpeech = "Hello, welcome to my software";
//       const value = new SpeechSynthesisUtterance(text);

//       if (femaleVoice) {
//         value.voice = femaleVoice;
//       }

//       window.speechSynthesis.speak(value);
//     };
//   }

//   const [responseDatas, setResponseDatas] = useState([]);
//   const [Name, setName] = useState("");
//   const [Email, setEmail] = useState("");
//   useEffect(() => {
//     const Token = localStorage.getItem('user')
//     Token && axios.post(ApiUrl + '/get_user', {
//       hehe: 'jje'
//     },
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           authorization: Token
//         },

//       })
//       .then(x =>
//       (
//         x?.data?.user_data?.username && setName(x?.data?.user_data?.username),
//         x?.data?.user_data?.email && setEmail(x?.data?.user_data?.email)
//       )
//       )
//   }, [])

//   const navigate = useNavigate();

//   const dispatch = useDispatch();
//   const responseData = useSelector((state) => state.responseData);
//   // const SelectData =useSelector(state=>state.responseData)
//   // const SelectData =useSelector(state=>state.responseData.value.experience)

//   if (responseDatas.questions && responseDatas.questions.length > 0) {
//     console.log("hello go for now");
//     // navigate("/interview"); // Replace '/interview' with your desired route
//   }

//   console.log({ responseData })
//   const [Conversation, setConversation] = useState(
//     []
//     // [
//     //   "me: hello how are you?",
//     //   "robot:  hello there, thankyou for taking your time for inteviewing with us, Please introduce yourself ",
//     //   "me: I am suffer engineer and I have 77 years of experience",
//     //   "robot:  What specific skills do you have as a software engineer? ",
//     //   "me: I have reactious skills and back and skills on Express",
//     //   "robot:  What experience do you have with React and Express? ",
//     //   "me: I usually work on Express because it is used for back and language and react is used for front and so Frontier and Express will be a good combination",
//     //   "robot:  What other programming languages and frameworks are you familiar with? ",
//     //   "me: next JS",
//     //   "robot:  What experience do you have with Next.js? ",
//     //   "me: I have 100 years",
//     //   "robot:  What experience do you have with software engineering? "
//     // ]
//   );
//   // const [Conversation, setConversation] = useState([]);

//   const [qaPairs, setQaPairs] = useState([]);

//   useEffect(() => {
//     const tempQaPairs = [];
//     let questionNumber = 1; // Initialize question number
//     for (let i = 1; i < Conversation.length; i += 2) {
//       const question = Conversation[i]?.replace('robot: ', '');
//       const answer = Conversation[i + 1]?.replace('me: ', '');
//       if (question && answer) {
//         tempQaPairs.push({ questionNumber, question, answer });
//         questionNumber++; // Increment question number for the next pair
//       }
//     }
//     setQaPairs(tempQaPairs);
//   }, [Conversation]);



//   const [isSpeaking, setIsSpeaking] = useState(false);
//   useEffect(() => {
//     let timer;
//     if (transcript !== '') {
//       setIsSpeaking(true); // User is speaking
//       // Clear previous timer if exists
//       clearTimeout(timer);
//       // Start a new timer to check if user stopped speaking after 2 seconds
//       timer = setTimeout(() => {
//         setIsSpeaking(false); // User stopped speaking
//         handleCheckafter(); // Trigger your function
//       }, 2000);
//     } else {
//       setIsSpeaking(false); // User stopped speaking
//       // Clear timer when user stops speaking
//       clearTimeout(timer);
//     }

//     // Clear the timer on component unmount
//     return () => clearTimeout(timer);
//   }, [transcript]);



//   let Solved_Answers = []
//   console.log({ Solved_Answers })


//   const StopInterview = async (e) => {

//     e.preventDefault()
//     SpeechRecognition.stopListening()
//     setLoading(true)
//     let evaluation_id = null
//     const formData = new FormData();
//     formData.append("history", `${History}`);

//     axios.post(`${mockApi}/analysis/`, formData)
//       .then(analysis => {

//         axios.post(`${mockApi}/criteria/`, formData)
//           .then(criteria => {
//             console.log({ criteria, analysis })
//             // axios.post(`${mockApi}/end/`)
//             axios.post(`${ApiUrl}/evaluation`, {
//               interview_id: InterviewId,
//               overal_decision: analysis.data.OverallDecision,
//               overall_sentiment: analysis.data.OverallSentiment,
//               area_of_strength: analysis.data.AreasOfStrength,
//               area_to_improve: analysis.data.AreasToImprove,
//               selfintroduction: criteria.data.SelfIntroduction,
//               team_colab: criteria.data.TeamworkAndCollaboration,
//               promblem_solving: criteria.data.ProblemSolvingSkills,
//               adabtibility: criteria.data.Adaptibility,
//               comunication: criteria.data.Communication
//             })
//               .then(async (x) => {
//                 console.log(x, "<=======");
//                 evaluation_id = x?.data.creat_eval?.id;
//                 // setConversation([])
//                 for (let i = 0; i < qaPairs.length; i++) {
//                   const formData = new FormData();
//                   formData.append("ques", (i + 1) + ". " + qaPairs[i].question); // Append question number to the question
//                   formData.append("ans", qaPairs[i].answer);
//                   // formData.append("history", `${History}`)

//                   try {
//                     const Response = await axios.post(
//                       `${questionApi}/feedback/`,
//                       formData,
//                       {
//                         headers: {
//                           "Content-Type": "multipart/form-data",
//                         },
//                       }
//                     );
//                     let quest_id = 0
//                     Response?.data && axios.post(ApiUrl + "/insert_ques_ans", {
//                       quest: qaPairs[i].question,
//                       answer: qaPairs[i].answer,
//                       evaluation_id: x?.data.creat_eval?.id,
//                       feedback: Response?.data.feedback,
//                       revised_answer: Response?.data.revised_answer,
//                       purpose: Response?.data.purpose,
//                       star: Response?.data.star
//                     }).then(x => {
//                       quest_id = x.data.quest_ans.id;
//                       console.log(qaPairs[i], '<===== qaPairs');
//                       for (let c = 0; c < Response?.data?.criteria?.length; c++) {
//                         console.log({ quest_id });

//                         Response?.data && axios.post(ApiUrl + '/insert_ans_criteria', {
//                           heading: Response.data.criteria[c].heading,
//                           score: Response.data.criteria[c].score,
//                           description: Response.data.criteria[c].description,
//                           quest_id: x.data.quest_ans.id
//                         })
//                       }
//                     })

//                     //run this after finishing everything

//                   } catch (error) {
//                     console.error("Error posting data:", error);
//                     // Optionally, handle the error, e.g., retry the request, log the error, etc.
//                   }
//                 }
//                 setLoading(false)
//                 setConversation([])
//                 // axios.post(`${mockApi}/end/`)
//                 // Push the complete response into the Solved_Answers array
//                 console.log(Response.data);
//               })

//           })
//       })

//   }
//   const { speak, voices } = useSpeechSynthesis();

//   const handleCheck = async () => {
//     setConversation(prev => [...prev, text])

//     let textToSend;
//     if (isFirstClick) {
//       textToSend = "hello";
//       setIsFirstClick(false); // Update state to mark that the first click has happened
//     } else {
//       textToSend = text; // Use the text input by the user for subsequent clicks
//     }

//     const Token = localStorage.getItem('user')
//     axios.post(`${ApiUrl}/start_now`, {
//       hello: 'dd'
//     },
//       {
//         headers: {
//           authorization: Token

//         }
//       }
//     ).then(x => setInterViewId(x.data.start_interview.id))


//     console.log('clicked')
//     const formData = new FormData();
//     formData.append("text", textToSend);
//     formData.append("name", Name);
//     formData.append("desired_job", responseData.value.jobTitle);
//     formData.append("experience", responseData.value.experience);
//     formData.append("job_description", responseData.value.desc);
//     // formData.append("history", History)
//     try {
//       const response = await axios.post(
//         `${mockApi}/manual/`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data"
//           },
//         }
//       );
//       console.log(response.data, "<==== bot")
//       setConversation(prev => [...prev, `robot: ${response.data.chat} `])
//       setHistory(`"${response.data.history}"`)
//       // setResponseDatas(response.data);
//       // dispatch(responseDataActions.setResponseData(response.data));


//       speak({ text: response.data.chat, voice: voices[5] })
//       // speak({ text: "hello", voice: voices[5] })
//     } catch (error) {
//       console.error("Axios error:", error.message);
//       // Optionally, handle error by setting an error state here and displaying it in the UI
//     }
//   };






//   const handleCheckafter = async () => {
//     setConversation(prev => [...prev, `me: ${text}`])
//     const formData = new FormData();
//     console.log('Handling check after 2 seconds');
//     console.log('clicked')
//     formData.append("text", text);
//     formData.append("name", Name);
//     formData.append("desired_job", responseData.value.jobTitle);
//     formData.append("experience", responseData.value.experience);
//     formData.append("job_description", responseData.value.desc)
//     formData.append("history", `${History}`)

//     try {
//       const response = await axios.post(
//         `${mockApi}/manual/`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       speak({ text: response.data.chat, voice: voices[5] })

//       setText("")
//       resetTranscript();
//       setConversation(prev => [...prev, `robot: ${response?.data.chat} `])

//       console.log(response.data, '<====== for chat history =====')
//       // const chatHistory = `${response.data.history}`
//       setHistory(`"${response.data.history}"`)

//       // setResponseDatas(response.data);
//       // dispatch(responseDataActions.setResponseData(response.data));
//     } catch (error) {
//       console.error("Axios error:", error.message);
//       // Optionally, handle error by setting an error state here and displaying it in the UI
//     }
//   }
//   console.log({ InterviewId })
//   return (
//     <>
//       {Loading && <Loader />}
//       <div className="h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden ">
//         <div className="sm:px-10 lg:px-20 px-10 justify-start flex flex-col w-full bg-[#F0F0F0] font-poppins h-[100vh] rounded-3xl pt-4 overflow-y-auto no-scrollbar " style={{ maxHeight: 'calc(110vh - 160px)' }}>
//         <DashboardBanner />
//           <div className={`grid grid-cols-1 gap-6 w-98p h-[900px] lg:h-[550px] md:h-[650px]  md:grid-cols ${!Transcript && Conversation?.length > 0 ? 'lg:grid-cols-3' : 'lg:grid-cols-1'} `}>
//             <div className="mt-5 p-5 rounded-lg md:col-span-3 lg:col-span-2 xl:col-span-2 w-full">

//               <h1 className="text-head text-2xl font-bold text-start leading-10">Welcome {Name}!</h1>
//               <p className="font-medium text-start leading-10 md:text-xl lg:text-xl text-[10px] text-[#3D3D3D]">
//                 Click "Start Interview" to get Started! START INTERVIEW
//               </p>
//               <div className="flex flex-col justify-center items-center">
//                 {
//                   transcript === '' ? <img src={robot} alt="robot" className="py-10" /> : (
//                     <>
//                       <div className="p-10 flex ">
//                         {/* hrllo */}
//                         <img src={robot} alt="robot" className="py-10" /> 
//                        <img src={voice}  width={200} alt="" />
//                       </div>
//                     </>
//                   )
//                 }

//                 {
//                   Conversation?.length < 1 ?
//                     <button onClick={() => (SpeechRecognition.startListening({ continuous: true }), handleCheck(), setTranscript(!Transcript))} className="bg-gradient-to-r from-red-700 to-blue-700 mb-4 text-white flex justify-center py-2 px-8 gap-5 items-center rounded-xl lg:text-xl text-sm">
//                       <FaRegCirclePlay className="text-2xl" /> Start Interview
//                     </button>
//                     :
//                     <button onClick={(e) => StopInterview(e)} className="bg-gradient-to-r from-red-800 to-blue-800 mb-4 text-white flex justify-center py-2 px-8 gap-5 rounded-xl lg:text-xl text-sm">
//                       <IoMdClose className="text-3xl" />  Stop Interview
//                     </button>
//                 }
//               </div>
//               {/* {Conversation?.length < 1 ?
//             <textarea
//               className="mt-2 p-8 rounded-xl w-full mb-4 border"
//               rows="4"
//               value={text}
//               placeholder="Greeting"
//               onChange={e => setText(e.target.value)}
//             /> :
//             <textarea
//               className="mt-2 p-8 rounded-xl w-full mb-4 border"
//               rows="4"
//               value={text}
//               onChange={(e) => setText(e.target.value)}
//               placeholder="Speak into your microphone"

//             />
//           } */}

//               {/* <div className="flex justify-end gap-5 ">
//             <button onClick={() => (setTranscript(!Transcript))} className="bg-head text-white text-sm rounded-xl  md:px-4 md:pt-4 md:pb-4">
//               Show Transcript
//             </button>
//             {Conversation.length > 0 && <button onClick={resetTranscript} className="bg-head text-white text-sm rounded-xl  md:px-4 md:pt-4 md:pb-4">
//               Reset
//             </button>}
//             <p onClick={() => console.log(responseData.value.history)}>Microphone: {listening ? 'on' : 'off'}</p>

//             {
//               Conversation.length > 1 ?
//                 <button onClick={() => handleCheckafter()} className="bg-PrimaryColor rounded-xl text-sm  text-white   md:px-4 md:pe-20 md:pt-4 md:pb-4">
//                   Send Answer
//                 </button> :
//                 <button disabled className="bg-PrimaryColor rounded-xl text-sm  text-white   md:px-4 md:pe-20 md:pt-4 md:pb-4">
//                   Send Answer
//                 </button>
//             }
//           </div> */}
//             </div>
//             {!Transcript && Conversation.length > 0 &&
//               <div className="scroll mt-5 p-5 rounded-lg overflow-y-auto bg-white md:col-span-1 lg:col-span-1 xl:col-span-1">
//                 <div className="flex justify-between ms-10">
//                   <h1 className="text-2xl text-head">Transcript</h1>
//                   <span onClick={() => setTranscript(!Transcript)} className="text-4xl text-head">
//                     <IoMdCloseCircleOutline />
//                   </span>
//                 </div>
//                 {
//                   Conversation.map((y, index) => {
//                     const me = y.includes('me:');
//                     const messageContent = me ? y.replace('me:', '') : y.replace('robot:', '');

//                     return (
//                       <React.Fragment key={index}>
//                         {messageContent && (
//                           <div className="flex flex-col z-0">
//                             <div className={`flex items-center ${me ? 'self-end' : ''}`}>
//                               <img src={profile} alt="Profile" />
//                               <p className="text-center text-head ms-2 font-medium text-lg">
//                                 {me ? 'You' : 'Interviewer'}
//                               </p>
//                             </div>
//                             <div>
//                               <p className="bg-gradient-to-r from-[#5653FF] to-[#8163F0] w-auto rounded-lg text-start p-5 text-white relative z-10 rounded-tr-lg rounded-br-lg rounded-bl-lg">
//                                 <div className={`flex items-center ${me ? 'justify-end' : ''}`}>
//                                   {messageContent}
//                                 </div>
//                               </p>
//                             </div>
//                           </div>
//                         )}
//                       </React.Fragment>
//                     );
//                   })
//                 }
//               </div >
//             }
//           </div >
//         </div>
//       </div>
//     </>
//   );
// };

// export default Dashboard;








// import React, { useEffect, useState } from "react";
// import robot from "../../assets/images/mic.png";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { responseDataActions } from "../../store/responseDataSlice";
// import { IoMdClose, IoMdCloseCircleOutline } from "react-icons/io";
// import profile from "../../assets/images/profile.png";
// import 'regenerator-runtime';
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import { mockApi, ApiUrl, questionApi } from "../../Constant/ApiUrl";
// import Loader from "../../Components/Loader";
// import DashboardBanner from "../../Components/DashboardBanner";
// import { FaRegCirclePlay } from "react-icons/fa6";
// import SiriWave from 'react-siriwave';
// import voice from '../../assets/images/voice.gif';
// import { useTts } from "react-tts"; // Import the TTS hook

// const Dashboard = () => {
//   const { transcript, listening, resetTranscript } = useSpeechRecognition();
//   const [text, setText] = useState(transcript);
//   const [InterviewId, setInterViewId] = useState(null);
//   const [Loading, setLoading] = useState(false);
//   const [History, setHistory] = useState("");
//   const [Transcript, setTranscript] = useState(true);
//   const [isFirstClick, setIsFirstClick] = useState(true);
//   const [responseDatas, setResponseDatas] = useState([]);
//   const [Name, setName] = useState("");
//   const [Email, setEmail] = useState("");
//   const [Conversation, setConversation] = useState([]);
//   const [qaPairs, setQaPairs] = useState([]);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const responseData = useSelector((state) => state.responseData);

//   // Use the TTS hook for text-to-speech
//   const { speak } = useTts();

//   useEffect(() => {
//     const Token = localStorage.getItem('user');
//     if (Token) {
//       axios.post(`${ApiUrl}/get_user`, {}, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           authorization: Token
//         }
//       })
//       .then((res) => {
//         setName(res?.data?.user_data?.username || "");
//         setEmail(res?.data?.user_data?.email || "");
//       });
//     }
//   }, []);

//   useEffect(() => {
//     const tempQaPairs = [];
//     let questionNumber = 1;
//     for (let i = 1; i < Conversation.length; i += 2) {
//       const question = Conversation[i]?.replace('robot: ', '');
//       const answer = Conversation[i + 1]?.replace('me: ', '');
//       if (question && answer) {
//         tempQaPairs.push({ questionNumber, question, answer });
//         questionNumber++;
//       }
//     }
//     setQaPairs(tempQaPairs);
//   }, [Conversation]);

//   useEffect(() => {
//     setText(transcript);
//   }, [transcript]);

//   const playVoice = (message) => {
//     const utterance = new SpeechSynthesisUtterance(message);
//     window.speechSynthesis.speak(utterance);
//   };


//   const handleCheck = async () => {
//     setConversation((prev) => [...prev, `me: ${text}`]);
//     const formData = new FormData();
//     formData.append("text", isFirstClick ? "hello" : text);
//     formData.append("name", Name);
//     formData.append("desired_job", responseData.value.jobTitle);
//     formData.append("experience", responseData.value.experience);
//     formData.append("job_description", responseData.value.desc);
//     try {
//       const response = await axios.post(`${mockApi}/manual/`, formData, {
//         headers: { "Content-Type": "multipart/form-data" }
//       });
//       setConversation((prev) => [...prev, `robot: ${response.data.chat}`]);
//       setHistory(response.data.history || "");
//       playVoice(response.data.chat);
//       setIsFirstClick(false);
//     } catch (error) {
//       console.error("Axios error:", error.message);
//     }
//   };

//   const handleCheckafter = async () => {
//     setConversation((prev) => [...prev, `me: ${text}`]);
//     const formData = new FormData();
//     formData.append("text", text);
//     formData.append("name", Name);
//     formData.append("desired_job", responseData.value.jobTitle);
//     formData.append("experience", responseData.value.experience);
//     formData.append("job_description", responseData.value.desc);
//     formData.append("history", History);
//     try {
//       const response = await axios.post(`${mockApi}/manual/`, formData, {
//         headers: { "Content-Type": "multipart/form-data" }
//       });
//       setConversation((prev) => [...prev, `robot: ${response.data.chat}`]);
//       setHistory(response.data.history || "");
//       playVoice(response.data.chat);
//       resetTranscript();
//     } catch (error) {
//       console.error("Axios error:", error.message);
//     }
//   };

//   const StopInterview = async (e) => {
//     e.preventDefault();
//     SpeechRecognition.stopListening();
//     setLoading(true);
//     const formData = new FormData();
//     formData.append("history", History);
//     try {
//       const analysis = await axios.post(`${mockApi}/analysis/`, formData);
//       const criteria = await axios.post(`${mockApi}/criteria/`, formData);
//       const evaluationData = {
//         interview_id: InterviewId,
//         overal_decision: analysis.data.OverallDecision,
//         overall_sentiment: analysis.data.OverallSentiment,
//         area_of_strength: analysis.data.AreasOfStrength,
//         area_to_improve: analysis.data.AreasToImprove,
//         selfintroduction: criteria.data.SelfIntroduction,
//         team_colab: criteria.data.TeamworkAndCollaboration,
//         promblem_solving: criteria.data.ProblemSolvingSkills,
//         adabtibility: criteria.data.Adaptibility,
//         comunication: criteria.data.Communication
//       };
//       const evalResponse = await axios.post(`${ApiUrl}/evaluation`, evaluationData);
//       const evaluation_id = evalResponse?.data.creat_eval?.id;

//       for (let i = 0; i < qaPairs.length; i++) {
//         const questionData = new FormData();
//         questionData.append("ques", (i + 1) + ". " + qaPairs[i].question);
//         questionData.append("ans", qaPairs[i].answer);

//         const Response = await axios.post(`${questionApi}/feedback/`, questionData, {
//           headers: { "Content-Type": "multipart/form-data" }
//         });

//         await axios.post(ApiUrl + "/insert_ques_ans", {
//           quest: qaPairs[i].question,
//           answer: qaPairs[i].answer,
//           evaluation_id: evaluation_id,
//           feedback: Response?.data.feedback,
//           revised_answer: Response?.data.revised_answer,
//           purpose: Response?.data.purpose,
//           star: Response?.data.star
//         });
//       }
//       setLoading(false);
//       setConversation([]);
//     } catch (error) {
//       console.error("Error stopping interview:", error);
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       {Loading && <Loader />}
//       <div className="h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden">
//         <div className="sm:px-10 lg:px-20 px-10 justify-start flex flex-col w-full bg-[#F0F0F0] font-poppins h-[100vh] rounded-3xl pt-4 overflow-y-auto no-scrollbar">
//           <DashboardBanner />
//           <div className={`grid grid-cols-1 gap-6 w-98p h-[900px] lg:h-[550px] md:h-[650px] md:grid-cols ${!Transcript && Conversation?.length > 0 ? 'lg:grid-cols-3' : 'lg:grid-cols-1'}`}>
//             <div className="mt-5 p-5 rounded-lg md:col-span-3 lg:col-span-2 xl:col-span-2 w-full">
//               <h1 className="text-head text-2xl font-bold text-start leading-10">Welcome {Name}!</h1>
//               <p className="font-medium text-start leading-10 md:text-xl lg:text-xl text-[10px] text-[#3D3D3D]">
//                 Click "Start Interview" to get Started!
//               </p>
//               <div className="flex flex-col justify-center items-center">
//                 {
//                   transcript === '' ? <img src={robot} alt="robot" className="py-10" /> : (
//                     <div className="p-10 flex">
//                       <img src={robot} alt="robot" className="py-10" />
//                       <img src={voice} width={200} alt="" />
//                     </div>
//                   )
//                 }
//                 {
//                   Conversation?.length < 1 ?
//                     <button onClick={() => (SpeechRecognition.startListening({ continuous: true }), handleCheck(), setTranscript(!Transcript))} className="bg-gradient-to-r from-red-700 to-blue-700 mb-4 text-white flex justify-center py-2 px-8 gap-5 items-center rounded-xl lg:text-xl text-sm">
//                       <FaRegCirclePlay className="text-2xl" /> Start Interview
//                     </button>
//                     :
//                     <button onClick={(e) => StopInterview(e)} className="bg-gradient-to-r from-red-800 to-blue-800 mb-4 text-white flex justify-center py-2 px-8 gap-5 rounded-xl lg:text-xl text-sm">
//                       <IoMdClose className="text-3xl" /> Stop Interview
//                     </button>
//                 }
//               </div>
//             </div>
//             {!Transcript && Conversation.length > 0 &&
//               <div className="scroll mt-5 p-5 rounded-lg overflow-y-auto bg-white md:col-span-1 lg:col-span-1 xl:col-span-1">
//                 <div className="flex justify-between ms-10">
//                   <h1 className="text-2xl text-head">Transcript</h1>
//                   <span onClick={() => setTranscript(!Transcript)} className="text-4xl text-head">
//                     <IoMdCloseCircleOutline />
//                   </span>
//                 </div>
//                 {
//                   Conversation.map((y, index) => {
//                     const me = y.includes('me:');
//                     const messageContent = me ? y.replace('me:', '') : y.replace('robot:', '');

//                     return (
//                       <React.Fragment key={index}>
//                         {messageContent && (
//                           <div className="flex flex-col z-0">
//                             <div className={`flex items-center ${me ? 'self-end' : ''}`}>
//                               <img src={profile} alt="Profile" />
//                               <p className="text-center text-head ms-2 font-medium text-lg">
//                                 {me ? 'You' : 'Interviewer'}
//                               </p>
//                             </div>
//                             <div>
//                               <p className="bg-gradient-to-r from-[#5653FF] to-[#8163F0] w-auto rounded-lg text-start p-5 text-white relative z-10 rounded-tr-lg rounded-br-lg rounded-bl-lg">
//                                 <div className={`flex items-center ${me ? 'justify-end' : ''}`}>
//                                   {messageContent}
//                                 </div>
//                               </p>
//                             </div>
//                           </div>
//                         )}
//                       </React.Fragment>
//                     );
//                   })
//                 }
//               </div>
//             }
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Dashboard;








// import React, { useEffect, useState } from "react";
// import robot from "../../assets/images/mic.png";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { responseDataActions } from "../../store/responseDataSlice";
// import { IoMdClose, IoMdCloseCircleOutline } from "react-icons/io";
// import profile from "../../assets/images/profile.png";
// import 'regenerator-runtime';
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import { mockApi, ApiUrl, questionApi } from "../../Constant/ApiUrl";
// import Loader from "../../Components/Loader";
// import DashboardBanner from "../../Components/DashboardBanner";
// import { FaRegCirclePlay } from "react-icons/fa6";
// import voice from '../../assets/images/voice.gif';
// import { useTts } from "react-tts"; // Import the TTS hook

// const Dashboard = () => {
//   const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
//   const [text, setText] = useState(transcript);
//   const [InterviewId, setInterViewId] = useState(null);
//   const [Loading, setLoading] = useState(false);
//   const [History, setHistory] = useState("");
//   const [TranscriptState, setTranscriptState] = useState(true);
//   const [isFirstClick, setIsFirstClick] = useState(true);
//   const [responseDatas, setResponseDatas] = useState([]);
//   const [Name, setName] = useState("");
//   const [Email, setEmail] = useState("");
//   const [Conversation, setConversation] = useState([]);
//   const [qaPairs, setQaPairs] = useState([]);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const responseData = useSelector((state) => state.responseData);

//   // Use the TTS hook for text-to-speech
//   const { speak } = useTts();

//   useEffect(() => {
//     const Token = localStorage.getItem('user');
//     if (Token) {
//       axios.post(`${ApiUrl}/get_user`, {}, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           authorization: Token
//         }
//       })
//       .then((res) => {
//         setName(res?.data?.user_data?.username || "");
//         setEmail(res?.data?.user_data?.email || "");
//       });
//     }
//   }, []);

//   useEffect(() => {
//     const tempQaPairs = [];
//     let questionNumber = 1;
//     for (let i = 1; i < Conversation.length; i += 2) {
//       const question = Conversation[i]?.replace('robot: ', '');
//       const answer = Conversation[i + 1]?.replace('me: ', '');
//       if (question && answer) {
//         tempQaPairs.push({ questionNumber, question, answer });
//         questionNumber++;
//       }
//     }
//     setQaPairs(tempQaPairs);
//   }, [Conversation]);

//   useEffect(() => {
//     setText(transcript);
//   }, [transcript]);

//   const playVoice = (message) => {
//     const utterance = new SpeechSynthesisUtterance(message);
//     window.speechSynthesis.speak(utterance);
//   };

//   const handleCheck = async () => {
//     setConversation((prev) => [...prev, `me: ${text}`]);
//     const formData = new FormData();
//     formData.append("text", isFirstClick ? "hello" : text);
//     formData.append("name", Name);
//     formData.append("desired_job", responseData.value.jobTitle);
//     formData.append("experience", responseData.value.experience);
//     formData.append("job_description", responseData.value.desc);
//     try {
//       const response = await axios.post(`${mockApi}/manual/`, formData, {
//         headers: { "Content-Type": "multipart/form-data" }
//       });
//       setConversation((prev) => [...prev, `robot: ${response.data.chat}`]);
//       setHistory(response.data.history || "");
//       playVoice(response.data.chat);
//       setIsFirstClick(false);
//     } catch (error) {
//       console.error("Axios error:", error.message);
//     }
//   };

//   const handleCheckafter = async () => {
//     setConversation((prev) => [...prev, `me: ${text}`]);
//     const formData = new FormData();
//     formData.append("text", text);
//     formData.append("name", Name);
//     formData.append("desired_job", responseData.value.jobTitle);
//     formData.append("experience", responseData.value.experience);
//     formData.append("job_description", responseData.value.desc);
//     formData.append("history", History);
//     try {
//       const response = await axios.post(`${mockApi}/manual/`, formData, {
//         headers: { "Content-Type": "multipart/form-data" }
//       });
//       setConversation((prev) => [...prev, `robot: ${response.data.chat}`]);
//       setHistory(response.data.history || "");
//       playVoice(response.data.chat);
//       resetTranscript();
//     } catch (error) {
//       console.error("Axios error:", error.message);
//     }
//   };

//   const StopInterview = async (e) => {
//     e.preventDefault();
//     SpeechRecognition.stopListening(); // Stop voice recognition
//     setLoading(true); // Set loading state
//     const formData = new FormData();
//     formData.append("history", History);
//     try {
//       const analysis = await axios.post(`${mockApi}/analysis/`, formData);
//       const criteria = await axios.post(`${mockApi}/criteria/`, formData);
//       const evaluationData = {
//         interview_id: InterviewId,
//         overal_decision: analysis.data.OverallDecision,
//         overall_sentiment: analysis.data.OverallSentiment,
//         area_of_strength: analysis.data.AreasOfStrength,
//         area_to_improve: analysis.data.AreasToImprove,
//         selfintroduction: criteria.data.SelfIntroduction,
//         team_colab: criteria.data.TeamworkAndCollaboration,
//         promblem_solving: criteria.data.ProblemSolvingSkills,
//         adabtibility: criteria.data.Adaptibility,
//         comunication: criteria.data.Communication
//       };
//       const evalResponse = await axios.post(`${ApiUrl}/evaluation`, evaluationData);
//       const evaluation_id = evalResponse?.data.creat_eval?.id;

//       for (let i = 0; i < qaPairs.length; i++) {
//         const questionData = new FormData();
//         questionData.append("ques", (i + 1) + ". " + qaPairs[i].question);
//         questionData.append("ans", qaPairs[i].answer);

//         const Response = await axios.post(`${questionApi}/feedback/`, questionData, {
//           headers: { "Content-Type": "multipart/form-data" }
//         });

//         await axios.post(ApiUrl + "/insert_ques_ans", {
//           quest: qaPairs[i].question,
//           answer: qaPairs[i].answer,
//           evaluation_id: evaluation_id,
//           feedback: Response?.data.feedback,
//           revised_answer: Response?.data.revised_answer,
//           purpose: Response?.data.purpose,
//           star: Response?.data.star
//         });
//       }
//       setLoading(false);
//       setConversation([]);
//     } catch (error) {
//       console.error("Error stopping interview:", error);
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       {Loading && <Loader />}
//       <div className="h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden">
//         <div className="sm:px-10 lg:px-20 px-10 justify-start flex flex-col w-full bg-[#F0F0F0] font-poppins h-[100vh] rounded-3xl pt-4 overflow-y-auto no-scrollbar">
//           <DashboardBanner />
//           <div className={`grid grid-cols-1 gap-6 w-98p h-[900px] lg:h-[550px] md:h-[650px] md:grid-cols ${!TranscriptState && Conversation?.length > 0 ? 'lg:grid-cols-3' : 'lg:grid-cols-1'}`}>
//             <div className="mt-5 p-5 rounded-lg md:col-span-3 lg:col-span-2 xl:col-span-2 w-full">
//               <h1 className="text-head text-2xl font-bold text-start leading-10">Welcome {Name}!</h1>
//               <p className="font-medium text-start leading-10 md:text-xl lg:text-xl text-[10px] text-[#3D3D3D]">
//                 Click "Start Interview" to get Started!
//               </p>
//               <div className="flex flex-col justify-center items-center">
//                 {
//                   transcript === '' ? <img src={robot} alt="robot" className="py-10" /> : (
//                     <div className="p-10 flex">
//                       <img src={robot} alt="robot" className="py-10" />
//                       <img src={voice} width={200} alt="" />
//                     </div>
//                   )
//                 }
//                 {
//                   Conversation?.length < 1 ?
//                     <button onClick={() => {
//                       SpeechRecognition.startListening({ continuous: true });
//                       handleCheck();
//                       setTranscriptState(!TranscriptState);
//                     }} className="bg-gradient-to-r from-red-700 to-blue-700 mb-4 text-white flex justify-center py-2 px-8 gap-5 items-center rounded-xl lg:text-xl text-sm">
//                       <FaRegCirclePlay className="text-2xl" /> Start Interview
//                     </button>
//                     :
//                     <button onClick={(e) => StopInterview(e)} className="bg-gradient-to-r from-red-800 to-blue-800 mb-4 text-white flex justify-center py-2 px-8 gap-5 rounded-xl lg:text-xl text-sm">
//                       <IoMdClose className="text-3xl" /> Stop Interview
//                     </button>
//                 }
//               </div>
//             </div>
//             {!TranscriptState && Conversation.length > 0 &&
//               <div className="scroll mt-5 p-5 rounded-lg overflow-y-auto bg-white md:col-span-1 lg:col-span-1 xl:col-span-1">
//                 <div className="flex justify-between ms-10">
//                   <h1 className="text-2xl text-head">Transcript</h1>
//                   <span onClick={() => setTranscriptState(!TranscriptState)} className="text-4xl text-head">
//                     <IoMdCloseCircleOutline />
//                   </span>
//                 </div>
//                 {
//                   Conversation.map((y, index) => {
//                     const me = y.includes('me:');
//                     const messageContent = me ? y.replace('me:', '') : y.replace('robot:', '');

//                     return (
//                       <React.Fragment key={index}>
//                         {messageContent && (
//                           <div className="flex flex-col z-0">
//                             <div className={`flex items-center ${me ? 'self-end' : ''}`}>
//                               <img src={profile} alt="Profile" />
//                               <p className="text-center text-head ms-2 font-medium text-lg">
//                                 {me ? 'You' : 'Interviewer'}
//                               </p>
//                             </div>
//                             <div>
//                               <p className="bg-gradient-to-r from-[#5653FF] to-[#8163F0] w-auto rounded-lg text-start p-5 text-white relative z-10 rounded-tr-lg rounded-br-lg rounded-bl-lg">
//                                 <div className={`flex items-center ${me ? 'justify-end' : ''}`}>
//                                   {messageContent}
//                                 </div>
//                               </p>
//                             </div>
//                           </div>
//                         )}
//                       </React.Fragment>
//                     );
//                   })
//                 }
//               </div>
//             }
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Dashboard;



import React, { useEffect, useState } from "react";
import robot from "../../assets/images/mic.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { responseDataActions } from "../../store/responseDataSlice";
import { IoMdClose, IoMdCloseCircleOutline } from "react-icons/io";
import profile from "../../assets/images/profile.png";
import 'regenerator-runtime';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { mockApi, ApiUrl, questionApi } from "../../Constant/ApiUrl";
import Loader from "../../Components/Loader";
import DashboardBanner from "../../Components/DashboardBanner";
import { FaRegCirclePlay } from "react-icons/fa6";
import voice from '../../assets/images/voice.gif';
import { useTts } from "react-tts"; // Import the TTS hook
import { toast } from "react-toastify";

const Dashboard = () => {
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [isBotSpeaking, setIsBotSpeaking] = useState(false); // Track if the bot is speaking
  const [isRequestInProgress, setIsRequestInProgress] = useState(false); // Track ongoing requests
  const [isRecordingAllowed, setIsRecordingAllowed] = useState(true); // Controls recording state
  const [text, setText] = useState(transcript);
  const [InterviewId, setInterViewId] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [History, setHistory] = useState("");
  const [TranscriptState, setTranscriptState] = useState(true);
  const [isFirstClick, setIsFirstClick] = useState(true);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Conversation, setConversation] = useState([]);
  const [qaPairs, setQaPairs] = useState([]);
  const responseData = useSelector((state) => state.responseData);


  const navigate = useNavigate();



  // Use the TTS hook for text-to-speech
  const { speak } = useTts();

  useEffect(() => {
    const Token = localStorage.getItem('user');
    if (Token) {
      axios.post(`${ApiUrl}/get_user`, {}, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: Token
        }
      })
        .then((res) => {
          setName(res?.data?.user_data?.username || "");
          setEmail(res?.data?.user_data?.email || "");
        });
    }
  }, []);

  useEffect(() => {
    const tempQaPairs = [];
    let questionNumber = 1;
    for (let i = 1; i < Conversation.length; i += 2) {
      const question = Conversation[i]?.replace('robot: ', '');
      const answer = Conversation[i + 1]?.replace('me: ', '');
      if (question && answer) {
        tempQaPairs.push({ questionNumber, question, answer });
        questionNumber++;
      }
    }
    setQaPairs(tempQaPairs);
  }, [Conversation]);

  // Retry logic with timeout
  const sendWithTimeoutAndRetry = async (formData, retries = 5, timeout = 5000) => {
    while (retries > 0) {
      try {
        const response = await Promise.race([
          axios.post(`${mockApi}/manual/`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          }),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Request timed out")), timeout)
          ),
        ]);
        return response; // Return response if successful
      } catch (error) {
        console.warn(`Backend request failed. Retries left: ${retries - 1}`);
        retries--;
        if (retries === 0) throw error; // Throw error if no retries left
      }
    }
  };
  

  useEffect(() => {
    let pauseTimer;

    if (!isBotSpeaking && !Loading && !isRequestInProgress && transcript.trim()) {  // Added !isRequestInProgress here
        console.log("User speaking:", transcript.trim());
        clearTimeout(pauseTimer); // Reset timer if the user keeps speaking

        pauseTimer = setTimeout(() => {
            console.log("User stopped speaking. Sending to backend...");
            handleSendToBackend(transcript.trim()); // Send speech to backend
            resetTranscript(); // Clear the transcript
        }, 4000); // 1-second pause detection
    }

    return () => clearTimeout(pauseTimer); // Clean up timer
}, [transcript, isBotSpeaking, Loading, isRequestInProgress]);  // Added isRequestInProgress here

  
const playVoice = (message) => {
  setIsBotSpeaking(true); // Indicate the bot is speaking
  const utterance = new SpeechSynthesisUtterance(message);

  // Completely stop speech recognition while bot is speaking
  console.log("Bot is speaking, stopping speech recognition.");
  SpeechRecognition.stopListening(); // Stops recognition

  // Event listener for when the bot finishes speaking
  utterance.onend = () => {
      setIsBotSpeaking(false); // Reset the bot speaking state
      console.log("Bot finished speaking. Restarting speech recognition after 1 second...");
      setTimeout(() => {
          SpeechRecognition.startListening({ continuous: true }); // Restart speech recognition after 1 second
      }, 1000); // Delay of 1 second
  };

  // Speak the message
  window.speechSynthesis.speak(utterance);
};

  
  
  

const handleSendToBackend = async (userSpeech) => {
  if (isRequestInProgress) {
    console.log("A request is already in progress. Ignoring duplicate request.");
    return; // Prevent duplicate requests
  }

  setIsRequestInProgress(true); // Indicate a request is in progress
  setIsRecordingAllowed(false); // Disable recording

  try {
    setLoading(true);
    SpeechRecognition.stopListening(); // Stop listening when sending to backend
    console.log("Sending speech to backend:", userSpeech);

    const formData = new FormData();
    formData.append("text", isFirstClick ? "hello" : userSpeech);
    formData.append("name", Name);
    formData.append("desired_job", responseData.value?.jobTitle);
    formData.append("experience", responseData.value?.experience);
    formData.append("job_description", responseData.value?.desc);
    formData.append("history", History);

    const response = await sendWithTimeoutAndRetry(formData, 5, 5000); // Retry with a 5-second timeout

    const botResponse = response.data.chat;
    setConversation((prev) => [
      ...prev,
      `Me: ${userSpeech}`,
      `Bot: ${botResponse}`,
    ]);
    setHistory(response.data.history || "");
    playVoice(botResponse); // Use TTS for bot's response

    setIsFirstClick(false);
  } catch (error) {
    console.error("Error sending speech to backend:", error.message);
    toast.error("Failed to send your speech after multiple attempts.");
  } finally {
    setLoading(false);
    setIsRequestInProgress(false); // Reset the flag after the request is complete
    setIsRecordingAllowed(true); // Re-enable recording
  }
};
  


  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListeningAndSend = async () => {
    SpeechRecognition.stopListening(); // Stop recognition
    const userSpeech = transcript.trim();

    if (userSpeech) {
      await handleSendToBackend(userSpeech); // Send user speech to the backend
    }

    resetTranscript(); // Clear the transcript
    startListening(); // Restart listening
  };


  const handleCheck = async () => {
    setConversation((prev) => [...prev, `me: ${text}`]);
    const formData = new FormData();
    formData.append("text", isFirstClick ? "hello" : text);
    formData.append("name", Name);
    formData.append("desired_job", responseData.value.jobTitle);
    formData.append("experience", responseData.value.experience);
    formData.append("job_description", responseData.value.desc);
    try {
      const response = await axios.post(`${mockApi}/manual/`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });
      setConversation((prev) => [...prev, `robot: ${response.data.chat}`]);
      setHistory(response.data.history || "");
      playVoice(response.data.chat);
      setIsFirstClick(false);
    } catch (error) {
      console.error("Axios error:", error.message);
    }
  };


  const StopInterview = async (e) => {
    e.preventDefault();
    SpeechRecognition.stopListening(); // Stop voice recognition
    setLoading(true); // Set loading state
    const formData = new FormData();
    formData.append("history", History);
    
    try {
      // Perform analysis first
      const analysis = await axios.post(`${mockApi}/analysis/`, formData);
      
      // Save the analysis data in localStorage in the desired format
      localStorage.setItem('analysisData', JSON.stringify(analysis.data));
  
      // Then perform criteria
      const criteria = await axios.post(`${mockApi}/criteria/`, formData);
      
      // Save the criteria data in localStorage
      localStorage.setItem('criteriaData', JSON.stringify(criteria.data));
  
      // Send all evaluation data to the backend after analysis and criteria
      const evaluationData = {
        interview_id: InterviewId,
        overal_decision: analysis.data.OverallDecision,
        overall_sentiment: analysis.data.OverallSentiment,
        area_of_strength: analysis.data.AreasOfStrength,
        area_to_improve: analysis.data.AreasToImprove,
        selfintroduction: criteria.data.SelfIntroduction,
        team_colab: criteria.data.TeamworkAndCollaboration,
        promblem_solving: criteria.data.ProblemSolvingSkills,
        adabtibility: criteria.data.Adaptability,
        comunication: criteria.data.Communication
      };
  
      const evalResponse = await axios.post(`${ApiUrl}/evaluation`, evaluationData);
      const evaluation_id = evalResponse?.data.creat_eval?.id;
  
      // Save the evaluation data in localStorage
      localStorage.setItem('evaluation_data', JSON.stringify(evaluationData));
  
      const feedbackResponses = [];
  
      // After evaluation data, send feedback for each question-answer pair
      for (let i = 0; i < qaPairs.length; i++) {
        const questionData = new FormData();
        questionData.append("ques", (i + 1) + ". " + qaPairs[i].question);
        questionData.append("ans", qaPairs[i].answer);
  
        const Response = await axios.post(`${questionApi}/feedback/`, questionData, {
          headers: { "Content-Type": "multipart/form-data" }
        });
  
        // Save feedback data to localStorage under feedbackResponses
        feedbackResponses.push({
          question: qaPairs[i].question,
          answer: qaPairs[i].answer,
          feedback: Response?.data.feedback,
          revised_answer: Response?.data.revised_answer,
          purpose: Response?.data.purpose,
          star: Response?.data.star
        });
  
        // Save question, answer, and feedback data to the backend
        await axios.post(ApiUrl + "/insert_ques_ans", {
          quest: qaPairs[i].question,
          answer: qaPairs[i].answer,
          evaluation_id: evaluation_id,
          feedback: Response?.data.feedback,
          revised_answer: Response?.data.revised_answer,
          purpose: Response?.data.purpose,
          star: Response?.data.star
        });
      }
  
      // Save all feedback responses in localStorage
      localStorage.setItem('feedbackResponses', JSON.stringify(feedbackResponses));
  
      setLoading(false);
      setConversation([]); // Clear conversation after interview stop
  
      // Redirect the user to the evaluation history page after successful feedback submission
      navigate("/interviewhistory/evaluation");
  
    } catch (error) {
      console.error("Error stopping interview:", error);
      setLoading(false);
    }
  };
  
  
  
  
  

  return (
    <>
      {Loading && <div className=""><Loader /></div>}
      <div className="h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden">
        <div className="sm:px-10 lg:px-20 px-10 justify-start flex flex-col w-full bg-[#F0F0F0] font-poppins h-[100vh] rounded-3xl pt-4 overflow-y-auto no-scrollbar">
          <DashboardBanner />
          <div className={`grid grid-cols-1 gap-6 w-98p h-[900px] lg:h-[550px] md:h-[650px] md:grid-cols ${!TranscriptState && Conversation?.length > 0 ? 'lg:grid-cols-3' : 'lg:grid-cols-1'}`}>
            <div className="mt-5 p-5 rounded-lg md:col-span-3 lg:col-span-2 xl:col-span-2 w-full">
              <h1 className="text-head text-2xl font-bold text-start leading-10">Welcome {Name}!</h1>
              <p className="font-medium text-start leading-10 md:text-xl lg:text-xl text-[10px] text-[#3D3D3D]">
                Click "Start Interview" to get Started!
              </p>
              <div className="flex flex-col justify-center items-center">
                {
                  transcript === '' ? <img src={robot} alt="robot" className="py-10" /> : (
                    <div className="p-10 flex">
                      <img src={robot} alt="robot" className="py-10" />
                      <img src={voice} width={200} alt="" />
                    </div>
                  )
                }
                {
                  Conversation?.length < 1 ?
                    <button onClick={() => {
                      SpeechRecognition.startListening({ continuous: true });
                      handleCheck();
                      setTranscriptState(!TranscriptState);
                    }} className="bg-gradient-to-r from-red-700 to-blue-700 mb-4 text-white flex justify-center py-2 px-8 gap-5 items-center rounded-xl lg:text-xl text-sm">
                      <FaRegCirclePlay className="text-2xl" /> Start Interview
                    </button>
                    :
                    <button onClick={(e) => StopInterview(e)} className="bg-gradient-to-r from-red-800 to-blue-800 mb-4 text-white flex justify-center py-2 px-8 gap-5 rounded-xl lg:text-xl text-sm">
                      <IoMdClose className="text-3xl" /> Stop Interview
                    </button>
                }
              </div>
            </div>
            {!TranscriptState && Conversation.length > 0 &&
              <div className="scroll mt-5 p-5 rounded-lg overflow-y-auto bg-white md:col-span-1 lg:col-span-1 xl:col-span-1">
                <div className="flex justify-between ms-10">
                  <h1 className="text-2xl text-head">Transcript</h1>
                  <span onClick={() => setTranscriptState(!TranscriptState)} className="text-4xl text-head">
                    <IoMdCloseCircleOutline />
                  </span>
                </div>
                {
                  Conversation.map((y, index) => {
                    const me = y.includes('me:');
                    const messageContent = me ? y.replace('me:', '') : y.replace('robot:', '');
                    return (
                      <React.Fragment key={index}>
                        {messageContent && (
                          <div className="flex flex-col z-0">
                            <div className={`flex items-center ${me ? 'self-end' : ''}`}>
                              <img src={profile} alt="Profile" />
                              <p className="text-center text-head ms-2 font-medium text-lg">
                                {me ? 'You' : 'Interviewer'}
                              </p>
                            </div>
                            <div>
                              <p className="bg-gradient-to-r from-[#5653FF] to-[#8163F0] w-auto rounded-lg text-start p-5 text-white relative sm:z-10 rounded-tr-lg rounded-br-lg rounded-bl-lg">
                                <div className={`flex items-center ${me ? 'justify-end' : ''}`}>
                                  {messageContent}
                                </div>
                              </p>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })
                }
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;