import React, { useState, useEffect } from "react";
import "../App.css"
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { FaRegLightbulb } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
import { CiTimer } from "react-icons/ci";
import axios from "axios";
import { ApiUrl } from "../Constant/ApiUrl";
import readinessImage from "../assets/images/interviewreadiness.png";
// import MockIntSide from "../assets/images/MockIntSide.png"
import mockInterview from "../assets/images/mockinterview.png";
import interviewHistory from "../assets/images/interviewhistory.png";
import blog from "../assets/images/blog.png";
import credentials from "../../src/assets/images/credentials.png";
import mainLogo from "../../src/assets/images/main-logo.png";
import YourPersonal from "../../src/assets/images/YourPersonalAIWorkspace.png";
import Next from "../../src/assets/images/next.png";
import Line from "../../src/assets/images/line.png";
import WithGoogle from "../../src/assets/images/withGoogle.png";
import More from "../../src/assets/images/More.png";
import by_continue from "../../src/assets/images/by_continue.png";
import { GoogleLogin } from "@react-oauth/google";
import { isExpired, decodeToken } from "react-jwt";
const SideBar = () => {
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const [isOpenSignUp, setOpenSignUp] = useState(false);
  const [isOpenSignIn, setOpenSignIn] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState();
  const [Validation, setValidation] = useState("");
  const [decoded, setDecoded] = useState(0);
  const navigate = useNavigate();

  const Token =  localStorage.getItem("user");
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const toggleSettings = () => {
    setSettingsOpen(!isSettingsOpen);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
    setSettingsOpen(false);
  };
  const [Name, setName] = useState("");
  // let decoded =0
  useEffect(() => {
    const Token = localStorage.getItem("user");
    
    const decode= decodeToken(Token)
    setDecoded(decode?.id)

    Token &&
      axios
        .post(
          ApiUrl + "/get_user",
          {
            hehe: "jje",
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: Token,
            },
          }
        )
        .then((x) => setName(x?.data?.user_data?.username));
  }, []);

  console.log({decoded})
  const handleSubmit = (e) => {

    // https://q2drk2ct-8080.inc1.devtunnels.ms

    e.preventDefault();

    axios
      // .post(ApiUrl + "/register", {
        .post("https://q2drk2ct-8080.inc1.devtunnels.ms/register", {
        username,
        email,
        password,
      })
      .then((x) => {
        console.log(x);
        navigate("/dashboard");
        setOpenSignUp(false)
        if (x.data === "User created!") {
          setUsername("");
          setPassword("");
          setEmail("");
          setOpenSignUp(false);
          setOpenSignIn(true);
          console(x.data);
        } else {
          console(x.data);
        }
      })
      .catch((x) => console.log(x));
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();

    axios
      .post(ApiUrl + "/login", {
        // .post("https://q2drk2ct-8000.inc1.devtunnels.ms/login", {

        email,
        password,
      })
      .then((x) => {
        console.log(x.data);
      
        // navigate("/dashboard");
        if (x.data.message === "Logged in successfully") {
      
    // if (x.data.status === "success") {
          // setFormData({
          //   email: "",
          //   password: "",
          // });
          localStorage.setItem("user", x.data.token);
          setName(x.data.token);
          navigate("/dashboard");
          setOpenSignIn(false);
          // window.location.href = '/dashboard'
        }
      })
      .catch((x) => console.log(x));
  };
  const GoToSignUp = () => {
    setOpenSignUp(true);
    setOpenSignIn(false);
  };
  const GoToSignIn = () => {
    setOpenSignUp(false);
    setOpenSignIn(true);
  };
  return (
    <div className="font-poppins">
      {isOpenSignIn && (
        <div className="fixed inset-0 z-50  flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg w-[90%] h-[90%] md:w-[70%] flex justify-center items-center flex-col relative">
            <div
              onClick={() => setOpenSignIn(false)}
              className="absolute top-1 right-2 cursor-pointer border border-black rounded-full px-3 py-1"
            >
              x
            </div>
            <img className="h-20 w-20" src={mainLogo} />
            <img className="h-4 my-6" src={YourPersonal} />
            <form>
              <input
                type="email"
                className="border border-gray-400 px-3 py-2 mb-3 rounded-md w-full "
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                className="border border-gray-400 px-3 py-2 rounded-md w-full "
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <img
                onClick={(e) => handleSubmitLogin(e)}
                src={`${Next}`}
                className="my-6"
              /> */}
               <div onClick={(e) => handleSubmitLogin(e)} className="bg-gradient-to-r from-[#3C38FF] to-[#E6251E] px-4 py-2 mt-5 text-center mx-auto my-0  w-[70%] rounded-[8px] text-white ">
                Next
              </div>
              <p className='my-4'>Don't have an account? <span className='text-[#4267b2] font-medium '><span onClick={GoToSignUp} className="cursor-pointer">SignUp</span> </span></p>

              {/* <button onClick={GoToSignUp}>SignUp</button> */}
            </form>
            <div className="flex items-center my-4">
              {/* <img src={`${Line}`} className="" /> */}
              <div className="px-2 font-semibold">OR</div>
              {/* <img src={`${Line}`} className="" /> */}
              </div>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                if (credentialResponse) {
                  const res = JSON.parse(
                    atob(credentialResponse.credential.split(".")[1])
                  );

                  axios
                    .post(`${ApiUrl}/login_with`, {
                      email: res.email,
                    })
                    .then((x) => {
                      console.log(x);
                      if (x?.data?.token) {
                        setName(x.data.token);
                        localStorage.setItem("user", x.data.token);
                        setOpenSignIn(false);
                        // window.location.href = '/dashboard'
                        navigate("/dashboard");
                      }
                    });
                  console.log(res);
                }
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
            {/* <img src={`${WithGoogle}`} className="" /> */}
            <img src={`${More}`} className="mt-4" />
            {/* <img src={`${by_continue}`} className="my-4" /> */}
            <p className='text-[12px] text-gray-500 mt-4 w-full md:w-[70%]  '>By continuing, you are greeting to <Link className="underline ">Terms of services</Link> & <Link className="underline">Privacy Policy</Link></p>

          </div>
        </div>
      )}
      {isOpenSignUp && (
        <div className="fixed inset-0 z-50 m-30 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg w-[90%] h-[90%] md:w-[70%] flex justify-center items-center flex-col relative">
            <div
              onClick={() => setOpenSignUp(false)}
              className="absolute top-1 right-2 cursor-pointer border border-black rounded-full px-3 py-1"
            >
              x
            </div>
            <img className="h-20 w-20" src={mainLogo} />
            <img className="h-4 my-6" src={YourPersonal} />
            <form>
              <input
                type="name"
                className="border border-gray-400 px-3 py-2 mb-3 rounded-md w-full md:w-[70%]"
                placeholder="username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="email"
                className={`border border-gray-400 px-3 py-2 mb-3 rounded-md w-full md:w-[70%] ${
                  Validation == "User Already exist!"
                    ? " border-red-800"
                    : "border-border_Line"
                }`}
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                className="border border-gray-400 px-3 py-2 rounded-md w-full md:w-[70%]"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <img
                onClick={(e) => handleSubmit(e)}
                src={`${Next}`}
                className="my-6 "
              /> */}
              <div   onClick={(e) => handleSubmit(e)} className="px-4 py-2 mt-5 text-center mx-auto my-0  w-[50%] rounded-[8px] text-white bg-gradient-to-r from-[#3C38FF] to-[#E6251E]">
                Next
              </div>
              <p className='my-4'>Already have an account? <span className='text-[#4267b2] font-medium'><span onClick={GoToSignIn} className="cursor-pointer">SignIn</span> </span></p>
              {/* <button onClick={GoToSignIn}>SignIn</button> */}
            </form>
            <div className="flex justify-center items-center gap-2">
            {/* <div className="flex items-center my-4">
                            <div className="w-full border-black-800" ></div>
                            <span className="px-2 font-semibold">OR</span>
                            <div className="w-full border-gray-800" ></div>
                        </div> */}
                        <div className="flex items-center my-4">
              {/* <img src={`${Line}`} className="" /> */}
              <div className="px-2 font-semibold">OR</div>
              {/* <img src={`${Line}`} className="" /> */}
              </div>
            </div>
            {/* <img src={`${WithGoogle}`} className="" /> */}
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                if (credentialResponse) {
                  const res = JSON.parse(
                    atob(credentialResponse.credential.split(".")[1])
                  );

                  axios
                    .post(`${ApiUrl}/register`, {
                      username: res?.name,
                      email: res?.email,
                    })
                    .then((x) => {
                      console.log(x);
                      if (x?.data == "User created!") {
                        setOpenSignUp(false);
                        setOpenSignIn(true);
                      } else {
                        setValidation(x.data);
                      }
                    });
                  console.log(res);
                }
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
            <img src={`${More}`} className=" mt-4" />
            {/* <img src={`${by_continue}`} className="my-4" /> */}
            <p className='text-[12px] text-gray-500 mt-4 w-full md:w-[70%]  '>By continuing, you are greeting to <Link className="underline ">Terms of services</Link> & <Link className="underline">Privacy Policy</Link></p>

          </div>
        </div>
      )}
      <button
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation"
        type="button"
        className="inline-flex absolute items-center p-2 mt-2 right-1 text-sm text-gray-500 rounded-lg sm:hidden hover:text-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-gray-200 text-start"
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>
      {isSidebarOpen && (
        <div
          className="fixed top-0 left-0 z-30 w-screen h-screen bg-black opacity-50"
          onClick={closeSidebar}
        ></div>
      )}

      <aside
        className={`fixed p-2 top-0 left-0 z-40 w-64 h-screen transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full bg-[#1A1A1A] overflow-y-auto rounded-3xl">
          <NavLink
            to="/dashboard"
            className="flex items-center justify-start ml-6 mt-5"
          >
            <img src={logo} width={130} alt="Flowbite Logo" />
          </NavLink>

          <hr className="h-px my-3 ms-5 me-5 bg-[#868686] border-0" />
          <ul className="space-y-2 font-medium my-10">
            <li className="ps-5 pe-5 ">
              <NavLink
                to="/dashboard"
                className={`flex items-center p-2 border-none rounded-[5px]  text-black group `}
              >
                {/* <FaRegLightbulb className="text-[#3D3D3D] text-xl " /> */}
                <img src={readinessImage} />
                <span className="ms-3 text-[#3D3D3D]">Interview Readiness</span>
              </NavLink>
            </li>
            <li className="ps-5 pe-5 ">
              <NavLink
                to="/mockInterview"
                className={`flex items-center p-2 border-none rounded-[5px]  text-black group `}
              >
                {/* <FaRegLightbulb className="text-[#3D3D3D] text-xl " /> */}
                <img src={mockInterview} />
                <span className="ms-3 text-[#3D3D3D]">Mock Interview</span>
              </NavLink>
            </li>
            <li className="ps-5 pe-5 ">
              <NavLink
                to="/interviewHistory"
                className={`flex items-center p-2 border-none rounded-[5px]  text-black group `}
              >
                {/* <FaRegLightbulb className="text-[#3D3D3D] text-xl " /> */}
                <img src={interviewHistory} />
                <span className="ms-3 text-[#3D3D3D]">Interview History</span>
              </NavLink>
            </li>
            <li className="ps-5 pe-5 ">
              <NavLink
                to="/Blog"
                className={`flex items-center p-2 border-none rounded-[5px]  text-black group `}
              >
                {/* <FaRegLightbulb className="text-[#3D3D3D] text-xl " /> */}
                <img src={blog} />
                <span className="ms-3 text-[#3D3D3D]">Blog</span>
              </NavLink>
            </li>
            {decoded === 1 ? (
              <>
                <li className="ps-5 pe-5 ">
                  <NavLink
                to="/blogpost"
                className={`flex items-center p-2 border-none rounded-[5px]  text-black group `}
              >
                <img src={blog} />
                <span className="ms-3 text-[#3D3D3D]">Blog Post</span>
              </NavLink>
                </li>
              </>
            ) : null}
          </ul>
          {Token ? (
            <button
              onClick={() => (
                localStorage.removeItem("user"), (window.location.href = "/")
              )}
              className="absolute bottom-9 flex items-center bg-gradient-to-r from-red-600 to-blue-600  ps-14 pe-14 pt-2 pb-2  text-white rounded-full text-sm left-8  "
            >
              Log out{" "}
              <span className="ml-5 ">
                <IoIosLogOut />
              </span>
            </button>
          ) : (


<div className="absolute w-[80%] ml-4  bottom-8 text-center rounded-[8px] p-2  bg-gradient-to-r from-[#3C38FF] to-[#E6251E] px-4">
{/* <img src={credentials} /> */}
<div className="text-[12px] text-white font-medium my-5 ">
  Your Personal AI Workspace
  </div>
<div className="bottom-5  text-white  cursor-pointer"  onClick={() => (setOpenSignUp(true), setOpenSignIn(false))}> 
<div className="flex gap-2  flex items-center justify-center px-4 py-1 bg-white bg-opacity-15 rounded-[8px]" >
                 <button
                    onClick={() => (setOpenSignUp(true), setOpenSignIn(false))}
                    className=""
                  >
                    Sign up
                  </button>
                  {" "}
                  /
                  <button
                    onClick={() => (setOpenSignIn(true), setOpenSignUp(false))}
                    className=""
                  >
                    Login
                  </button>
                </div>
                </div>
</div>

            // <div className="absolute bottom-8 p-2  cursor-pointer">
            //   <div className="absolute bottom-5 left-16 text-white  cursor-pointer" >
            //     <div className="flex gap-2 " >
            //       <button
            //         onClick={() => (setOpenSignUp(true), setOpenSignIn(false))}
            //         className=""
            //       >
            //         Sign up
            //       </button>
            //       {" "}
            //       /
            //       <button
            //         onClick={() => (setOpenSignIn(true), setOpenSignUp(false))}
            //         className=""
            //       >
            //         Login
            //       </button>
            //     </div>
            //   </div>
              // <img src={credentials} />
            // </div>
          )}
        </div>
      </aside>
    </div>
  );
};

export default SideBar;
