import React, { useState } from "react";
import WeWork from "../MainPage/WeWork";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { questionApi } from "../../Constant/ApiUrl";
import { useDispatch } from "react-redux";
import { responseDataActions } from "../../store/responseDataSlice";
import Loader from "../../Components/Loader";
import logo from "../../assets/images/main-logo.png"
import Card1 from "./Card1";
import { PolarArea } from "react-chartjs-2";
import BlogFooter from "./BlogFooter";
import DashboardBanner from "../../Components/DashboardBanner";
import DynamicBlog from "./DynamicBlog";

const Blog = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Answer, setAnswer] = useState("");
  const [questions, setquestions] = useState([]);
  const [modal, setmodal] = useState(false);
  console.log(questions);

  return (
    <div className="p-5 h-[95vh] mt-2 mr-3 font-poppins rounded-2xl w-[99%] bg-[#F0F0F0] overflow-hidden">
      <div className="overflow-y-auto no-scrollbar" style={{ maxHeight: 'calc(110vh - 160px)' }}>
      {loading && <Loader />}
      {modal && (
        <div className="fixed z-10 top-0 left-0 right-0 bottom-0 bg-blue-400 bg-opacity-25 backdrop-filter backdrop-blur-xs">
          <div className="h-[90%]  w-[80%] top-[5%] left-[10%]  fixed flex flex-col justify-center items-center">
            {questions.length > 0 &&
              questions?.map((question, index) => {
                return (
                  <div
                    key={index}
                    className="mt-5 p-5 rounded-lg w-98p mb-4 bg-white overflow-auto"
                  >
                    <div className="text-start">
                      <div >
                        <div className="flex justify-between items-center">
                          <h1
                            onClick={() => console.log(question)}
                            className="text-start pt-4 pb-4  text-head font-bold text-lg"
                          >
                            Feedback
                          </h1>
                          <p onClick={() => setmodal(false)}>X</p>
                        </div>

                        <div className="flex gap-3 flex-col lg:flex-row ">
                          {question.feedback
                            .split("\n\n")
                            .map((section, sectionIndex) => (
                              <div
                                key={sectionIndex}
                                className="grid grid-flow-col  gap-4"
                              >
                                <div className="rounded-lg border border-gray-300 shadow-sm p-4">
                                  {section
                                    .split("\n")
                                    .map((line, lineIndex) => {
                                      const parts = line.split(":");

                                      if (parts.length > 1) {
                                        const heading = parts.shift();
                                        const paragraph = parts.join(":");
                                        return (
                                          <div key={lineIndex}>
                                            <h2 className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 text-[12px] rounded-xl bg-[#F2F4FF] font-medium">
                                              {heading}
                                            </h2>
                                            <p className="mt-2 text-[#3D3D3D]">
                                              {paragraph}
                                            </p>
                                          </div>
                                        );
                                      } else {
                                        return <p key={lineIndex}>{line}</p>;
                                      }
                                    })}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div>
                        <h1 className="text-start pt-4 pb-4  text-head font-bold text-lg">
                          Revised Answer
                        </h1>

                        {question.revised_answer
                          .split("\n")
                          .map((section, sectionIndex) => {
                            return <div>{section}</div>;
                          })}
                      </div>
                      <div>
                        <h1 className="text-start pt-4 pb-4  text-head font-bold text-lg">
                          Purpose
                        </h1>
                        <div className="flex flex-wrap">
                          {question.purpose
                            .split("\n\n")
                            .map((section, sectionIndex) => (
                              <div
                                key={sectionIndex}
                                className="flex flex-wrap w-64 mb-5 gap-4"
                              >
                                <div className="rounded-xl shadow-xl border border-gray-300 p-4 mx-1">
                                  {section
                                    .split("\n")
                                    .map((line, lineIndex) => {
                                      const parts = line.split(":");
                                      if (parts.length > 1) {
                                        let heading = parts.shift();
                                        const paragraph = parts.join(":");
                                        // Splitting the heading further to separate the number and the text
                                        const headingParts =
                                          heading.split(/(?<=^\d+)\. /); // Lookbehind to split after the number followed by a dot and space
                                        return (
                                          <div key={lineIndex}>
                                            <h2 className="flex flex-col">
                                              {headingParts.length > 1 ? (
                                                <>
                                                  <span className=" text-black font-bold text-4xl rounded-full p-1">
                                                    {headingParts[0] + "."}
                                                  </span>
                                                  <span className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 text-[12px] rounded-xl bg-[#F2F4FF] font-medium">
                                                    {headingParts[1]}
                                                  </span>
                                                </>
                                              ) : (
                                                heading // Fallback if there's no number
                                              )}
                                            </h2>
                                            <p className="pt-3">{paragraph}</p>
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <p key={lineIndex} className="">
                                            {line}
                                          </p>
                                        );
                                      }
                                    })}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div
                        onClick={() =>
                          console.log({ question, index, Response })
                        }
                      >
                        <h1 className="text-start pt-4 pb-4  text-head font-bold text-lg">
                          Criteria
                        </h1>
                        <div className="flex w-full flex-col lg:flex-row md:flex-row justify-between">
                          <div className="flex justify-center items-center lg:w-[50%]">
                            <PolarArea
                              data={{
                                labels: [
                                  question?.criteria[0]?.heading,
                                  question?.criteria[1]?.heading,
                                  question?.criteria[2]?.heading,
                                  question?.criteria[3]?.heading,
                                  question?.criteria[4]?.heading,
                                ],
                                datasets: [
                                  {
                                    label: "My First Dataset",
                                    data: [
                                      question?.criteria[0]?.score,
                                      question?.criteria[1]?.score,
                                      question?.criteria[2]?.score,
                                      question?.criteria[3]?.score, // Make sure this exists or handle it appropriately
                                      question?.criteria[4]?.score, // Make sure this exists or handle it appropriately
                                    ],
                                    backgroundColor: [
                                      "rgba(255, 99, 132, 0.2)",
                                      "rgba(75, 192, 192, 0.2)",
                                      "rgba(255, 205, 86, 0.2)",
                                      "rgba(201, 203, 207, 0.2)",
                                      "rgba(54, 162, 235, 0.2)",
                                    ],
                                    borderColor: [
                                      "rgb(255, 99, 132)",
                                      "rgb(75, 192, 192)",
                                      "rgb(255, 205, 86)",
                                      "rgb(201, 203, 207)",
                                      "rgb(54, 162, 235)",
                                    ],
                                    borderWidth: 1,
                                  },
                                ],
                              }}
                              options={{
                                // Simplify or adjust options as needed

                                maintainAspectRatio: false,
                              }}
                            />
                          </div>
                          <div className=" flex flex-col justify-center items-start lg:w-[50%]">
                            {question?.criteria?.length > 0 &&
                              question?.criteria.map((x) => (
                                <div className="flex py-2 gap-4 items-center">
                                  <div className="relative ">
                                    <svg
                                      className="text-center"
                                      width="36"
                                      height="36"
                                      viewBox="0 0 36 36"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="18"
                                        cy="18"
                                        r="16"
                                        fill="none"
                                        className="stroke-current text-red-600  "
                                        stroke-width="2"
                                      ></circle>

                                      <g className="origin-center -rotate-90 transform">
                                        <circle
                                          cx="18"
                                          cy="18"
                                          r="16"
                                          fill="none"
                                          className="stroke-current text-gray-200 dark:text-gray-700"
                                          stroke-width="2"
                                          stroke-dasharray="100"
                                          stroke-dashoffset={x.score}
                                        ></circle>
                                      </g>
                                    </svg>
                                    <div className="absolute w-full text-center top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                      <span className="text-center text-xs font-semibold text-gray-800 dark:text-white">
                                        {x.score}%
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-xs font-extrabold">
                                      {x.heading}
                                    </div>
                                    <div className="text-xs font-semibold">
                                      {x.description}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>

                        <div>
                          <h1 className="text-start pt-4 pb-4 text-head font-bold text-lg">
                            STAR
                          </h1>
                          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                            {question.star
                              .split("\n\n")
                              .map((section, sectionIndex) => {
                                const validSections = [
                                  "Situation",
                                  "Task",
                                  "Action",
                                  "Result",
                                ];

                                // Check if the current section is one of the specified valid sections
                                if (
                                  validSections.some((validSection) =>
                                    section.includes(validSection)
                                  )
                                ) {
                                  return (
                                    <div
                                      key={sectionIndex}
                                      className="rounded-lg border border-gray-300 shadow-sm p-4"
                                    >
                                      {section
                                        .split("\n")
                                        .map((line, lineIndex) => {
                                          const parts = line.split(":");
                                          if (parts.length > 1) {
                                            let heading = parts.shift();
                                            const paragraph = parts.join(":");
                                            // Splitting the heading further to separate the number and the text
                                            const headingParts =
                                              heading.split(/(?<=^\d+)\. /); // Lookbehind to split after the number followed by a dot and space
                                            return (
                                              <div key={lineIndex}>
                                                <h2 className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 text-[16px] rounded-full bg-[#F2F4FF] font-medium">
                                                  {headingParts.length > 1 ? (
                                                    <>
                                                      <span className="text-blue-400 bg-white rounded-full p-1">
                                                        {headingParts[0]}
                                                      </span>
                                                      <span className="rounded-full p-1 ">
                                                        {headingParts[1]}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    heading // Fallback if there's no number
                                                  )}
                                                </h2>
                                                <p className="mt-2 text-gray-600 text-[13px]">
                                                  {paragraph}
                                                </p>
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <p key={lineIndex}>{line}</p>
                                            );
                                          }
                                        })}
                                    </div>
                                  );
                                } else {
                                  return null; // Skip rendering if the section is not in the validSections array
                                }
                              })}
                          </div>
                          <div className="my-5">
                            {question.star
                              .split("\n\n")
                              .map((section, sectionIndex) => {
                                const validSections = [
                                  "Overall feedback on STAR method",
                                ];

                                // Check if the current section is one of the specified valid sections
                                if (
                                  validSections.some((validSection) =>
                                    section.includes(validSection)
                                  )
                                ) {
                                  return (
                                    <div
                                      key={sectionIndex}
                                      className="rounded-lg border border-gray-300 shadow-sm p-4"
                                    >
                                      {section
                                        .split("\n")
                                        .map((line, lineIndex) => {
                                          const parts = line.split(":");
                                          if (parts.length > 1) {
                                            let heading = parts.shift();
                                            const paragraph = parts.join(":");
                                            // Splitting the heading further to separate the number and the text
                                            const headingParts =
                                              heading.split(/(?<=^\d+)\. /); // Lookbehind to split after the number followed by a dot and space
                                            return (
                                              <div key={lineIndex}>
                                                <h2 className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 text-[16px] rounded-full bg-[#F2F4FF] font-medium">
                                                  {headingParts.length > 1 ? (
                                                    <>
                                                      <span className="text-blue-400 bg-white rounded-full p-1">
                                                        {headingParts[0]}
                                                      </span>
                                                      <span className="text-base rounded-full p-1">
                                                        {headingParts[1]}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    heading // Fallback if there's no number
                                                  )}
                                                </h2>
                                                <p className="mt-2 text-gray-600 text-[13px]">
                                                  {paragraph}
                                                </p>
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <p key={lineIndex}>{line}</p>
                                            );
                                          }
                                        })}
                                    </div>
                                  );
                                } else {
                                  return null; // Skip rendering if the section is not in the validSections array
                                }
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <div className="bg-[#F0F0F0]   rounded-3xl h-screen">
        <div className="sm:px-10 lg:px-12 px-10 justify-start flex">
          <DashboardBanner />
          {/* <div className="flex"><p className="text-center pt-5 font-bold lg:text-[32px] sm:text-[20px] text-[15px]">Mockli</p><p className="px-3 lg:text-4xl  text-2xl font-thin md:pt-5 pt-3 ">|</p><p className="text-center pt-5 font-bold lg:text-[32px] sm:text-[20px] text-[15px]">Your Personal AI Interviewer</p></div> */}
        </div>
        <div className="flex flex-col gap-5 text-start sm:px-10 lg:px-12 px-10 pt-4">
          <div style={{ background: 'rgba(2,92,251,0.17)' }} className=" lg:w-3/12 md:w-3/6 w-full rounded-md"><p className="text-[#025CFB] text-sm text-center p-3">First Impressions Count</p></div>
          <h2 className="lg:text-3xl md:text-2xl text-xl sm:text-lg ">Let's Start with</h2>
          <h1 className="lg:text-2xl md:text-xl text-xl sm:text-lg text-[#025CFB] font-bold">
            "Please Introduce Yourself"
          </h1>
          <p className="lg:text-[16px] md:text-ms text-sm sm:text-sm">
            This is your chance to make a memorable first impression. Share your
            journey, your achievements, and what makes you unique.
          </p>

          {/* <textarea
              onChange={(e) => setAnswer(e.target.value)}
              className="p-3 h-40 border-2 text-start border-gray-500 outline-none rounded-lg w-full sm:w-3/4 md:w-full"
              placeholder="Enter your answer"
            /> */}
          <div className="relative w-full sm:w-3/4 md:w-full ">
      <textarea
        onChange={(e) => setAnswer(e.target.value)}
        className="p-3 h-40 border-2 text-start border-gray-500 outline-none rounded-lg w-full resize-none"
        placeholder="Enter your answer"
      ></textarea>
      <button
       onClick={async () => {
        setLoading(true);

        const formData = new FormData();
        formData.append(
          "ques",
          1 +
          ". " +
          "This is your chance to make a memorable first impression.Share your journey your achievements, and what makes you unique."
        ); // Append question number to the question
        formData.append("ans", Answer);

        try {
          await axios
            .post(`${questionApi}/feedback/`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(
              (x) => (
                setLoading(false),
                console.log(x, "<==========check me====="),
                setquestions([x.data]),
                setmodal(true)
              )
            );

          //run this after finishing everything
        } catch (error) {
          console.error("Error posting data:", error);
          // Optionally, handle the error, e.g., retry the request, log the error, etc.
        }
      }}
        // Example action
        className="absolute bottom-3 right-1  text-white bg-gradient-to-r from-[#5653FF] to-[#8163F0] rounded-full md:px-8 px-6 md:py-2 py-1 text-center md:text-base text-sm"
        type="button"
      >
        Review
      </button>
    </div>

          {/* <button
            onClick={async () => {
              setLoading(true);

              const formData = new FormData();
              formData.append(
                "ques",
                1 +
                ". " +
                "This is your chance to make a memorable first impression.Share your journey your achievements, and what makes you unique."
              ); // Append question number to the question
              formData.append("ans", Answer);

              try {
                await axios
                  .post(`${questionApi}/feedback/`, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then(
                    (x) => (
                      setLoading(false),
                      console.log(x, "<==========check me====="),
                      setquestions([x.data]),
                      setmodal(true)
                    )
                  );

                //run this after finishing everything
              } catch (error) {
                console.error("Error posting data:", error);
                // Optionally, handle the error, e.g., retry the request, log the error, etc.
              }
            }}
            className="text-white bg-gradient-to-r from-[#5653FF] to-[#8163F0] rounded-full px-8 py-2 text-center self-start text-sm"
          >
            Review
          </button> */}
        </div>
      </div>
      <div className="sm:px-10 lg:px-12 px-10 justify-start flex w-full mt-[-80px] ">
        <Card1 />
      </div>
      <div className="sm:px-10 lg:px-12 px-10 justify-start">
        <DynamicBlog/>
      </div>
      <div className="pb-5 bg-white pt-[-50px]">
        <BlogFooter />
      </div>
      </div>
    </div>
  )
}

export default Blog