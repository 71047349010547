import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios"; // Ensure axios is imported
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import Chart from 'chart.js/auto';
import { PolarArea } from "react-chartjs-2";
import { questionApi } from "../../Constant/ApiUrl";
import Loader_question from "../../Components/Loader_question";
import DashboardBanner from "../../Components/DashboardBanner";
const Interview = () => {

  const responseData = useSelector((state) => state.responseData.value);

  const [answers, setAnswers] = useState({});
  const [Response, setResponse] = useState([]);
  const [Loading, setLoading] = useState(false)
  const [step , setStep] = useState(false)

  // Initialize answers based on the questions available
  const questions =
    responseData && responseData.questions ? responseData.questions : [];

  const handleAnswerChange = (index, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: value,
    }));
  };

  const handleCheck = async (index) => {
    setLoading(true)
    const question = questions[index];
    const answer = answers[index];

    const formData = new FormData();
    formData.append("ques", question);
    formData.append("ans", answer);
    console.log(formData);

    try {
      const response = await axios.post(
        `${questionApi}/feedback/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      response.data && setLoading(false)
      setResponse(previousResponse => [...previousResponse, response.data]);
      if(response.data){
        setStep(true)
      }else{
        setStep(false)
      }

      console.log(response.data);
    } catch (error) {
      console.error("Axios error:", error.message);
    }
  };
  console.log(Response, "<======== check this =====")

  return (
    <>
      {Loading && <Loader_question />}
      <div className="h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden ">
      <div className="sm:px-10 lg:px-20 px-10 justify-start flex flex-col w-full bg-[#F0F0F0] font-poppins h-[100vh] rounded-3xl pt-4 overflow-y-auto no-scrollbar " style={{ maxHeight: 'calc(110vh - 160px)' }}>
      <DashboardBanner />
      <div className="mt-5 p-5 rounded-lg w-98p mb-4 bg-white">
        
        <h1 className="text-start pt-4 pb-4 text-head font-bold text-lg">
          Interview Information
        </h1>
        <div className="flex flex-col">
          <div className="flex">
            <p className="font-medium leading-8">Position:</p>
            <p>{/* Render position here */}</p>
          </div>
          <div className="flex">
            <p className="font-medium leading-8">Company:</p>
            <p>{/* Render company here */}</p>
          </div>
          <div className="flex">
            <p className="font-medium leading-8">Key Requirements:</p>
            <p>{/* Render key requirements here */}</p>
          </div>
        </div>
      </div>
      <p className="text-center text-[#000000] font-semibold text-xl">
          Per Question Evaluation & Feedback
        </p>

      {questions.map((question, index) => {
        const feedbackEntry = Response.find(entry => entry.question_numbers.includes(String(index + 1)));
        return (
          <div  key={index} className="mt-5 p-5 rounded-lg w-98p mb-4 bg-white">
            <div className="text-start">
              <p className="font-medium pb-3">{question}</p>
              <textarea
                value={answers[index] || ""}
                onChange={(e) => handleAnswerChange(index, e.target.value)}
                className="h-32 p-4 border-2 mb-4 border-gray-500 outline-none rounded-lg w-full"
                placeholder="Enter your answer"
              />
              {/* yahan start */}
              <div >
                {feedbackEntry &&<h1 onClick={()=>console.log(feedbackEntry)} className="text-start pt-4 pb-4  text-head font-bold text-lg">
                  Feedback
                </h1>}
                <div className="flex gap-3 flex-col lg:flex-row flex-wrap ">
                  {
                    feedbackEntry && feedbackEntry.feedback.split('\n\n').map((section, sectionIndex) => (

                      <div key={sectionIndex} className="grid grid-flow-col  gap-4">
                        <div className="rounded-lg border border-gray-300 shadow-sm p-4">
                          {section.split('\n').map((line, lineIndex) => {
                            const parts = line.split(':');

                            if (parts.length > 1) {
                              const heading = parts.shift();
                              const paragraph = parts.join(':');
                              return (
                                <div key={lineIndex}>
                                  <h2 className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 text-[12px] rounded-xl bg-[#F2F4FF] font-medium">{heading}</h2>
                                  <p className="mt-2 text-[#3D3D3D]">{paragraph}</p>
                                </div>
                              );
                            } else {

                              return <p key={lineIndex}>{line}</p>;
                            }
                          })}

                        </div>
                      </div>
                    ))
                  }
                </div>


              </div>
              <div>
                {feedbackEntry &&<h1 className="text-start pt-4 pb-4  text-head font-bold text-lg">
                  Revised Answer
                </h1>}
                {
                  feedbackEntry && feedbackEntry.revised_answer.split('\n').map(
                    (section, sectionIndex) => {
                      return (
                        <div>
                          {section}
                        </div>
                      )
                    }
                  )
                }
              </div>
              <div >
                {feedbackEntry &&<h1 className="text-start pt-4 pb-4  text-head font-bold text-lg">
                  Purpose
                </h1>}
                <div className="flex flex-wrap">
                  {
                    feedbackEntry && feedbackEntry.purpose.split('\n\n').map((section, sectionIndex) => (
                      <div key={sectionIndex} className="flex flex-wrap w-64 mb-5 gap-4">
                        <div className="rounded-xl shadow-xl border border-gray-300 p-4 mx-1">
                          {section.split('\n').map((line, lineIndex) => {
                            const parts = line.split(':');
                            if (parts.length > 1) {
                              let heading = parts.shift();
                              const paragraph = parts.join(':');
                              // Splitting the heading further to separate the number and the text
                              const headingParts = heading.split(/(?<=^\d+)\. /); // Lookbehind to split after the number followed by a dot and space
                              return (
                                <div key={lineIndex} >
                                  {/* Applying different styles to the number and the rest of the heading */}
                                  <h2 className="flex flex-col">
                                    {headingParts.length > 1 ? (
                                      <>
                                        <span className=" text-black font-bold text-4xl rounded-full p-1">{headingParts[0] + '.'}</span>
                                        <span className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 text-[12px] rounded-xl bg-[#F2F4FF] font-medium">{headingParts[1]}</span>
                                      </>
                                    ) : (
                                      heading // Fallback if there's no number
                                    )}
                                  </h2>
                                  <p className="pt-3">{paragraph}</p>
                                </div>
                              );
                            } else {
                              return <p key={lineIndex} className="">{line}</p>;
                            }
                          })}
                        </div>
                      </div>
                    ))
                  }
                </div>


              </div>
              <div onClick={() => console.log({ question, index, Response })}>
                {feedbackEntry &&<h1 className="text-start pt-4 pb-4  text-head font-bold text-lg">
                  Criteria
                </h1>}
                <div className="flex w-full flex-col lg:flex-row md:flex-row justify-between">
                  <div className="flex justify-center items-center lg:w-[50%]">
                    {feedbackEntry && (
                      <PolarArea
                        data={{
                          labels: [
                            feedbackEntry?.criteria[0]?.heading,
                            feedbackEntry?.criteria[1]?.heading,
                            feedbackEntry?.criteria[2]?.heading,
                            feedbackEntry?.criteria[3]?.heading,
                            feedbackEntry?.criteria[4]?.heading,
                          ],
                          datasets: [{
                            label: 'My First Dataset',
                            data: [
                              feedbackEntry?.criteria[0]?.score,
                              feedbackEntry?.criteria[1]?.score,
                              feedbackEntry?.criteria[2]?.score,
                              feedbackEntry?.criteria[3]?.score, // Make sure this exists or handle it appropriately
                              feedbackEntry?.criteria[4]?.score, // Make sure this exists or handle it appropriately
                            ],
                            backgroundColor: [
                              'rgba(255, 99, 132, 0.2)',
                              'rgba(75, 192, 192, 0.2)',
                              'rgba(255, 205, 86, 0.2)',
                              'rgba(201, 203, 207, 0.2)',
                              'rgba(54, 162, 235, 0.2)'
                            ],
                            borderColor: [
                              'rgb(255, 99, 132)',
                              'rgb(75, 192, 192)',
                              'rgb(255, 205, 86)',
                              'rgb(201, 203, 207)',
                              'rgb(54, 162, 235)'
                            ],
                            borderWidth: 1
                          }]
                        }}
                        options={{
                          // Simplify or adjust options as needed

                          maintainAspectRatio: false
                        }}
                      />
                    )}

                  </div>
                  <div className=" flex flex-col justify-center items-start lg:w-[50%]">

                    {
                      feedbackEntry && feedbackEntry.criteria?.length > 0 && feedbackEntry.criteria.map(x => (
                        <div className="flex py-2 gap-4 items-center">
                          <div className="relative ">
                            <svg className="text-center" width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                              <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-red-600  " stroke-width="2"></circle>

                              <g className="origin-center -rotate-90 transform">
                                <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-gray-700" stroke-width="2" stroke-dasharray="100" stroke-dashoffset={x.score}></circle>
                              </g>

                            </svg>
                            <div className="absolute w-full text-center top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                              <span className="text-center text-xs font-semibold text-gray-800 dark:text-white">{x.score}%</span>
                            </div>
                          </div>
                          <div>
                            <div className="text-xs font-extrabold">{x.heading}</div>
                            <div className="text-xs font-semibold">{x.description}</div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>

                <div>
                  {feedbackEntry &&<h1 className="text-start pt-4 pb-4 text-head font-bold text-lg">
                    STAR
                  </h1>}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {feedbackEntry &&
                      feedbackEntry.star.split('\n\n').map((section, sectionIndex) => {
                        const validSections = [
                          'Situation',
                          'Task',
                          'Action',
                          'Result',
                        ];

                        // Check if the current section is one of the specified valid sections
                        if (validSections.some(validSection => section.includes(validSection))) {
                          return (
                            <div key={sectionIndex} className="rounded-lg border border-gray-300 shadow-sm p-4">
                              {section.split('\n').map((line, lineIndex) => {
                                const parts = line.split(':');
                                if (parts.length > 1) {
                                  let heading = parts.shift();
                                  const paragraph = parts.join(':');
                                  // Splitting the heading further to separate the number and the text
                                  const headingParts = heading.split(/(?<=^\d+)\. /); // Lookbehind to split after the number followed by a dot and space
                                  return (
                                    <div key={lineIndex}>
                                      {/* Applying different styles to the number and the rest of the heading */}
                                      <h2 className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 text-[16px] rounded-full bg-[#F2F4FF] font-medium">
                                        {headingParts.length > 1 ? (
                                          <>
                                            <span className="text-blue-400 bg-white rounded-full p-1">{headingParts[0]}</span>
                                            <span className="rounded-full p-1 ">{headingParts[1]}</span>
                                          </>
                                        ) : (
                                          heading // Fallback if there's no number
                                        )}
                                      </h2>
                                      <p className="mt-2 text-gray-600 text-[13px]">{paragraph}</p>
                                    </div>
                                  );
                                } else {
                                  return <p key={lineIndex}>{line}</p>;
                                }
                              })}
                            </div>
                          );
                        } else {
                          return null; // Skip rendering if the section is not in the validSections array
                        }
                      })}
                  </div>
                  <div className="my-5">
                    {feedbackEntry &&
                      feedbackEntry.star.split('\n\n').map((section, sectionIndex) => {
                        const validSections = [
                          'Overall feedback on STAR method'
                        ];

                        // Check if the current section is one of the specified valid sections
                        if (validSections.some(validSection => section.includes(validSection))) {
                          return (
                            <div key={sectionIndex} className="rounded-lg border border-gray-300 shadow-sm p-4">
                              {section.split('\n').map((line, lineIndex) => {
                                const parts = line.split(':');
                                if (parts.length > 1) {
                                  let heading = parts.shift();
                                  const paragraph = parts.join(':');
                                  // Splitting the heading further to separate the number and the text
                                  const headingParts = heading.split(/(?<=^\d+)\. /); // Lookbehind to split after the number followed by a dot and space
                                  return (
                                    <div key={lineIndex}>
                                      {/* Applying different styles to the number and the rest of the heading */}
                                      <h2  className="text-[#1D275F] ps-4 pe-4 pt-2 pb-2 text-[16px] rounded-full bg-[#F2F4FF] font-medium">
                                        {headingParts.length > 1 ? (
                                          <>
                                            <span className="text-blue-400 bg-white rounded-full p-1">{headingParts[0]}</span>
                                            <span className="text-base rounded-full p-1">{headingParts[1]}</span>
                                          </>
                                        ) : (
                                          heading // Fallback if there's no number
                                        )}
                                      </h2>
                                      <p className="mt-2 text-gray-600 text-[13px]">{paragraph}</p>
                                    </div>
                                  );
                                } else {
                                  return <p key={lineIndex}>{line}</p>;
                                }
                              })}
                            </div>
                          );
                        } else {
                          return null; // Skip rendering if the section is not in the validSections array
                        }
                      })}
                  </div>
                </div>
              </div>

              {/* yahan end */}
              <div className="text-end">
                <button
                  onClick={() => handleCheck(index)}
                  className="bg-buttonbg rounded-lg font-medium text-buttonColor p-3"
                >
                  Analyze
                </button>
              </div>

            </div>
          </div>
        )
      })}
      </div>
      </div>
    </>
  );
};

export default Interview;
