import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/images/img.png";
import img2 from "../../assets/images/img2.png";
import img3 from "../../assets/images/img3.png";
import img4 from "../../assets/images/img4.png";
import img5 from "../../assets/images/img5.png";
import img6 from "../../assets/images/img6.png";
import img7 from "../../assets/images/img7.png";
import img8 from "../../assets/images/img8.png";
import DashboardBanner from "../../Components/DashboardBanner";

const MockInterview = () => {
  return (
    <>
      <div className="p-5 h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden">
      <div className="overflow-y-auto no-scrollbar" style={{ maxHeight: 'calc(110vh - 160px)' }}>
        <DashboardBanner />
        <div className="flex items-center justify-center py-8 pb-12 ">
          {/* First Dot */}
          <div className="h-4 w-4 rounded-full bg-Step flex items-center justify-center">
            <span className="text-black pt-12">info</span>
          </div>
          {/* Dashed Line */}
          <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
          {/* Second Dot */}
          <div className="h-4 w-4 rounded-full bg-white border-gray-400 border-2 flex items-center justify-center">
            <span className="text-black pt-12">Interview</span>
          </div>
          {/* Dashed Line */}
          <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
          {/* Third Dot */}
          <div className="h-4 w-4 rounded-full bg-white border-gray-400 border-2 flex items-center justify-center">
            <span className="text-black pt-12">report</span>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <div>
            <button className="bg-blue-200 bg-opacity-17 text-sm py-2 px-10 rounded-lg text-Step">Mock Interview</button>
          </div>
          <p className="text-2xl font-bold mt-2">Select Your Interview Type</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-20">
          <Link to="/mockinterview/audiotext" className="mt-5 py-3 rounded-lg bg-white flex flex-col justify-center items-center">
            <div className="text-center">
              <img src={img1} alt="" className="md:w-[200px] w-[180px]" />
              <p className="text-[#1A459C] font-semibold text-[11px]">Technology</p>
            </div>
          </Link>
          <Link to="/mockinterview/audiotext" className="mt-5 py-3 rounded-lg bg-white flex flex-col justify-center items-center">
            <div className="text-center">
              <img src={img2} className="md:w-[200px] w-[180px]"  alt="" />
              <p className="text-[#1A459C] font-semibold text-[11px]">Health Care</p>
            </div>
          </Link>
          <Link to="/mockinterview/audiotext" className="mt-5 py-3 rounded-lg bg-white flex flex-col justify-center items-center">
            <div className="text-center">
              <img src={img3} className="w-[700px] h-[188px]" alt="" />
              <p className="text-[#1A459C] font-semibold text-[11px]">Finance Service</p>
            </div>
          </Link>
          <Link to="/mockinterview/audiotext" className="mt-5 py-3 rounded-lg bg-white flex flex-col justify-center items-center">
            <div className="text-center">
              <img src={img4} className="md:w-[200px] w-[180px]"  alt="" />
              <p className="text-[#1A459C] font-semibold text-[11px]">Education</p>
            </div>
          </Link>
          <Link to="/mockinterview/audiotext"className="mt-5 py-3 rounded-lg bg-white flex flex-col justify-center items-center">
            <div className="text-center">
              <img src={img5} className="md:w-[200px] w-[180px]"  alt="" />
              <p className="text-[#1A459C] font-semibold text-[11px]">Manufacturing and Engineering</p>
            </div>
          </Link>
          <Link to="/mockinterview/audiotext" className="mt-5 py-3 rounded-lg bg-white flex flex-col justify-center items-center">
            <div className="text-center">
              <img src={img6} className="md:w-[200px] w-[180px]"  alt="" />
              <p className="text-[#1A459C] font-semibold text-[11px]">Retail and Ecommerce</p>
            </div>
          </Link>
          <Link to="/mockinterview/audiotext" className="mt-5 py-3 rounded-lg bg-white flex flex-col justify-center items-center">
            <div className="text-center">
              <img src={img7} className="md:w-[200px] w-[180px]"  alt="" />
              <p className="text-[#1A459C] font-semibold text-[11px]">Logistics and Supply Chain</p>
            </div>
          </Link>
          <Link to="/mockinterview/audiotext" className="mt-5 py-3 rounded-lg bg-white flex flex-col justify-center items-center">
            <div className="text-center">
              <img src={img8} alt="" className=" md:h-[160px] h-[150px]" />
              <p className="text-[#1A459C] font-semibold text-[11px] md:pt-11 pt-8">Other</p>
            </div>
          </Link>
        </div></div>
      </div>
    </>
  );
};

export default MockInterview;
