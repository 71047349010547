// import React, { useEffect, useState } from "react";
// import robot from "../../assets/images/bot.png";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { responseDataActions } from "../../store/responseDataSlice";
// import { ApiUrl, questionApi } from "../../Constant/ApiUrl";
// import Loader from "../../Components/Loader";
// import { FiUploadCloud } from "react-icons/fi";
// // import mocklibanner from '../assets/images/mocklibanner.png'
// import mocklibanner from '../../assets/images/mocklibanner.png'
// import DashboardBanner from "../../Components/DashboardBanner";
// const AudioSubmit = (props) => {
//   const [file, setFile] = useState(null);
//   const [jobTitle, setJobTitle] = useState(null);
//   const [experience, setExperience] = useState(null);
//   const [desc, setDesc] = useState(null);
//   const [responseDatas, setResponseDatas] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const [Name, setName] = useState("");

//   console.log(props, "<===")

//   useEffect(() => {
//     const Token = localStorage.getItem('user')
//     Token && axios.post(ApiUrl + '/get_user', {
//       hehe: 'jje'
//     },
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           authorization: Token
//         },

//       })
//       .then(x =>
//       (
//         setName(x.data.user_data.username)
//       )
//       )
//   }, [])
//   const navigate = useNavigate();

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleExperience = (e) => {
//     setExperience(e.target.value);
//   };

//   const handleTextChangeJob = (e) => {
//     setJobTitle(e.target.value);
//   };

//   const handleTextChangeDesc = (e) => {
//     setDesc(e.target.value);
//   };
//   const dispatch = useDispatch();

//   if (responseDatas?.questions && responseDatas?.questions?.length > 0) {
//     console.log("hello");
//     navigate("/dashboard/interview"); // Replace '/interview' with your desired route
//   }

//   const handleCheck = async () => {
//     setLoading(true)
//     const formData = new FormData();
//     file && formData.append("file", file);
//     jobTitle && formData.append("job_title", jobTitle);
//     experience && formData.append("years_experience", experience);
//     desc && formData.append("description", desc);

//     try {
//       let response
//       file ? response = await axios.post(
//         `${questionApi}/upload/`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       ) : response = await axios.post(
//         `${questionApi}/upload/`,
//         {
//           hehe: 'hehe'
//         },
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       )
//       response?.data && setLoading(false)
//       setResponseDatas(response.data);
//       dispatch(responseDataActions.setResponseData(response.data));
//     } catch (error) {
//       console.error("Axios error:", error.message);
//       // Optionally, handle error by setting an error state here and displaying it in the UI
//     }
//   };

//   return (
//     <div div className="mt-5 p-5 rounded-lg w-98p bg-white">
//       {loading && <Loader />}
//       {/* <div className="flex justify-center items-center">
//         <img src={mocklibanner} width={'80%'} alt="" />
//       </div> */}
//       <DashboardBanner />
//       <div className="flex items-center justify-center py-8 ">
//         {/* First Dot */}
//         <div className="h-4 w-4 rounded-full bg-Step flex items-center justify-center">
//           <span className="text-black pt-12">info</span>
//         </div>
//         {/* Dashed Line */}
//         <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
//         {/* Second Dot */}
//         <div className="h-4 w-4 rounded-full bg-[#8E339C]  flex items-center justify-center">
//           <span className="text-black pt-12">Interview</span>
//         </div>
//         {/* Dashed Line */}
//         <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
//         {/* Third Dot */}
//         <div className="h-4 w-4 rounded-full bg-white border-gray-400 border-2 flex items-center justify-center">
//           <span className="text-black pt-12">report</span>
//         </div>
//       </div>
//       <div className="flex flex-col md:flex-row pb-6 pt-7">
//         <div>
//           <img width={200} src={robot} alt="robot" />
//         </div>
//         <div className="bg-gradient-to-r from-[#E6251E] to-[#3C38FF] rounded-lg">
//           <p className="text-white pt-7 ps-4 pe-4 pb-1 text-start">
//             Hi {Name}! We need a bit more information about you and the
//             position to generate questions for you <br />
//             <p className="pt-5">
//               {" "}
//               All fields are optional! Go ahead and hit that "Start!" button
//               even if you don't have any details yet.
//             </p>
//           </p>
//         </div>
//       </div>
//       <div className="flex flex-col ">
//         <h1 className="text-start pt-4 pb-4 text-head font-bold text-lg">
//           About You (Optional)
//         </h1>
//         <div className="flex flex-col md:flex-row text-sm ">
//           <p>My desired job title/position is</p>
//           <input
//             className="bg-white border-0 border-b border-dotted w-28 text-center ps-2 pe-2 outline-none border-gray-300"
//             type="text"
//             value={jobTitle}
//             onChange={handleTextChangeJob}
//           />

//           <p>I have</p>
//           <input
//             value={experience}
//             onChange={handleExperience}
//             className="bg-white border-0 text-center ps-2 pe-2 border-b border-dotted w-28 outline-none border-gray-300"
//             type="number"
//           />
//           <p> years of experience in this industry.</p>
//         </div>
//         <div className="text-start flex flex-col md:flex-row gap-10">
//           <div className="flex-1">
//             <h1 className="pt-4 pb-4 text-head font-bold text-lg">
//               About The Position (Optional)
//             </h1>
//             <input
//               type="text"
//               placeholder="Job Description Link"
//               value={desc}
//               onChange={handleTextChangeDesc}
//               className="rounded-lg border-2 border-gray-200 outline-none p-4 w-full"
//             />
//           </div>
//           <div className="md:w-1/3">
//             <h1 className="pt-4 pb-4 text-head font-bold text-lg">
//               Upload CV (Optional)
//             </h1>
//             <label
//               htmlFor="upload"
//               className="flex justify-between items-center relative cursor-pointer w-full bg-white rounded-lg border-2 border-gray-200 outline-none p-4"
//             >
//               <span className="text-gray-400">Upload</span>
//               <FiUploadCloud className="text-gray-400" />
//               <input
//                 type="file"
//                 id="upload"
//                 style={{ display: "none" }}
//                 onChange={handleFileChange}
//               />
//             </label>
//           </div>
//         </div>

//         <div className="flex items-center justify-center pb-7 pt-7">
//           <div className="flex-grow border-t border-[#A3B5D7]"></div>
//           <span className="mx-4 -mb-px text-sm text-gray-600">Or</span>
//           <div className="flex-grow border-t border-[#A3B5D7]"></div>
//         </div>
//         <textarea
//           type="text"
//           className="h-32 p-4 border-2 border-gray-200 mb-6 outline-none rounded-lg w-full"
//           placeholder="Job Description Link"
//         />
//         <div className="">
//           <button
//             onClick={handleCheck}
//             className="text-center bg-gradient-to-r from-red-500 to-blue-500 text-white rounded-lg"
//             style={{ padding: "10px 80px" }}
//           >
//             Submit
//           </button>

//         </div>
//       </div>
//     </div>
//   );
// };

// export default AudioSubmit;

import React, { useEffect, useState } from "react";
import robot from "../../assets/images/bot.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { responseDataActions } from "../../store/responseDataSlice";
import { FiUploadCloud } from "react-icons/fi";
import DashboardBanner from "../../Components/DashboardBanner";
import { ApiUrl, mockApi } from "../../Constant/ApiUrl";
import { toast } from "react-toastify";

const AudioSubmit = () => {
  const [file, setFile] = useState(null);
  const [jobTitle, setJobTitle] = useState("");
  const [experience, setExperience] = useState("");
  const [desc, setDesc] = useState("");
  const [Name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
  const [userExists, setUserExists] = useState(false); // Track if user exists

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log("File selected:", selectedFile);  // Add this to check if file is selected
    if (selectedFile) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (allowedTypes.includes(selectedFile.type)) {
        setFile(selectedFile);
      } else {
        toast.error("Invalid file type. Please upload a PDF or Word document.");
      }
    }
  };

  // Handle job title input change and store it in localStorage
  const handleTextChangeJob = (e) => {
    const newJobTitle = e.target.value;
    setJobTitle(newJobTitle);
    localStorage.setItem("jobTitle", newJobTitle); // Save to localStorage
  };
  // Handle experience input change
  const handleExperienceChange = (e) => {
    setExperience(e.target.value);
  };

  // Handle job description input change
  const handleTextChangeDesc = (e) => {
    setDesc(e.target.value);
  };

  // Fetch user data on component mount and check if user exists
  useEffect(() => {
    const Token = localStorage.getItem("user");
    if (Token) {
      setUserExists(true);
      axios
        .post(
          `${ApiUrl}/get_user`,
          {},
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: Token,
            },
          }
        )
        .then((response) => {
          const userData = response.data?.user_data;
          if (userData) {
            setName(userData.username || "");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
          toast.error("Failed to fetch user data.");
        });
    } else {
      setUserExists(false);
      toast.warning("User not authenticated. Please log in.");
    }
  }, []);

  // Function to handle form submission
  const sendWithRetry = async (url, formData, retries = 5, timeout = 5000) => {
    while (retries > 0) {
      try {
        const response = await Promise.race([
          axios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          }),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Request timed out")), timeout)
          ),
        ]);
        return response; // Return response if successful
      } catch (error) {
        console.warn(`Request failed. Retries left: ${retries - 1}`);
        retries--;
        if (retries === 0) throw error; // Throw error if no retries left
      }
    }
  };
  
  const send_params = async () => {
    // Prevent multiple submissions
    if (isSubmitting) return;
  
    // Basic validation
    if (!jobTitle.trim() || !experience.trim() || !desc.trim()) {
      toast.warning("Please fill in all required fields.");
      return;
    }
  
    setIsSubmitting(true); // Set submitting state
  
    // Create FormData to handle text fields
    const formData = new FormData();
    formData.append("jobTitle", jobTitle);
    formData.append("experience", experience);
    formData.append("description", desc);
  
    try {
      const Token = localStorage.getItem("user");
      if (!Token) {
        toast.error("User not authenticated.");
        setIsSubmitting(false);
        return;
      }
  
      // Send POST request to /manual_input/ with retry
      const response = await sendWithRetry(`${mockApi}/manual_input/`, formData, 5, 5000);
  
      // Handle successful response
      if (response.status === 200) {
        toast.success("Information submitted successfully!");
        // Update Redux store if needed
        dispatch(
          responseDataActions.setResponseData({
            jobTitle,
            experience,
            desc,
          })
        );
  
        // If CV file exists, send it to /upload-cv/ with retry
        if (file) {
          const cvFormData = new FormData();
          cvFormData.append("file", file);
  
          const cvResponse = await sendWithRetry(`${mockApi}/upload-cv/`, cvFormData, 5, 5000);
  
          if (cvResponse.status === 200) {
            toast.success("CV uploaded successfully!");
          } else {
            toast.error("Failed to upload CV.");
          }
        }
  
        // Navigate to the next page
        navigate("/mockinterview/interviews/mockinterviewvoice");
      } else {
        toast.error("Failed to submit information.");
      }
    } catch (error) {
      console.error("Error submitting data:", error.message);
      toast.error("An error occurred while submitting your information.");
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div className="h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden p-5">
      <div
        className="overflow-y-auto no-scrollbar"
        style={{ maxHeight: "calc(110vh - 160px)" }}
      >
        <DashboardBanner />
        <div className="flex items-center justify-center py-8 ">
          {/* First Dot */}
          <div className="h-4 w-4 rounded-full bg-Step flex items-center justify-center">
            <span className="text-black pt-12">info</span>
          </div>
          {/* Dashed Line */}
          <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
          {/* Second Dot */}
          <div className="h-4 w-4 rounded-full bg-[#8E339C] flex items-center justify-center">
            <span className="text-black pt-12">Interview</span>
          </div>
          {/* Dashed Line */}
          <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
          {/* Third Dot */}
          <div className="h-4 w-4 rounded-full bg-white border-gray-400 border-2 flex items-center justify-center">
            <span className="text-black pt-12">report</span>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pb-6 pt-7">
          <div>
            <img width={200} src={robot} alt="robot" />
          </div>
          <div className="bg-gradient-to-r from-[#3C38FF] to-[#E6251E] rounded-lg">
            <p className="text-white pt-7 ps-4 pe-4 pb-1 text-start">
              Hi {Name}! We need a bit more information about you and the
              position to generate questions for you.
              <br />
              <p className="pt-5">
                All fields are optional! Go ahead and hit that "Start!" button
                even if you don't have any details yet.
              </p>
            </p>
          </div>
        </div>
        <div className="flex flex-col ">
          <h1 className="text-start pt-4 pb-4 text-head font-bold text-lg">
            About You (Optional)
          </h1>
          <div className="flex flex-col md:flex-row text-sm items-center space-x-2">
            <p className="">My desired job title/position is</p>
            <input
              className="bg-[#F0F0F0] border-0 border-b border-dotted w-48 sm:m-0 text-center ps-2 pe-2 outline-none border-gray-300"
              type="text"
              value={jobTitle}
              onChange={handleTextChangeJob}
              placeholder="e.g., Software Engineer"
            />
            <p>I have</p>
            <input
              value={experience}
              onChange={handleExperienceChange}
              className="bg-[#F0F0F0] border-0 border-b border-dotted w-20 sm:m-0 text-center ps-2 pe-2 outline-none border-gray-300"
              type="number"
              min="0"
              placeholder="Years"
            />
            <p>years of experience in this industry.</p>
          </div>
          <div className="text-start flex flex-col md:flex-row gap-10 mt-6">
            <div className="flex-1">
              <h1 className="pt-4 pb-4 text-head font-bold text-lg">
                About The Position (Optional)
              </h1>
              <input
                type="text"
                placeholder="Job Description"
                value={desc}
                onChange={handleTextChangeDesc}
                className="rounded-lg border-2 border-gray-200 outline-none p-4 w-full"
              />
            </div>
            <div className="md:w-1/3">
              <h1 className="pt-4 pb-4 text-head font-bold text-lg">
                Upload CV (Optional)
              </h1>
              <label
                htmlFor="upload"
                className="flex justify-between items-center relative cursor-pointer w-full bg-white rounded-lg border-2 border-gray-200 outline-none p-4"
              >
                <span className="text-gray-400">
                  {file ? file.name : "Upload your CV"}
                </span>
                <FiUploadCloud className="text-gray-400" />
                <input
                  type="file"
                  id="upload"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx"
                />
              </label>
            </div>
          </div>

          <div className="flex items-center justify-center pb-7 pt-7">
            <div className="flex-grow border-t border-[#A3B5D7]"></div>
            <span className="mx-4 -mb-px text-sm text-gray-600">Or</span>
            <div className="flex-grow border-t border-[#A3B5D7]"></div>
          </div>
          <textarea
            type="text"
            className="h-32 p-4 border-2 border-gray-200 mb-6 outline-none rounded-lg w-full"
            placeholder="Additional Job Description (Optional)"
          />

          {userExists && (
            <div className="flex justify-center">
              <button
                onClick={send_params}
                className={`text-center bg-gradient-to-r from-red-500 to-blue-500 text-white rounded-lg ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                style={{ padding: "10px 80px" }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudioSubmit;



