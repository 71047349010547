import React from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/main-logo.png"
import { ApiUrl } from "../../Constant/ApiUrl";
import BlogFooter from "./BlogFooter";
import DashboardBanner from "../../Components/DashboardBanner";

const NextPage = () => {
    const location = useLocation();
    const { cardData } = location.state || {}; // Destructure cardData or initialize as an empty object if undefined

    if (!cardData) {
        return <div>Loading...</div>; // Add loading state or error handling
    }

    const { image, title, description } = cardData;

    return (
        <div className="h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden ">
            <div className="sm:px-10 lg:px-20 px-10 justify-start flex flex-col w-full bg-[#F0F0F0] font-poppins h-[100vh] rounded-3xl overflow-y-auto no-scrollbar " style={{ maxHeight: 'calc(110vh - 160px)' }}>


                <div className="justify-start flex items-center align">
                    <img src={logo} className="md:h-20 md:w-20 h-12 w-12" />
                    <div className="flex"><p className="text-center  pt-2 font-bold lg:text-[22px] sm:text-[15px] text-[13px]">Mockli</p><p className="md:px-3 px-1 lg:text-2xl text-lg font-thin md:pt-2 pt-1 ">|</p><p className="md:ml-0  pt-2 font-bold lg:text-[22px] sm:text-[15px] text-[13px]">Your Personal AI Interviewer</p></div>
                </div>
                {/* <DashboardBanner /> */}
                <div className='flex justify-start'>
                    <button style={{ background: 'rgba(2,92,251,0.17)' }} className='text-[#025CFB] rounded-md px-8 py-2 self-start md:text-sm text-xs my-4 fpnt-medium'>Blogs</button>
                </div>
                <h1 className="font-bold lg:text-2xl md:text-xl text-md text-start my-3">{title}</h1>
                <div className="h-100 w-full my-3 rounded-full">
                    <img src={`${ApiUrl}/uploads/${image}` ? `${ApiUrl}/uploads/${image}` : "loading..."} alt={title} className="md:h-[300px] md:w-[100%] h-[200px] w-[100%] rounded-lg bg-cover" />
                </div>

                <p className=" text-justify font-poppins md:text-[16px] text-xs">{description}</p>
                <div className="">


                </div>
                <BlogFooter />
            </div>

        </div>
    );
};

export default NextPage;
