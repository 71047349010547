import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../../Constant/ApiUrl";
import { useDispatch, useSelector } from "react-redux";
import { responseDataActions } from "../../store/responseDataSlice";
import DashboardBanner from "../../Components/DashboardBanner";



const InterviewHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [responseDatas, setResponseDatas] = useState([]);

  useEffect(() => {
    const Token = localStorage.getItem('user');
    if (Token) {
      axios.post(ApiUrl + '/all_interview', {
        hehe: 'jje'
      },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: Token
          }
        })
        .then(response => {
          setResponseDatas(response.data.start_interview);
        })
        .catch(error => {
          console.error("Error fetching interview history:", error);
        });
    }
  }, []);

  console.log(responseDatas, 'dataaaaaaaaaaaaaaaaaaaaa')

  return (

    <div className="p-5 h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden" 
   
    >
      <div className="overflow-y-auto no-scrollbar" style={{ maxHeight: 'calc(110vh - 160px)' }}
     
      >
        <DashboardBanner />
        <div className="text-3xl text-start py-10 font-bold mt-2 px-12">Interview History</div>
        <div className="flex flex-wrap justify-center gap-10 px-12">
          {responseDatas.map((data, index) => (
            <div key={index} className="flex-shrink-0 w-full sm:w-[90%] md:w-[50%] xl:w-[30%] lg:w-[40%] gap-10" 
            onClick={()=>dispatch(responseDataActions.setInterview(data))}
            >
              <div className="shadow-xl h-auto mt-5 p-10 rounded-2xl bg-white ">
                <div className="flex flex-col gap-2 text-start w-full pb-10">
                  <p className="font-medium text-[16px]">
                    <span className="text-[#025CFB] font-semibold">Title:</span> None
                  </p>
                  <p className="font-medium text-[16px]">
                    <span className="text-[#025CFB] font-semibold text-[16px]">Time:</span> 2024-03-1 00:34
                  </p>
                  <p className="font-medium  text-[16px]">
                    <span className="text-[#025CFB] font-semibold">Company:</span> None
                  </p>
                  <p className="font-medium  text-[16px]">
                    <span className="text-[#025CFB] font-semibold ">Level:</span> None
                  </p>
                </div>
                <button
                  onClick={() => {

                    navigate('/interviewhistory/evaluation')
                    // dispatch(responseDataActions)
                  }}
                  className="bg-gradient-to-r from-red-600 to-blue-600 w-full py-2 mt-6 text-[15px] text-white rounded-2xl"
                >
                  View Full Evaluation
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>


  );
};

export default InterviewHistory;
