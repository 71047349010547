import React from 'react'
import mocklibanner from '../assets/images/mocklibanner.png'
import logo from "../assets/images/main-logo.png"
const DashboardBanner = () => {
  return (
    <>
         <div className="justify-start flex items-center align font-poppins ">
                    <img src={logo} className="md:h-20 md:w-20 h-12 w-12" />
                    <div className="flex"><p className="text-center  pt-2 font-bold lg:text-[22px] sm:text-[15px] text-[13px]">Mockli</p><p className="md:px-3 px-1 lg:text-2xl text-lg font-thin md:pt-2 pt-1 ">|</p><p className="md:ml-0  pt-2 font-bold lg:text-[22px] sm:text-[15px] text-[13px]">Your Personal AI Interviewer</p></div>
                </div>
    </>
  )
}

export default DashboardBanner