import React, { useEffect, useState } from "react";
// import header from "../../assets/images/header2.png";
import "../../App.css"; // Assuming you have a CSS file for this component
import { ApiUrl } from "../../Constant/ApiUrl";
import axios from "axios";
import DashboardBanner from "../../Components/DashboardBanner";

export default function Index() {
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [Title, setTitle] = useState("");
  const [Blog, setBlog] = useState("");
  const [ID, setID] = useState(0);
  const [doosra, setdoosra] = useState(0);
  const [UserBlogs, setUserBlogs] = useState([]);

  function dropHandler(ev) {
    ev.preventDefault(); // Prevent default behavior

    let files = [];
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          var file = ev.dataTransfer.items[i].getAsFile();
          console.log(`... file[${i}].name = ${file.name}`);
          files.push(file);
        }
      }
    } else {
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        console.log(`... file[${i}].name = ${ev.dataTransfer.files[i].name}`);
        files.push(ev.dataTransfer.files[i]);
      }
    }

    setDroppedFiles(files);
    console.log("File(s) dropped", files);
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  }

  useEffect(() => {
    axios.get(`${ApiUrl}/blog`).then((x) => setUserBlogs(x.data.Blogs));
  }, [doosra]);
  function handleImageChange(e) {
    e.preventDefault();

    let file = e.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDroppedFiles(file);
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }
  console.log(droppedFiles);
  const PostNow = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", droppedFiles);
    formData.append("title", Title);
    formData.append("blog", Blog);
    axios
      .post(`${ApiUrl}/blog`, formData)
      .then(
        (x) => (
          setBlog(""),
          setDroppedFiles([]),
          setImagePreviewUrl(null),
          setTitle(""),
          console.log(x, "<== check")
        )
      );
  };
  const UpdateNow=(e)=>{
    e.preventDefault();
    // console.log({ID,Blog,Title,droppedFiles})
    const formData = new FormData();
    formData.append("image", droppedFiles);
    formData.append("title", Title);
    formData.append("blog", Blog);
    axios
     .post(`${ApiUrl}/update_blog/${ID}`, formData)
     .then(
        (x) => (
            console.log(x),
          setBlog(""),
          setDroppedFiles([]),
          setImagePreviewUrl(null),
          setTitle("")
        )
      );
  }
  return (
    <div className="w-full h-screen flex justify-center items-center font-poppins">
      {doosra == 0 && (
        <div className="bg-white py-5 h-[95%] w-[90%] rounded-lg flex flex-col justify-center items-center">
          {/* <img src={header} alt="Header" /> */}
          <DashboardBanner />
          <div className="flex items-center justify-center w-full">
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-[280px] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-600 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500"
              onDragOver={(event) => event.preventDefault()}
              onDrop={dropHandler}
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">
                    Click to upload or drag and drop
                  </span>
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  PNG, JPG (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onChange={handleImageChange}
              />
            </label>
            {imagePreviewUrl && (
              <div className="image-preview-container">
                <img src={imagePreviewUrl} alt="Preview" />
              </div>
            )}
          </div>
          <div className="w-[90%] text-left">
            <label
              for="helper-text"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Title
            </label>
            <input
              type="text"
              id="helper-text"
              aria-describedby="helper-text-explanation"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Embracing the Digital Revolution ..."
              onChange={(e) => setTitle(e.target.value)}
              value={Title}
            />
          </div>
          <div className="w-[90%] text-left">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Write Your Blog
            </label>
            <textarea
              id="message"
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Write Your Blog......"
              value={Blog}
              onChange={(e) => setBlog(e.target.value)}
            ></textarea>
          </div>
          <div className="flex gap-4 my-3">
            <button
              onClick={(e) => PostNow(e)}
              className="bg-gradient-to-r  from-red-900 to-blue-900 text-white font-bold py-2 px-4 rounded"
            >
              Submit Post
            </button>
            <button
              onClick={(e) => setdoosra(1)}
              className="bg-gradient-to-r  from-red-900 to-blue-900 text-white font-bold py-2 px-4 rounded"
            >
              Next screen
            </button>
          </div>
        </div>
      )}
      {doosra == 1 && (
        <div className="bg-white  h-[90%] w-[90%] rounded-lg flex flex-col justify-center items-center">
          <div className="p-5 bg-gray-100 w-full">
            {UserBlogs?.length > 0 ? (
              UserBlogs.map((item, i) => (
                <div
                  key={i}
                  className="p-4 w-full flex justify-between  mb-4 border border-gray-300 rounded-lg bg-white transition-all duration-300 ease-in-out hover:shadow-lg"
                >
                  <h3 className="mb-3 text-lg">{item.title}</h3>
                  <div className="flex gap-2 ">
                    <button
                      onClick={() => {
                        setBlog(item.blog);
                        setTitle(item.title);
                        setID(item.id);
                        setdoosra(2);
                      }}
                      className="bg-gradient-to-r from-red-900 to-blue-900 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out hover:from-red-700 hover:to-blue-700"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        axios.get(`${ApiUrl}/delete_blog/${item.id}`);
                        const updatedBlogs = UserBlogs.filter(
                          (blog) => blog.id !== item.id
                        );
                        setUserBlogs(updatedBlogs);
                      }}
                      className="bg-gradient-to-r from-red-900 to-blue-900 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out hover:from-red-700 hover:to-blue-700"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No blogs found.</p>
            )}
          </div>

          <button
            onClick={(e) => setdoosra(0)}
            className="bg-gradient-to-r  from-red-900 to-blue-900 text-white font-bold py-2 px-4 rounded"
          >
            Previous screen
          </button>
        </div>
      )}
      {doosra == 2 && (
        <div className="bg-white  h-[90%] w-[90%] rounded-lg flex flex-col justify-center items-center">
          {/* <img src={header} alt="Header" /> */}
          <DashboardBanner />
          <div className="flex items-center justify-center w-full">
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-[280px] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-600 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500"
              onDragOver={(event) => event.preventDefault()}
              onDrop={dropHandler}
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">
                    Click to upload or drag and drop
                  </span>
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  PNG, JPG (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onChange={handleImageChange}
              />
            </label>
            {imagePreviewUrl && (
              <div className="image-preview-container">
                <img src={imagePreviewUrl} alt="Preview" />
              </div>
            )}
          </div>
          <div className="w-[90%] text-left">
            <label
              for="helper-text"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Title
            </label>
            <input
              type="text"
              id="helper-text"
              aria-describedby="helper-text-explanation"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Embracing the Digital Revolution ..."
              onChange={(e) => setTitle(e.target.value)}
              value={Title}
            />
          </div>
          <div className="w-[90%] text-left">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Write Your Blog
            </label>
            <textarea
              id="message"
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Write Your Blog......"
              value={Blog}
              onChange={(e) => setBlog(e.target.value)}
            ></textarea>
          </div>
          <div className="flex gap-4 my-3">
            <button
              onClick={(e) => UpdateNow(e)}
              className="bg-gradient-to-r  from-red-900 to-blue-900 text-white font-bold py-2 px-4 rounded"
            >
              Update Post
            </button>
            <button
              onClick={(e) => setdoosra(1)}
              className="bg-gradient-to-r  from-red-900 to-blue-900 text-white font-bold py-2 px-4 rounded"
            >
              previous screen
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
