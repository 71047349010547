import React from 'react'
import logo from "../../assets/images/main-logo.png"
import fb from "../../assets/images/fb.png";
import insta from "../../assets/images/insta.png";
import twitter from "../../assets/images/twitter.png"


const BlogFooter = () => {
  return (
    <div className=' bg-[#F0F0F0] sm:px-10 lg:px-12 pt-5 pb-3 px-10 bottom-0 font-poppins mt-4'>
      <div className="bg-[#1A1A1A] flex py-2 sm:py-6 md:py-6 lg:py-6 rounded-xl">
        <div className='flex md:w-[80%] w-[70%] items-center'>
          <img src={logo} />
          <div className="flex text-white"><p className="md:px-3 px-1 lg:text-4xl text-2xl font-thin ">|</p><p className="text-center font-bold lg:text-[32px] sm:text-[20px] text-[15px]">MOCKLI</p></div>
        </div>

        <div className='flex items-center justify-center md:w-[10%] w-[20%]'>
          <img src={fb} className='md:h-[30%] md:w-[30%] h-[20%] w-[20%]' />
          <img src={insta} className='md:h-[30%] md:w-[30%] h-[20%] w-[20%] md:mx-5 mx-2' />
          <img src={twitter} className='md:h-[30%] md:w-[30%] h-[20%] w-[20%]' />
        </div>
      </div>
    </div>
  )
}

export default BlogFooter