import React, { useEffect } from 'react'
import interview from "../../assets/images/rafiki.png"
import readiness from "../../assets/images/pana.png"
import Frame1 from "../../assets/images/Frame1.png"
import Frame2 from "../../assets/images/Frame2.png"
import Frame3 from "../../assets/images/Frame3.png"
import DynamicBlog from './DynamicBlog'
import axios from 'axios'
import { ApiUrl } from '../../Constant/ApiUrl'
import { useNavigate } from "react-router-dom";


const Card = ({ image, title, description }) => {
    return (
        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 p-5 flex justify-center font-poppins">
            <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
                <div className="p-2"> {/* Added padding around the image */}
                    <img src={image} alt={title} className="w-full h-25 object-cover rounded-xl" />
                </div>
                <div className="justify-start px-3 pb-1">
                    <p className="text-gray-700 text-start lg:text-[17px] md:text-[15px] text-[12px] font-medium">{description}</p>
                    {/* <p className="text-end text-xs py-2 font-thin text-[#F06464]">{description2}</p> */}
                </div>
            </div>
        </div>
    );
};

const Card1 = () => {

    const cardsData = [
        {
            image: Frame1,
            description: "Provide Job Link"
        },
        {
            image: Frame2,
            description: "Interactive Interview"
        },
        {
            image: Frame3,
            description: "Detailed Feedback"
        }
    ];
    const navigate = useNavigate();
    return (
        <div className='justify-start mt-[-45px] font-poppins'>
            <div className='flex gap-2 flex-col lg:flex-row w-[100%]'>
                <div className='lg:w-[50%]'>
                    <div className='text-start text-md font-bold pb-3'>Mock Interview</div>
                    <div className='bg-gradient-to-r from-[#F8E9E2] to-[#F9E4F2]  flex rounded-md'>
                        <div className='w-[47%] p-4 justify-start flex flex-col'>
                            <p className='py-5 text-pretty text-start font-medium  md:text-sm text-xs'>Empower Your Interview Success Assess and Elevate Your Readiness</p>
                            <button
                             onClick={()=> navigate('/mockInterview')} 
                             className='text-white bg-gradient-to-r from-[#5653FF] to-[#8163F0] rounded-full px-8 py-2 text-center self-start md:text-sm text-xs'>Review</button>
                        </div>
                        <div className='md:w-[6%]'></div>
                        <div className='w-[47%] justify-center p-5'>
                            <img src={interview} className='h-[100%] w-[100%]' />
                        </div>
                    </div>
                </div>
                <div className='lg:w-[50%]'>
                    <div className='text-start text-md font-bold pb-3'>Interview Readiness</div>

                    <div className='bg-gradient-to-r from-[#F8E9E2] to-[#F9E4F2]  flex rounded-md'>
                        <div className='sm:w-[47%] p-4 justify-start flex flex-col'>
                            <p className='py-5 text-pretty text-start font-medium md:text-sm text-xs'>Master Your Interview Performance Engage in Realistic Mock Interviews</p>
                            <button
                            onClick={()=> navigate('/dashboard')} 
                            className='text-white bg-gradient-to-r from-[#5653FF] to-[#8163F0] rounded-full px-8 py-2 text-center self-start md:text-sm text-xs'>Review</button>
                        </div>
                        <div className='md:w-[6%]'></div>
                        <div className='sm:w-[47%] justify-center p-5'>
                            <img src={readiness} className='h-[100%] w-[75%]' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-start'>
                <button style={{ background: 'rgba(2,92,251,0.17)' }} className='text-[#025CFB] rounded-md px-8 py-2 self-start md:text-sm text-xs mt-11 mb-6'>How We Work</button>
            </div>
            <div className='flex justify-start'>
                <div className='flex flex-col justify-start'>
                    <div className='font-bold text-2xl text-start'>Provide Your Job Link Begin Interactive </div>
                    <div className='font-bold text-2xl text-start'>Interview Obtain Detailed Feedback</div>
                </div>
            </div>
            <div className="container mx-auto py-3">
                <div className="flex flex-wrap -mx-5">
                    {cardsData.map((card, index) => (
                        <Card key={index} {...card} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Card1