import React from "react";
import { Link, useNavigate } from "react-router-dom";
import audio from '../../assets/images/audio.png'
import text from '../../assets/images/text.png'
import DashboardBanner from "../../Components/DashboardBanner";

const AudioText = () => {

    const navigate = useNavigate()
    const handleNavigateAudio = () => {
        navigate('/mockinterview/audio')
    }
    const handleNavigateText = () => {
        navigate('/mockinterview/text')
    }



    return (
        <>

            <div className="h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden ">


                <div className="sm:px-10 lg:px-20 px-10 justify-start flex flex-col w-full bg-[#F0F0F0] font-poppins h-[100vh] rounded-3xl pt-4 overflow-y-auto no-scrollbar " style={{ maxHeight: 'calc(110vh - 160px)' }}>
                    <DashboardBanner />
                    <div className="flex items-center justify-center py-8 pb-12 ">
                        {/* First Dot */}
                        <div className="h-4 w-4 rounded-full bg-Step flex items-center justify-center">
                            <span className="text-black pt-12">info</span>
                        </div>
                        {/* Dashed Line */}
                        <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
                        {/* Second Dot */}
                        <div className="h-4 w-4 rounded-full bg-[#8E339C]  flex items-center justify-center">
                            <span className="text-black pt-12">Interview</span>
                        </div>
                        {/* Dashed Line */}
                        <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
                        {/* Third Dot */}
                        <div className="h-4 w-4 rounded-full bg-white border-gray-400 border-2 flex items-center justify-center">
                            <span className="text-black pt-12">report</span>
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-start pb-16">
                        <div>
                            <button className="bg-blue-200 bg-opacity-17 text-sm py-2 px-10 rounded-lg text-Step">Mock Interview</button>
                        </div>
                        <p className="text-2xl font-bold mt-2">Select Your Interview Type</p>
                    </div>



                    <div className="flex flex-col lg:flex-row md:flex-row justify-center items-center gap-10">
                        <div onClick={handleNavigateAudio} className="flex flex-col xl:w-[23%] lg:w-[27%] md:w-[35%] w-[50%]  xl:h-90 lg:h-90 md:h-90  gap-5 bg-white rounded-xl ">
                            <div className="flex justify-center items-center py-14"> <img src={audio} width={140} alt="" /></div>
                            <p className="text-PrimaryColor font-semibold pb-4">By Audio</p>
                        </div>
                        <div onClick={handleNavigateText} className="flex flex-col xl:w-[23%] lg:w-[27%] md:w-[35%] w-[50%]  xl:h-90 lg:h-90 md:h-90  gap-5 bg-white rounded-xl ">
                            <div className="flex justify-center items-center py-14"> <img src={text} width={150} alt="" /></div>
                            <p className="text-PrimaryColor font-semibold pb-4">By Text</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AudioText;
