import React, { useEffect, useState } from "react";
import robot from "../../assets/images/bot2.gif";
import robotText from '../../assets/images/bottext.png';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoMdCloseCircleOutline, IoMdClose } from "react-icons/io";
import profile from "../../assets/images/profile.png";
import 'regenerator-runtime';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { mockApi, ApiUrl, questionApi } from "../../Constant/ApiUrl";
import Loader from "../../Components/Loader";
import DashboardBanner from "../../Components/DashboardBanner";
import { FaRegCirclePlay } from "react-icons/fa6";
import submits from '../../assets/images/submit.png';
import button from '../../assets/images/button.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InterviewText = () => {
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const [text, setText] = useState("");
    const [InterviewId, setInterViewId] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [History, setHistory] = useState("");
    const [Transcript, setTranscriptState] = useState(true);
    const [robotConve, setRobotConve] = useState('');
    const [isWaitingResponse, setIsWaitingResponse] = useState(false); // Tracks if waiting for response
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const responseData = useSelector((state) => state.responseData);

    // State for user information
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");

    // State for conversation and Q&A pairs
    const [Conversation, setConversation] = useState([]);
    const [qaPairs, setQaPairs] = useState([]);

    // State to store available voices
    const [voices, setVoices] = useState([]);

    useEffect(() => {
        // Fetch user data on component mount
        const Token = localStorage.getItem('user');
        if (Token) {
            axios.post(`${ApiUrl}/get_user`, { hehe: 'jje' }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    authorization: Token
                }
            })
                .then(res => {
                    setName(res?.data?.user_data?.username || "");
                    setEmail(res?.data?.user_data?.email || "");
                })
                .catch(error => {
                    console.error("Error fetching user data:", error.message);
                });
        }
    }, []);

    useEffect(() => {
        // Update text state when transcript changes
        setText(transcript);
    }, [transcript]);

    useEffect(() => {
        // Parse conversation into Q&A pairs
        const tempQaPairs = [];
        let questionNumber = 1;
        for (let i = 1; i < Conversation.length; i += 2) {
            const question = Conversation[i]?.replace('robot: ', '');
            const answer = Conversation[i + 1]?.replace('me: ', '');
            if (question && answer) {
                tempQaPairs.push({ questionNumber, question, answer });
                questionNumber++;
            }
        }
        setQaPairs(tempQaPairs);
    }, [Conversation]);

    useEffect(() => {
        // Load available voices
        const loadVoices = () => {
            const availableVoices = window.speechSynthesis.getVoices();
            setVoices(availableVoices);
            console.log("Available voices:", availableVoices);
        };

        if (window.speechSynthesis) {
            window.speechSynthesis.onvoiceschanged = loadVoices;
            loadVoices(); // Load voices initially
        } else {
            console.error("Speech Synthesis not supported in this browser.");
        }
    }, []);

    // Function to play voice using Web Speech API
    const playVoice = (message) => {
        if (!window.speechSynthesis) {
            console.error("Speech Synthesis not supported in this browser.");
            return;
        }

        const utterance = new SpeechSynthesisUtterance(message);
        
        // Select a specific voice (e.g., US English Female)
        const selectedVoice = voices.find(voice => voice.lang === 'en-US' && voice.name.toLowerCase().includes('female'));

        if (selectedVoice) {
            utterance.voice = selectedVoice;
            console.log(`Using voice: ${selectedVoice.name}`);
        } else {
            console.warn("Desired voice not found. Using default voice.");
        }

        // Handle speech synthesis events (optional)
        utterance.onstart = () => {
            console.log("Speech started");
        };

        utterance.onend = () => {
            console.log("Speech ended");
        };

        utterance.onerror = (e) => {
            console.error("Speech synthesis error:", e);
        };

        window.speechSynthesis.speak(utterance);
    };

    // Function to send message with retry mechanism
    const sendWithRetry = async (formData, maxRetries = 3, retryDelay = 2000, timeout = 5000) => {
        let attempts = 0;
        while (attempts < maxRetries) {
            try {
                const response = await Promise.race([
                    axios.post(`${mockApi}/manual/`, formData, {
                        headers: { "Content-Type": "multipart/form-data" }
                    }),
                    new Promise((_, reject) =>
                        setTimeout(() => reject(new Error('Request timed out')), timeout)
                    )
                ]);
                return response;
            } catch (error) {
                attempts++;
                if (attempts >= maxRetries) {
                    throw error;
                }
                console.warn(`Attempt ${attempts} failed. Retrying in ${retryDelay}ms...`);
                await new Promise(res => setTimeout(res, retryDelay));
            }
        }
    };

    // Unified function to handle sending messages
    const sendMessage = async (e) => {
        if (e) e.preventDefault();

        if (isWaitingResponse) {
            toast.info('Please wait for the current response to arrive.');
            return;
        }

        if (text.trim() === "") {
            toast.warning('Please enter your answer before submitting.');
            return;
        }

        setIsWaitingResponse(true); // Disable send actions
        setConversation(prev => [...prev, `me: ${text}`]);

        // Initialize interview on first message
        if (InterviewId === null) {
            try {
                const startResponse = await axios.post(`${ApiUrl}/start_now`, { hello: 'dd' }, {
                    headers: {
                        authorization: localStorage.getItem('user')
                    }
                });
                setInterViewId(startResponse.data.start_interview.id);
            } catch (error) {
                console.error("Error starting interview:", error.message);
                toast.error("Failed to start interview.");
                setIsWaitingResponse(false); // Re-enable send actions
                return;
            }
        }

        // Prepare form data
        const formData = new FormData();
        formData.append("text", text);
        formData.append("name", Name);
        formData.append("desired_job", responseData.value.jobTitle);
        formData.append("experience", responseData.value.experience);
        formData.append("job_description", responseData.value.desc);
        formData.append("history", History);

        try {
            // Send user's response to the backend with retry
            const response = await sendWithRetry(formData);
            
            // Update conversation with robot's response
            const robotMessage = `robot: ${response.data.chat}`;
            setConversation(prev => [...prev, robotMessage]);
            setHistory(response.data.history || "");
            setRobotConve(response.data.chat);

            // Play robot's voice response
            playVoice(response.data.chat);
        } catch (error) {
            console.error("Axios error:", error.message);
            toast.error("Failed to send your answer after multiple attempts.");
        } finally {
            setIsWaitingResponse(false); // Re-enable send actions
            setText(""); // Clear input
            resetTranscript(); // Reset speech transcript
        }
    };

    // Function to stop the interview and handle evaluation
const StopInterview = async (e) => {
    e.preventDefault();
    SpeechRecognition.stopListening(); // Stop listening for voice input
    setLoading(true); // Show loading indicator
    setIsWaitingResponse(true); // Disable interactions during processing

    const formData = new FormData();
    formData.append("history", History); // Add interview history to the request payload

    const retryRequest = async (url, data, maxRetries = 5, delay = 1000) => {
        let attempts = 0;
        while (attempts < maxRetries) {
            try {
                const response = await axios.post(url, data, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                return response.data;
            } catch (error) {
                attempts++;
                if (attempts >= maxRetries) {
                    throw error;
                }
                console.warn(`Retrying request (${attempts}/${maxRetries})...`);
                await new Promise((resolve) => setTimeout(resolve, delay));
            }
        }
    };

    try {
        // Step 1: Send history to the backend for analysis with retries
        const analysisData = await retryRequest(`${mockApi}/analysis/`, formData);
        console.log("Analysis Response:", analysisData);

        // Save analysis data to local storage
        localStorage.setItem("analysisData", JSON.stringify(analysisData));

        // Step 2: Send history to retrieve criteria-based feedback with retries
        const criteriaData = await retryRequest(`${mockApi}/criteria/`, formData);
        console.log("Criteria Response:", criteriaData);

        // Save criteria data to local storage
        localStorage.setItem("criteriaData", JSON.stringify(criteriaData));

        // Step 3: Prepare evaluation data
        const evaluationData = {
            interview_id: InterviewId,
            overal_decision: analysisData?.OverallDecision || "",
            overall_sentiment: analysisData?.OverallSentiment || "",
            area_of_strength: analysisData?.AreasOfStrength || "",
            area_to_improve: analysisData?.AreasToImprove || "",
            selfintroduction: criteriaData?.SelfIntroduction || "",
            team_colab: criteriaData?.TeamworkAndCollaboration || "",
            promblem_solving: criteriaData?.ProblemSolvingSkills || "",
            adabtibility: criteriaData?.Adaptibility || "",
            comunication: criteriaData?.Communication || "",
        };

        // Save evaluation data to local storage
        localStorage.setItem("evaluationData", JSON.stringify(evaluationData));

        // Step 4: Submit evaluation data with retries
        const evalResponse = await retryRequest(`${ApiUrl}/evaluation`, evaluationData);
        console.log("Evaluation Response:", evalResponse);

        const evaluation_id = evalResponse?.creat_eval?.id;
        if (!evaluation_id) {
            throw new Error("Evaluation ID is undefined. Check the API response structure.");
        }

        // Step 5: Submit Q&A feedback for each question with retries
        const feedbackResponses = [];
        if (qaPairs.length === 0) {
            console.warn("No Q&A pairs found to submit feedback.");
        } else {
            for (let i = 0; i < qaPairs.length; i++) {
                const questionData = new FormData();
                questionData.append("ques", `${qaPairs[i].questionNumber}. ${qaPairs[i].question}`);
                questionData.append("ans", qaPairs[i].answer);

                console.log(`Submitting feedback for Q${i + 1}:`, {
                    question: qaPairs[i].question,
                    answer: qaPairs[i].answer,
                });

                try {
                    // Submit feedback and retrieve response with retries
                    const feedbackData = await retryRequest(`${questionApi}/feedback/`, questionData);
                    console.log(`Feedback Response for Q${i + 1}:`, feedbackData);

                    feedbackResponses.push(feedbackData);

                    // Save feedback responses to local storage
                    localStorage.setItem("feedbackResponses", JSON.stringify(feedbackResponses));

                    // Save Q&A along with feedback to the database
                    await retryRequest(`${ApiUrl}/insert_ques_ans`, {
                        quest: qaPairs[i].question,
                        answer: qaPairs[i].answer,
                        evaluation_id: evaluation_id,
                        feedback: feedbackData?.feedback || "",
                        revised_answer: feedbackData?.revised_answer || "",
                        purpose: feedbackData?.purpose || "",
                        star: feedbackData?.star || "",
                    });

                    // Submit criteria-based data (if available)
                    if (feedbackData?.criteria) {
                        for (const criterion of feedbackData.criteria) {
                            await retryRequest(`${ApiUrl}/insert_ans_criteria`, {
                                heading: criterion.heading || "",
                                score: criterion.score || 0,
                                description: criterion.description || "",
                                quest_id: feedbackData?.quest_ans?.id || "",
                            });
                        }
                    }
                } catch (feedbackError) {
                    console.error(`Error posting feedback for Q${i + 1}:`, feedbackError);
                }
            }
        }

        // Save feedback responses to state
        console.log("Final Feedback Responses:", feedbackResponses);

        // Redirect to /interviewhistory/evaluation after completion
        toast.success("Interview ended and evaluation submitted successfully.");
        navigate("/interviewhistory/evaluation");
    } catch (error) {
        console.error("Error stopping interview:", error);
        toast.error("Failed to stop interview. Please try again.");
    } finally {
        setLoading(false); // Hide loading indicator
        setIsWaitingResponse(false); // Re-enable interactions
    }
};

           

    // Function to handle Enter key press in the input field
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            sendMessage(e);
        }
    };

    return (
        <>
            {Loading && <Loader />}
            <ToastContainer />

            <div className="p-5 h-[95vh] mt-2 mr-3 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden">
                <div className="overflow-y-auto no-scrollbar" style={{ maxHeight: 'calc(125vh - 160px)' }}>
                    <DashboardBanner />
                    <div className={`grid grid-cols-1 w-full h-[900px] lg:h-[512px] md:h-[650px] md:grid-cols ${!Transcript && Conversation?.length > 0 ? 'lg:grid-cols-3' : 'lg:grid-cols-1'} `}>
                        {/* Main Interview Section */}
                        <div className="mt-5 p-5 rounded-lg md:col-span-3 lg:col-span-2 xl:col-span-2 w-full">
                            <div className="flex justify-between items-center">
                                <h1 className="text-head text-2xl font-semibold">Welcome {Name}</h1>
                                <button onClick={() => setTranscriptState(!Transcript)} className="focus:outline-none">
                                    {Transcript && <img src={button} width={120} alt="Toggle Transcript" />}
                                </button>
                            </div>
                            <p className="font-medium text-start leading-10 md:text-lg mt-3 lg:text-sm text-sm text-gray-700">
                                Click "Start Interview" to get Started!
                            </p>
                            <div className="flex flex-col justify-center items-center mt-5">
                                {/* Robot Animation and Text */}
                                <div className="flex items-center">
                                    <img src={robot} alt="robot" width={120} className="py-10" />
                                    {robotConve !== "" && (
                                        <div className="relative hidden lg:block ml-4">
                                            <img src={robotText} width={200} alt="Robot Text" />
                                            <p className="absolute top-7 text-xs left-7 w-36 text-white truncate">{robotConve}</p>
                                        </div>
                                    )}
                                </div>

                                {/* Start/Stop Interview Button */}
                                {Conversation?.length < 1 ? (
                                    <button
                                        onClick={() => {
                                            SpeechRecognition.startListening({ continuous: true });
                                            sendMessage(); // Use unified sendMessage
                                            setTranscriptState(!Transcript);
                                        }}
                                        className={`bg-gradient-to-r from-red-700 to-blue-700 mb-4 text-white flex justify-center items-center py-2 px-8 gap-5 rounded-xl lg:text-xl text-sm ${isWaitingResponse ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={isWaitingResponse} // Disable button when waiting
                                    >
                                        <FaRegCirclePlay className="text-2xl" /> Start Interview
                                    </button>
                                ) : (
                                    <button
                                        onClick={StopInterview}
                                        className={`bg-gradient-to-r from-red-800 to-blue-800 mb-4 text-white flex justify-center items-center py-2 px-8 gap-5 rounded-xl lg:text-xl text-sm ${isWaitingResponse ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={isWaitingResponse} // Disable button when waiting
                                    >
                                        <IoMdClose className="text-3xl" /> Stop Interview
                                    </button>
                                )}
                            </div>

                            {/* Input Field for User's Answer */}
                            <div className="relative mt-2 mb-4">
                                <input
                                    className={`py-2 ps-2 rounded-xl w-full border outline-none ${isWaitingResponse ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    value={text}
                                    placeholder="Type your Answer"
                                    onChange={e => setText(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    disabled={isWaitingResponse} // Disable input when waiting
                                />
                                <img
                                    src={submits}
                                    width={50}
                                    alt="Submit"
                                    className={`absolute top-1.5 right-2 cursor-pointer ${isWaitingResponse ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={sendMessage}
                                    style={{ pointerEvents: isWaitingResponse ? 'none' : 'auto' }} // Prevent clicking when disabled
                                />
                            </div>

                            {/* Optional: Display a loading indicator near the input */}
                            {isWaitingResponse && (
                                <div className="flex justify-center">
                                    <span className="text-gray-500 text-sm">Sending...</span>
                                </div>
                            )}
                        </div>

                        {/* Transcript Section */}
                        {!Transcript && Conversation.length > 0 && (
                            <div className="scroll mt-10 p-5 rounded-lg overflow-y-auto bg-white md:col-span-1 lg:col-span-1 xl:col-span-1">
                                <div className="flex justify-center items-center mb-4">
                                    <h1 className="text-2xl text-head font-semibold">Transcript</h1>
                                    <span
                                        onClick={() => setTranscriptState(!Transcript)}
                                        className="text-4xl text-red-500 ms-10 cursor-pointer"
                                    >
                                        <IoMdCloseCircleOutline />
                                    </span>
                                </div>
                                {Conversation.map((y, index) => {
                                    const me = y.includes('me:');
                                    const messageContent = me ? y.replace('me: ', '') : y.replace('robot: ', '');

                                    return (
                                        <React.Fragment key={index}>
                                            {messageContent && (
                                                <div className={`flex flex-col mt-4 ${me ? 'items-end' : 'items-start'}`}>
                                                    <div className={`flex items-center ${me ? 'justify-end' : 'justify-start'}`}>
                                                        <img src={profile} alt="Profile" className="w-8 h-8 rounded-full" />
                                                        <p className="text-center text-blue-600 font-medium text-sm ml-2">
                                                            {me ? 'You' : 'Interviewer'}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className={`bg-gradient-to-r from-blue-500 to-indigo-500 w-auto rounded-lg text-start p-5 text-white relative z-10 ${me ? 'ml-auto' : ''}`}>
                                                            <div className={`flex items-center ${me ? 'justify-end' : 'justify-start'}`}>
                                                                {messageContent}
                                                            </div>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            </>
        );

    };

    export default InterviewText;
