// exports.ApiUrl = "https://q2drk2ct-8080.inc1.devtunnels.ms"


// exports.questionApi = "https://interview-gm3x.onrender.com"
// // exports.questionApi = "https://jnjd8s9d-8007.euw.devtunnels.msm"

// exports.mockApi = "https://interview-gm3x.onrender.com"
// // exports.questionApi = "https://jnjd8s9d-8000.euw.devtunnels.ms"
// // exports.mockApi = "https://jnjd8s9d-8000.euw.devtunnels.ms"
// // exports.ApiUrl = "http://192.168.100.15:8080"
// exports.ApiUrl = "https://interview.askaritechs.org"


// exports.questionApi = "https://jnjd8s9d-8007.euw.devtunnels.msm"

// exports.questionApi = "https://jnjd8s9d-8000.euw.devtunnels.ms"
// exports.mockApi = "https://jnjd8s9d-8000.euw.devtunnels.ms"
// exports.ApiUrl = "http://192.168.100.15:8080"





exports.questionApi = "https://q2drk2ct-8000.inc1.devtunnels.ms"

exports.mockApi = "https://q2drk2ct-8000.inc1.devtunnels.ms"

exports.ApiUrl = "https://q2drk2ct-8080.inc1.devtunnels.ms"
