import React, { useEffect, useState } from "react";
import robot from "../../assets/images/bot.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { responseDataActions } from "../../store/responseDataSlice";
import { ApiUrl, questionApi } from "../../Constant/ApiUrl";
import Loader from "../../Components/Loader";
import { FiUploadCloud } from "react-icons/fi";
// import mocklibanner from '../assets/images/mocklibanner.png'
import mocklibanner from '../../assets/images/mocklibanner.png'
import DashboardBanner from "../../Components/DashboardBanner";
const Interviews = (props) => {
  const [file, setFile] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [experience, setExperience] = useState(null);
  const [desc, setDesc] = useState(null);
  const [responseDatas, setResponseDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");


  const [Name, setName] = useState("");

  console.log(props, "<===")

  const Token =  localStorage.getItem("user");
  console.log(Token, "<===")

  useEffect(() => {
    const Token = localStorage.getItem('user')
    Token && axios.post(ApiUrl + '/get_user', {
      hehe: 'jje'
    },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: Token
        },

      })
      .then(x =>
      (
        setName(x?.data?.user_data?.username)
      )
      )
  }, [])
  const navigate = useNavigate();

  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first file selected
    if (file) {
      setFileName(file.name); // Update state with file name
    }
  };
  const handleExperience = (e) => {
    setExperience(e.target.value);
  };

  const handleTextChangeJob = (e) => {
    setJobTitle(e.target.value);
  };

  const handleTextChangeDesc = (e) => {
    setDesc(e.target.value);
  };
  const dispatch = useDispatch();

  if (responseDatas?.questions && responseDatas?.questions?.length > 0) {
    console.log("hello");
    navigate("/dashboard/interview"); // Replace '/interview' with your desired route
  }

  const handleCheck = async () => {
    setLoading(true)
    const formData = new FormData();
    file && formData.append("file", file);
    jobTitle && formData.append("job_title", jobTitle);
    experience && formData.append("years_experience", experience);
    desc && formData.append("description", desc);

    try {
      let response
      file ? response = await axios.post(
        `${questionApi}/upload/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ) : response = await axios.post(
        `${questionApi}/upload/`,
        {
          hehe: 'hehe'
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      response?.data && setLoading(false)
      setResponseDatas(response.data);
      dispatch(responseDataActions.setResponseData(response.data));
    } catch (error) {
      console.error("Axios error:", error.message);
      // Optionally, handle error by setting an error state here and displaying it in the UI
    }
  };

  return (
    <div className="p-5 h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden">
      <div className="overflow-y-auto no-scrollbar" style={{ maxHeight: 'calc(110vh - 160px)' }}>
        {loading && <Loader />}
        {/* <div className="flex justify-center items-center">
        <img src={mocklibanner} width={'80%'} alt="" />
      </div> */}
        <DashboardBanner />
        <div className="flex items-center justify-center py-8 ">
          {/* First Dot */}
          <div className="h-4 w-4 rounded-full bg-Step flex items-center justify-center">
            <span className="text-black pt-12">info</span>
          </div>
          {/* Dashed Line */}
          <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
          {/* Second Dot */}
          <div className="h-4 w-4 rounded-full bg-white border-gray-400 border-2 flex items-center justify-center">
            <span className="text-black pt-12">Interview</span>
          </div>
          {/* Dashed Line */}
          <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
          {/* Third Dot */}
          <div className="h-4 w-4 rounded-full bg-white border-gray-400 border-2 flex items-center justify-center">
            <span className="text-black pt-12">report</span>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pb-6 pt-7">
          <div >
            <img width={200} src={robot} alt="robot" className="hidden md:block" />
          </div>
          <div className="bg-gradient-to-r from-[#3C38FF] to-[#E6251E] rounded-3xl">
            <p className="text-white pt-7 ps-4 pe-4 pb-1 text-start">
              Hi {Name}! We need a bit more information about you and the
              position to generate questions for you <br />
              <p className="pt-5">
                {" "}
                All fields are optional! Go ahead and hit that "Start!" button
                even if you don't have any details yet.
              </p>
            </p>
          </div>
        </div>
        <div className="flex flex-col ">
          <h1 className="text-start pt-4 pb-4 text-head font-bold text-lg">
            About You (Optional)
          </h1>
          <div className="flex flex-col md:flex-row justify-center items-center gap-3 lg:justify-start lg:items-start md:justify-start md:items-start text-sm ">
            <p>My desired job title / position is</p>
            <input
              className={`bg-[#F0F0F0] input-placeholder border-b-2 text-center mx-2 ps-2 pe-2 shadow-lg  w-28 outline-none border-gray-800 hover:scale-105 hover:shadow-lg transition-transform duration-300 cursor-text animate-blink focus:animate-none ${jobTitle ? 'animate-none': "animate-blink"} `}
              type="text"
              value={jobTitle}
              onChange={handleTextChangeJob}
            />
{/* <div className="experience">
  I have <span class="input-placeholder bg-purple-100 border-b-2 border-dashed border-purple-700 text-purple-700 font-bold px-2 py-1 rounded-md shadow-md hover:scale-105 hover:shadow-lg transition-transform duration-300 cursor-text">------</span> years of experience.
</div> */}

            <p>I have </p>
            <input
              value={experience}
              onChange={handleExperience}
              className={`bg-[#F0F0F0] input-placeholder border-b-2 text-center mx-2 ps-2 pe-2 shadow-lg  w-28 outline-none border-gray-800 hover:scale-105 hover:shadow-lg transition-transform duration-300 cursor-textanimate-blink focus:animate-none ${experience ? 'animate-none': "animate-blink"} `}
              type="number"
            />
            <p> years of experience in this industry.</p>
          </div>

          <div className="text-start flex flex-col md:flex-row gap-10 justify-center md:items-center">
            <div className="flex-1">
              <h1 className="pt-4 pb-4 text-head font-bold text-lg">
                About The Position (Optional)
              </h1>
              <input
                type="text"
                placeholder="Job Description Link"
                value={desc}
                onChange={handleTextChangeDesc}
                className="rounded-lg border-2 border-gray-200 outline-none p-4 w-full"
              />
            </div>
            <div className="md:w-1/3">
              <h1 className="pt-4 pb-4 text-head font-bold text-lg">
                Upload CV (Optional)
              </h1>
           
              <label
                htmlFor="upload"
                className="flex justify-between items-center relative cursor-pointer w-full bg-white rounded-lg border-2 border-gray-200 outline-none p-4"
              >
                   {fileName ? fileName : 
                   <div className="flex gap-3 items-center">
                    <FiUploadCloud className="text-gray-400" />

                <span className="text-gray-400">Upload</span>

                </div>
                 }
               
                <input
                  type="file"
                  id="upload"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                 {/* {fileName && <p>{fileName}</p>} */}
              </label>

            </div>
          </div>

          <div className="flex items-center justify-center pb-7 pt-7">
            <div className="flex-grow border-t border-[#A3B5D7]"></div>
            <span className="mx-4 -mb-px text-sm text-gray-600">Or</span>
            <div className="flex-grow border-t border-[#A3B5D7]"></div>
          </div>
          <textarea
            type="text"
            className="h-32 p-4 border-2 border-gray-200 mb-6 outline-none rounded-lg w-full"
            placeholder="Job Description Link"
          />
          {Token &&
          <div className="">
            <button
              onClick={handleCheck}
              className="text-center bg-gradient-to-r from-red-500 to-blue-500 text-white rounded-lg"
              style={{ padding: "10px 80px" }}
            >
              Submit 
            </button>

          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Interviews;
