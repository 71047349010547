import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../layout";
import Dashboard from "../pages/InterviewVoice";
import Interview from "../pages/interview";
import InterviewHistory from "../pages/interviewhistory";
import MockInterview from "../pages/mockinterview";
import evaluation from "../pages/evaluation";
import Signup from "../pages/signup";
import Login from "../pages/login";
import Navbar from "../pages/MainPage/Navbar";
import Interviews from "../pages/mockinterview/Interviews";
import Evaluation1 from "../pages/evaluation/Evaluation1";
import SecondInterview from "../pages/secondInterview";
import axios from "axios";
import { ApiUrl } from "../Constant/ApiUrl";
import AudioText from "../pages/AudioText";
import AudioSubmit from "../pages/AudioSubmit";
import TextSubmit from "../pages/TextSubmit";
import InterviewVoice from "../pages/InterviewVoice";
import InterviewText from "../pages/InterviewText";
import BlogPost from '../pages/blog_post'
import Blog from '../pages/Blog/Blog'
import ShowBlog from '../pages/Blog/ShowBlog'
import DynamicBlog from "../pages/Blog/DynamicBlog";
import DynamicBlog2 from "../pages/Blog/DynamicBlog2";
const Routers = () => {
  const [Name, setName] = useState("");
  useEffect(() => {
    const Token = localStorage.getItem("user");
    Token &&
      axios
        .post(
          ApiUrl + "/get_user",
          {
            hehe: "jje",
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: Token,
            },
          }
        )
        .then((x) => setName(x?.data?.user_data?.username));
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Blog />
            </Layout>
          }
        />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <Layout>
              <Interviews />
            </Layout>
          }
        />
        <Route
          path="/dashboard/interview"
          element={
            <Layout>
              <Interview />
            </Layout>
          }
        />

        <Route
          path="/mockinterview"
          element={
            <Layout>
              <MockInterview />
            </Layout>
          }
        />
        <Route
          path="/Blog/ShowBlog"
          element={
            <Layout>
              <ShowBlog />
            </Layout>
          }
        />

        <Route
          path="/mockinterview/interviews"
          element={
            <Layout>
              <SecondInterview />
            </Layout>
          }
        />

        <Route
          path="/mockinterview/audio"
          element={
            <Layout>
              <AudioSubmit />
            </Layout>
          }
        />
        <Route
          path="/mockinterview/text"
          element={
            <Layout>
              <TextSubmit />
            </Layout>
          }
        />

        <Route
          path="/mockinterview/audiotext"
          element={
            <Layout>
              <AudioText />
            </Layout>
          }
        />

        <Route
          path="/mockinterview/interviews/mockinterviewvoice"
          element={
            <Layout>
              <InterviewVoice />
            </Layout>
          }
        />

        <Route
          path="/mockinterview/interviews/mockinterviewtext"
          element={
            <Layout>
              <InterviewText />
            </Layout>
          }
        />

        <Route
          path="/interviewhistory"
          element={
            <Layout>
              <InterviewHistory />
            </Layout>
          }
        />

        <Route
          path="/interviewhistory/evaluation"
          element={
            <Layout>
              <Evaluation1 />
            </Layout>
          }
        />

        <Route
          path="/blogpost"
          element={
            <Layout>
              <BlogPost />
            </Layout>
          }
        />
        <Route
          path="/Blog"
          element={
            <Layout>
              <div className="h-[95vh] mt-2 mr-3 mb-4 rounded-2xl w-[99%] bg-[#F0F0F0] font-poppins overflow-hidden ">
              <div className="sm:px-10 lg:px-20 px-10 justify-start flex flex-col w-full bg-[#F0F0F0] font-poppins h-[100vh] rounded-3xl pt-4 overflow-y-auto no-scrollbar " style={{ maxHeight: 'calc(110vh - 160px)' }}>
                <DynamicBlog2 />
              </div>
              </div>
            </Layout>
          }
        />

      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
