import { createSlice } from "@reduxjs/toolkit";

export const responseDataSlice = createSlice({
  name: "responseData",
  initialState: {
    value: [],
    interview: [],
  },
  reducers: {
    setResponseData: (state, action) => {
      state.value = action.payload;
    },
    setInterview: (state, action) => {
      state.interview = action.payload;
    },
  },
});

export const responseDataActions = responseDataSlice.actions;
// export const {  } = responseDataSlice.actions;

export default responseDataSlice.reducer;
