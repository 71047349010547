import React, { useEffect, useState } from "react";
import robot from "../../assets/images/robot.png";
import axios from "axios";
import { useDispatch ,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { responseDataActions } from "../../store/responseDataSlice";
import { FiUploadCloud } from "react-icons/fi";
const SecondInterview = () => {
  const [file, setFile] = useState(null);
  const [jobTitle, setJobTitle] = useState("");
  const [experience, setExperience] = useState("");
  const [desc, setDesc] = useState("");
  const [responseDatas, setResponseDatas] = useState([]);
  

  const navigate = useNavigate();
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleExperience = (e) => {
    setExperience(e.target.value);
  };

  const handleTextChangeJob = (e) => {
    setJobTitle(e.target.value);
  };

  const handleTextChangeDesc = (e) => {
    setDesc(e.target.value);
  };
  const dispatch = useDispatch();

  
  const send_params = () => {
    dispatch(responseDataActions.setResponseData({jobTitle, experience, desc}))
    navigate("/mockinterview/interviews/mockinterviewvoice")
    // console.log({ jobTitle, experience, desc })
  }

  return (
    <div div className="mt-5 p-5 rounded-lg w-98p bg-[#F0F0F0] font-poppins">
      <div className="flex items-center justify-center py-8 ">
        {/* First Dot */}
        <div className="h-4 w-4 rounded-full bg-Step flex items-center justify-center">
          <span className="text-black pt-12">info</span>
        </div>
        {/* Dashed Line */}
        <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
        {/* Second Dot */}
        <div className="h-4 w-4 rounded-full bg-Step flex items-center justify-center">
          <span className="text-black pt-12">Interview</span>
        </div>
        {/* Dashed Line */}
        <div className="w-20 border-t-2 border-dashed border-gray-400"></div>
        {/* Third Dot */}
        <div className="h-4 w-4 rounded-full bg-white border-gray-400 border-2 flex items-center justify-center">
          <span className="text-black pt-12">report</span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row pb-6 pt-7">
        <div>
          <img width={300} src={robot} alt="robot" className="-scale-x-100" />
        </div>
        <div className="bg-Step rounded-lg">
          <p className="text-white p-4">
            If you're using Tailwind CSS and want to flip an image horizontally
            without custom CSS or inline styles, you can utilize Tailwind's
            utility classes for transformations. In Tailwind CSS v2.1 and above,
            you can use the scale-x utility directly
          </p>
        </div>
      </div>
      <div className="flex flex-col ">
        <h1 className="text-start pt-4 pb-4 text-head font-bold text-lg">
          About You (Optional)
        </h1>
        <div className="flex flex-col md:flex-row ">
          <p>My desired job title/position is</p>
          <input
            className="bg-white border-0 text-center ps-2 pe-2 border-b w-28 outline-none border-gray-300"
            type="text"
            value={jobTitle}
            onChange={handleTextChangeJob}
          />
          <p>I have</p>
          <input
            value={experience}
            onChange={handleExperience}
            className="bg-white border-0 ps-2 pe-2 text-center border-b w-28 outline-none border-gray-300"
            type="number"
          />
          <p> years of experience in this industry.</p>
        </div>
        <div className="text-start flex flex-col md:flex-row gap-10">
          <div className="flex-1">
            <h1 className="pt-4 pb-4 text-head font-bold text-lg">
              About The Position (Optional)
            </h1>
            <input
              type="text"
              placeholder="Job Description Link"
              value={desc}
              onChange={handleTextChangeDesc}
              className="rounded-lg border-2 border-gray-200 outline-none p-4 w-full"
            />
          </div>
          <div className="md:w-1/3">
            <h1 className="pt-4 pb-4 text-head font-bold text-lg">
              Upload CV (Optional)
            </h1>
            <label
              htmlFor="upload"
              className="flex justify-between items-center relative cursor-pointer w-full bg-white rounded-lg border-2 border-gray-200 outline-none p-4"
            >
              <span className="text-gray-400">Upload</span>
              <FiUploadCloud className="text-gray-400" />
              <input
                type="file"
                id="upload"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </label>
          </div>
        </div>


        <div className="flex items-center justify-center pb-7 pt-7">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-4 -mb-px text-sm text-gray-600">Or</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>
        <textarea
          type="text"
          className="h-32 p-4 border-2 border-gray-500 mb-6 outline-none rounded-lg w-full"
          placeholder="Job Description Link"
        />
        <div className="">
          <button
            onClick={send_params}
            className="text-center bg-PrimaryColor text-white rounded-lg "
            style={{ padding: "10px 20px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecondInterview;
