import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiUrl } from "../../Constant/ApiUrl";
import Loader from "../../Components/Loader";

const Card = ({ image, title, description, description2, onClick }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleDescription = () => {
        setExpanded(!expanded);
    };

    const handleClick = () => {
        onClick({ image, title, description, description2 }); // Call onClick with card data
    };

    return (
        <div className="sm:w-1/2 md:w-1/2 lg:w-[35%] xl:w-1/3 p-5 h-50 flex justify-center font-poppins" onClick={handleClick}>
            <div className="bg-white rounded-2xl shadow-md overflow-hidden relative w-[100%] flex flex-col"> {/* Set fixed width for the container */}
                <div className="p-2">
                    <img src={`${ApiUrl}/uploads/${image}`} alt={title} className="w-[900px] h-[100px] object-cover rounded-xl" />
                </div>
                <div className="relative justify-start px-2 flex flex-col">
                    <h2 className="md:text-lg text-md font-bold mb-2 text-start">{title}</h2>
                    <div className="flex flex-col relative gap-3">
                        <p className="text-gray-700 text-start text-xs font-medium pr-[90px] pb-10">
                            {expanded ? description : description.slice(0, 90)}
                        </p>
                        
                    </div>
                </div>
                <div>
                <p className="absolute bottom-0 right-0 text-xs font-thin text-[#F06464] font-poppins pr-3 pb-1 ">{description2}</p>
                </div>


            </div>
        </div>

    );
};

const DynamicBlog = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // State for loading spinner
    const [blogs, setBlogs] = useState([]);

    const getBlog = async () => {
        try {
            const response = await fetch(`${ApiUrl}/blog`, {
                method: "GET"
            });
            const data = await response.json();
            setBlogs(data.Blogs);
        } catch (error) {
            console.error("Error fetching blogs:", error);
        } finally {
            setLoading(false); // Set loading to false after response is received or error occurs
        }
    };

    useEffect(() => {
        getBlog();
    }, []);

    const handleCardClick = (cardData) => {
        navigate("/Blog/ShowBlog", { state: { cardData } });
    };

    const cardsData = blogs.map(blog => ({
        image: blog.img,
        title: blog.title,
        description: blog.blog,
        description2: new Date(blog.createdAt).toLocaleDateString()
    }));

    return (
        <div id="Blog" className="font-poppins">
            <div className="flex justify-start">
                <div className="font-bold md:text-[24px] text-xl">Blogs</div>
            </div>
            
                <div className="container mx-auto py-8 flex justify-center">
                    <div className="flex flex-wrap -mx-4">
                        {cardsData.map((card, index) => (
                            <Card key={index} {...card} onClick={handleCardClick.bind(null, card)} />
                        ))}
                    </div>
                </div>
            
        </div>
    );
};

export default DynamicBlog;
